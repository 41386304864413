import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingShopItem = props => {
  return (
    <div
      className="appointment-booking-shop-item"
      onClick={props.onClickHandle}
    >
      <span className="appointment-booking-shop-item__name">{props.name}</span>
      <span className="appointment-booking-shop-item__address">
        {props.address}
      </span>
    </div>
  );
};

AppointmentBookingShopItem.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  onClickHandle: PropTypes.func
};

AppointmentBookingShopItem.propTypesMeta = 'exclude';

export default AppointmentBookingShopItem;
