import React from 'react';
import PropTypes from 'prop-types';

const SkipToMainButton = ({ text, targetId }) => {
  return (
    <div className="skip-to-main-button">
      <a className="skip-to-main-button__link" href={`#${targetId}`}>
        {text}
      </a>
    </div>
  );
};

SkipToMainButton.propTypes = {
  text: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired
};

SkipToMainButton.propTypesMeta = 'exclude';

export default SkipToMainButton;
