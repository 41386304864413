import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from 'components/content-container';
import Link from 'components/link';
import Text from 'components/text';
import cn from 'classnames';

const BreadCrumbs = ({ isMagazine, items }) =>
  !items.length ? null : (
    <Text.div
      className={cn('breadcrumbs', { 'breadcrumbs--magazine': isMagazine })}
      theme={Text.themes.small}
    >
      <ContentContainer>
        <ul itemScope itemType="http://schema.org/BreadcrumbList">
          {items.map(({ text, url }, index) => (
            <li
              key={text + url}
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              {url ? (
                <Link
                  url={url}
                  text={text}
                  themes={[Link.themes.linkGrayUnderline]}
                  itemProp={true}
                />
              ) : (
                <span itemProp="name">{text}</span>
              )}

              <meta itemProp="position" content={index + 1} />
            </li>
          ))}
        </ul>
      </ContentContainer>
    </Text.div>
  );

BreadCrumbs.propTypes = {
  isMagazine: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      text: PropTypes.string.isRequired
    })
  )
};

BreadCrumbs.propTypesMeta = {
  isMagazine: 'exclude'
};

BreadCrumbs.defaultProps = {
  isMagazine: false,
  items: []
};

export default BreadCrumbs;
