import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from 'components/content-container';
import ContactLensHeader from './contact-lens-header';
import Grid from 'components/grid';
import MainTitle from 'components/main-title';
import ProductListBlock from 'components/product-list-block';
import TeaserExpander from 'components/teaser-expander';
import Text from 'components/text';
import ProductText from 'components/product-text';

const ContactLensPage = ({
  contentTitle,
  header,
  productTextHtml,
  productInformation,
  productMetadata,
  relatedProducts,
  isCollapsable,
  showLessText,
  showMoreText,
  title
}) => (
  <div className="contact-lens-page">
    <ContentContainer>
      <MainTitle title={title} theme={MainTitle.themes.small} />

      <ContactLensHeader {...header} />
      {productTextHtml && <ProductText textHtml={productTextHtml} />}

      <div className="contact-lens-content">
        <Text.h2 theme={Text.themes.h2}>{contentTitle}</Text.h2>

        <TeaserExpander
          showMoreText={showMoreText}
          showLessText={showLessText}
          isCollapsable={isCollapsable}
        >
          <Grid theme={Grid.themes.leftColumnWider}>
            {productInformation.length > 0 && (
              <Grid.Column>
                <div className="contact-lens-information">
                  {productInformation.map(item => [
                    <Text.h3 theme={Text.themes.h3} key={item.title}>
                      {item.title}
                    </Text.h3>,
                    <p key={item.text}>{item.text}</p>
                  ])}
                </div>
              </Grid.Column>
            )}

            {productMetadata && (
              <Grid.Column>
                <div className="contact-lens-metadata">
                  <Text.h3 theme={Text.themes.h3}>
                    {productMetadata.title}
                  </Text.h3>
                  <table>
                    <tbody>
                      {productMetadata.content.map(item => (
                        <tr key={item.title}>
                          <td>{`${item.title}:`}</td>
                          <td>{item.text}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Grid.Column>
            )}
          </Grid>
        </TeaserExpander>
      </div>

      <div className="contact-lens-blocks">
        {relatedProducts && <ProductListBlock {...relatedProducts} />}
      </div>
    </ContentContainer>
  </div>
);

ContactLensPage.propTypes = {
  contentTitle: PropTypes.string,
  header: PropTypes.exact(ContactLensHeader.propTypes),
  productTextHtml: PropTypes.string,
  productType: PropTypes.string,
  isCollapsable: PropTypes.bool,
  productInformation: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.string
    })
  ),
  productMetadata: PropTypes.exact({
    title: PropTypes.string,
    content: PropTypes.arrayOf(
      PropTypes.exact({
        title: PropTypes.string,
        text: PropTypes.string
      })
    )
  }),
  relatedProducts: PropTypes.exact(ProductListBlock.propTypes),
  showLessText: PropTypes.string,
  showMoreText: PropTypes.string,
  title: PropTypes.string
};

ContactLensPage.defaultProps = {
  productInformation: [],
  isCollapsable: true
};

export default ContactLensPage;
