import React from 'react';
import PropTypes from 'prop-types';

import api from '../../js/api-helper';
import checkApiPropTypes from 'js/check-api-prop-types';

import ContactLensForm from './contact-lens-form';
import Grid from '../grid';
import ProductImages from '../product-images';
import ProductLogo from '../product-logo';
import ProductSellingPoints from '../product-selling-points';
import ReplacementProductBanner from './replacement-product-banner';
import SellingPoints from '../selling-points';
import UpdateFormResponse from './update-form-response';
import Text from 'components/text';
import ProductVariantText from 'components/product-variant-text';

class ContactLensHeader extends React.Component {
  static propTypes = {
    getAvailabilityEndpoint: PropTypes.string,
    productImages: PropTypes.exact(ProductImages.propTypes),
    productLogo: PropTypes.exact(ProductLogo.propTypes),
    productSellingPoints: PropTypes.arrayOf(PropTypes.string),
    purchaseForm: PropTypes.exact(ContactLensForm.propTypes),
    replacementProductBanner: PropTypes.exact(
      ReplacementProductBanner.propTypes
    ),
    replacementProductTitle: PropTypes.string,
    sellingPoints: PropTypes.exact(SellingPoints.propTypes),
    updateFormEndpoint: PropTypes.string,
    productVariantText: PropTypes.string
  };

  static defaultProps = {
    productSellingPoints: []
  };

  state = {
    purchaseForm: this.props.purchaseForm,
    productVariantText: this.props.productVariantText
  };

  isLoading = false;

  onFormChange = formData => {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    const transformedFormData = Object.entries(formData).reduce(
      (accum, [key, val]) => Object.assign({}, accum, { [key]: val || '' }),
      {}
    );

    api
      .execute(this.props.updateFormEndpoint, transformedFormData)
      .then(({ purchaseForm, productVariantText }) => {
        this.isLoading = false;

        checkApiPropTypes(
          { purchaseForm },
          UpdateFormResponse.propTypes,
          'UpdateFormResponse'
        );

        this.setState(state => ({
          purchaseForm: Object.assign({}, state.purchaseForm, purchaseForm),
          productVariantText: productVariantText
        }));
      });

    // NOTE: This endpoint is separate from updateFormEndpoint because the availability check is much slower on the server than the price calculation.
    if (this.props.getAvailabilityEndpoint) {
      api
        .execute(this.props.getAvailabilityEndpoint, transformedFormData)
        .then(({ purchaseForm }) => {
          checkApiPropTypes(
            { purchaseForm },
            UpdateFormResponse.propTypes,
            'UpdateFormResponse'
          );
          this.setState(state => ({
            purchaseForm: Object.assign({}, state.purchaseForm, purchaseForm)
          }));
        });
    }
  };

  render() {
    return (
      <Grid className="contact-lens-header" theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <div className="contact-lens-product-wrapper">
            {this.props.replacementProductBanner && (
              <React.Fragment>
                <div className="contact-lens-replacement-product">
                  <ReplacementProductBanner
                    {...this.props.replacementProductBanner}
                  />
                </div>

                <div className="contact-lens-main-product">
                  <Text.h2 theme={Text.themes.h2}>
                    {this.props.replacementProductTitle}
                  </Text.h2>

                  {this.props.productSellingPoints && (
                    <div className="contact-lens-product-selling-points">
                      <ProductSellingPoints
                        items={this.props.productSellingPoints}
                      />
                    </div>
                  )}

                  <div className="contact-lens-image">
                    <ProductImages {...this.props.productImages} />
                  </div>
                </div>
              </React.Fragment>
            )}

            {!this.props.replacementProductBanner && (
              <div className="contact-lens-main-product">
                <div className="contact-lens-logo">
                  <ProductLogo {...this.props.productLogo} />
                </div>

                <div className="contact-lens-image">
                  <ProductImages {...this.props.productImages} />
                </div>

                {this.props.productSellingPoints && (
                  <div className="contact-lens-product-selling-points">
                    <ProductSellingPoints
                      items={this.props.productSellingPoints}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Grid.Column>

        <Grid.Column>
          <ContactLensForm
            onChange={this.onFormChange}
            {...this.state.purchaseForm}
          />

          {this.state.productVariantText && (
            <ProductVariantText
              textHtml={this.state.productVariantText}
              className="product-variant-text--in-contact-lens"
            />
          )}
          <div className="contact-lens-selling-points">
            <SellingPoints {...this.props.sellingPoints} />
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default ContactLensHeader;
