import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Link from 'components/link';

const CheckoutLogout = ({ buttonText, link, onComplete, text }) => {
  return (
    <div className="checkout-logout">
      <div className="checkout-logout-content">
        <p>{text}</p>
        <Link {...link} />
      </div>

      <div>
        <Button
          onClick={onComplete}
          iconAfter="chevron-right"
          text={buttonText}
        />
      </div>
    </div>
  );
};

CheckoutLogout.propTypes = {
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.exact(Link.propTypes),
  onComplete: PropTypes.func,
  text: PropTypes.string.isRequired
};

export default CheckoutLogout;
