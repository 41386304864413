import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from 'components/page-header';
import ComponentList from 'components/component-list';
import ContentContainer from 'components/content-container';
import MagazineRelatedPages from 'components/magazine-related-pages/magazine-related-pages';

const MagazineSectionPage = ({ header, blocks, relatedPages }) => {
  return (
    <div className="magazine-section-page">
      {header && <PageHeader {...header} />}
      <ContentContainer>
        <div className="magazine-section-page__content">
          {blocks && <ComponentList {...blocks} />}
        </div>

        {relatedPages && <MagazineRelatedPages {...relatedPages} />}
      </ContentContainer>
    </div>
  );
};

MagazineSectionPage.propTypes = {
  header: PropTypes.exact(PageHeader.propTypes),
  blocks: PropTypes.exact(ComponentList.propTypes),
  relatedPages: PropTypes.exact(MagazineRelatedPages.propTypes)
};

MagazineSectionPage.defaultProps = {};

export default MagazineSectionPage;
