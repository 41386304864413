import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from 'components/text';
import ImageLink from './image-link';

const ImageLinkList = ({ items, title, hasSingleColumn }) => (
  <div className="image-link-list">
    {title && <Text.h2 theme={Text.themes.uppercaseBig}>{title}</Text.h2>}
    <ul
      className={cn('image-link-list-list', {
        'image-link-list-list--grow-items':
          items.length < 3 && !hasSingleColumn,
        'image-link-list-list--single-column': hasSingleColumn
      })}
    >
      {items.map((item, index) => (
        <li key={index} className="image-link-list-item">
          <ImageLink {...item} />
        </li>
      ))}
    </ul>
  </div>
);

ImageLinkList.propTypes = {
  title: PropTypes.string,
  hasSingleColumn: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact(ImageLink.propTypes))
};

ImageLinkList.defaultProps = {
  items: []
};

export default ImageLinkList;
