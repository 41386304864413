import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useIsMounted from 'hooks/use-is-mounted';

import Button from '../button';
import Clickable from 'components/clickable';
import ContentContainer from '../content-container';
import FilterGroup from './filter-group';
import HiddenInput from 'components/hidden-input';

const CategoryFilters = ({
  categoryId,
  filterGroups,
  hideFilters,
  hideFiltersLabel,
  isVisible,
  onReset,
  resetLabel,
  submitLabel,
  title
}) => {
  const isMounted = useIsMounted();

  return (
    <div
      className={cn('category-filters', {
        'is-visible': isVisible
      })}
    >
      <h2>{title}</h2>
      <div className="category-filters-nav">
        <ContentContainer>
          <div className="category-filters-hide-wrapper">
            <Button
              className="category-filters-hide"
              iconBefore="chevron-left"
              onClick={hideFilters}
              text={hideFiltersLabel}
              themes={[]}
            />
          </div>
          <Clickable
            element="label"
            htmlFor="category-filters-submit"
            className="category-filters-apply"
            themes={[Clickable.themes.buttonCyan]}
          >
            {submitLabel}
          </Clickable>
          <Clickable
            element="label"
            htmlFor="category-filters-reset"
            className="category-filters-reset"
            themes={[Clickable.themes.buttonOutline]}
          >
            {resetLabel}
          </Clickable>
        </ContentContainer>
      </div>
      {categoryId && <HiddenInput {...categoryId} />}
      <div className="category-filters-content">
        {filterGroups.map(group => (
          <FilterGroup key={group.title} {...group} />
        ))}
      </div>
      <input
        type="reset"
        id="category-filters-reset"
        className={cn('category-filters-reset', 'is-hidden')}
        value={resetLabel}
        onClick={onReset}
      />
      <input
        id="category-filters-submit"
        type="submit"
        className={cn('category-filters-submit', {
          'is-mounted': isMounted
        })}
        value={submitLabel}
      />
    </div>
  );
};

CategoryFilters.propTypes = {
  categoryId: PropTypes.exact(HiddenInput.propTypes),
  filterGroups: PropTypes.arrayOf(PropTypes.exact(FilterGroup.propTypes)),
  hideFilters: PropTypes.func,
  hideFiltersLabel: PropTypes.string,
  isVisible: PropTypes.bool,
  onReset: PropTypes.func,
  resetLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  title: PropTypes.string
};

CategoryFilters.propTypesMeta = {
  isVisible: 'exclude'
};

CategoryFilters.defaultProps = {
  filterGroups: [],
  submitLabel: 'Filtrér'
};

export default CategoryFilters;
