import PropTypes from 'prop-types';

import SearchResults from './search-results';

const GlobalSearchResponse = {};
GlobalSearchResponse.propTypes = {
  searchResults: PropTypes.exact(SearchResults.propTypes)
};

export default GlobalSearchResponse;
