import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import scrollToElement from '@creuna/utils/scroll-to-element';

import Collapse from 'react-tiny-collapse';
import TinyTransition from 'react-tiny-transition';

import useIsMounted from 'hooks/use-is-mounted';
import usePrevious from 'hooks/use-previous';

import Button from 'components/button';
import Icon from 'components/icon';

const themes = {
  padding: 'theme-padding'
};

const CheckoutStep = ({
  children,
  collapseLabel,
  forceInitialAnimation,
  expandLabel,
  isEnabled,
  isComplete,
  number,
  theme,
  title
}) => {
  const [element, setElement] = React.useState();
  const [isExpanded, setIsExpanded] = React.useState(true);

  const isMounted = useIsMounted();

  const toggle = () => setIsExpanded(isExpanded => !isExpanded);

  const wasComplete = usePrevious(isComplete);
  const wasEnabled = usePrevious(isEnabled);

  React.useEffect(() => {
    if (!wasComplete && isComplete) setIsExpanded(false);
  }, [isComplete]);

  React.useEffect(() => {
    if (!wasEnabled && isEnabled) {
      setTimeout(() => {
        element && scrollToElement(element);
      }, 500);
    }
  }, [isEnabled]);

  return (
    <div
      className={cn('checkout-step', theme, {
        'is-completed': isComplete,
        'is-enabled': isEnabled
      })}
      ref={setElement}
    >
      <div
        className="checkout-step-header"
        onClick={isComplete ? toggle : () => {}}
      >
        <TinyTransition
          delay={isComplete ? 0 : 200}
          disableInitialAnimation={true}
        >
          {!isComplete && (
            <div className="checkout-step-number">
              <span>{number}</span>
            </div>
          )}
        </TinyTransition>

        <TinyTransition delay={isComplete ? 200 : 0}>
          {isComplete && (
            <div className="checkout-step-checkmark">
              <Icon name="checkmark" />
            </div>
          )}
        </TinyTransition>
        <h2>{title}</h2>

        {isComplete && (
          <div className="checkout-step-info">
            <Button
              onClick={e => {
                e.stopPropagation();
                toggle();
              }}
              text={isExpanded ? collapseLabel : expandLabel}
              themes={[Button.themes.link]}
            />
          </div>
        )}
      </div>

      {(!isMounted || isEnabled) && (
        <Collapse
          animateChildren={false}
          forceInitialAnimation={isMounted && forceInitialAnimation}
          isOpen={isExpanded}
        >
          <div
            className={cn('checkout-step-content', {
              'checkout-step-content-non-js': !isMounted
            })}
          >
            {children}
          </div>
        </Collapse>
      )}
    </div>
  );
};

CheckoutStep.propTypes = {
  children: PropTypes.node,
  collapseLabel: PropTypes.string,
  forceInitialAnimation: PropTypes.bool,
  expandLabel: PropTypes.string,
  isEnabled: PropTypes.bool,
  isComplete: PropTypes.bool,
  number: PropTypes.number,
  theme: PropTypes.oneOf(Object.values(themes)),
  title: PropTypes.string
};

CheckoutStep.propTypesMeta = 'exclude';

CheckoutStep.themes = themes;

export default CheckoutStep;
