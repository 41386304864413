import React from 'react';
import PropTypes from 'prop-types';
import AppointmentBookingShopItem from 'components/appointment-booking-modal/appointment-booking-shop-item';

const AppointmentBookingShopList = props => {
  return (
    <div className="appointment-booking-shop-list">
      <div className="appointment-booking-shop-list__inner">
        {props.shops &&
          props.shops.map((item, index) => {
            return (
              <AppointmentBookingShopItem
                key={item.id}
                name={item.name}
                address={item.address}
                onClickHandle={() => {
                  props.onClickShop(index);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

AppointmentBookingShopList.propTypes = {
  onClickShop: PropTypes.func,
  shops: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired, // NOTE: Used as key in region list
      address: PropTypes.string.isRequired
    })
  )
};

export default AppointmentBookingShopList;
