import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-tiny-collapse';
import TinyTransition from 'react-tiny-transition';
import Icon from '../icon';

import RichText from '../rich-text';

const CollapsableRichText = ({
  text,
  children,
  hiddenText,
  showInlineCloseButton,
  readLessButtonText,
  onCloseClick,
  readMoreButtonText,
  closeButtonText,
  isInitiallyOpen
}) => {
  const [textIsExpanded, setTextIsExpanded] = useState(isInitiallyOpen);
  const [showButton, setShowButton] = useState(!isInitiallyOpen);

  const handleOnReadMoreClick = () => {
    setTextIsExpanded(!textIsExpanded);
    setShowButton(textIsExpanded);
  };

  return (
    <div className="collapsable-rich-text">
      {showInlineCloseButton && (
        <button
          className="collapsable-rich-text-close-button"
          onClick={onCloseClick}
        >
          <span className="collapsable-rich-text-close-text">
            {closeButtonText}
          </span>
          <span className="collapsable-rich-text-close-icon">
            <Icon name="big-x" />
          </span>
        </button>
      )}
      <RichText
        theme={RichText.themes.compactWhite}
        isCentered={false}
        hasBottomMargin={false}
        className="collapsable-rich-text"
        element="span"
        text={text}
      />
      {readMoreButtonText && (
        <TinyTransition
          disableInitialAnimation={true}
          duration={200}
          delay={200}
          classNames={{
            beforeEnter: 'collapsable-rich-text-before-enter',
            entering: 'collapsable-rich-text-entering',
            beforeLeave: 'collapsable-rich-text-before-leave',
            leaving: 'collapsable-rich-text-leaving'
          }}
        >
          {showButton && (
            <button
              className="collapsable-rich-text-button collapsable-rich-text-button--more"
              onClick={handleOnReadMoreClick}
            >
              {readMoreButtonText}
            </button>
          )}
        </TinyTransition>
      )}
      <Collapse isOpen={textIsExpanded}>
        <div className="collapsable-rich-text-collsapsed">
          <div className="collapsable-rich-text-hidden-text">
            <RichText
              theme={RichText.themes.compactWhite}
              hasBottomMargin={false}
              text={hiddenText}
              isCentered={false}
            />
          </div>
          {children && (
            <div className="collapsable-rich-text-content">{children}</div>
          )}
          {readLessButtonText && (
            <button
              className="collapsable-rich-text-button"
              onClick={handleOnReadMoreClick}
            >
              {readLessButtonText}
            </button>
          )}
        </div>
      </Collapse>
    </div>
  );
};

CollapsableRichText.propTypesMeta = {
  closeButtonText: 'exclude',
  showInlineCloseButton: 'exclude'
};

CollapsableRichText.propTypes = {
  isInitiallyOpen: PropTypes.bool,
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
  closeButtonText: PropTypes.string,
  showInlineCloseButton: PropTypes.bool,
  readLessButtonText: PropTypes.string,
  readMoreButtonText: PropTypes.string,
  text: PropTypes.string.isRequired,
  hiddenText: PropTypes.string.isRequired
};

export default CollapsableRichText;
