import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Text from 'components/text';

const MyConsents = ({ consentList }) => (
  <Text element="ul" className="my-consents" theme={Text.themes.small}>
    {consentList.map(consent => {
      return (
        <li key={consent.id}>
          <Icon name="checkmark" />
          {consent.text}
        </li>
      );
    })}
  </Text>
);

MyConsents.propTypes = {
  consentList: PropTypes.arrayOf(
    PropTypes.exact({ id: PropTypes.string, text: PropTypes.string })
  )
};

MyConsents.defaultProps = {
  consentList: []
};

export default MyConsents;
