import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingTitle = props => {
  return <strong className="appointment-booking-title">{props.value}</strong>;
};

AppointmentBookingTitle.propTypes = {
  value: PropTypes.string
};

AppointmentBookingTitle.propTypesMeta = 'exclude';

export default AppointmentBookingTitle;
