import googleMapsLoader from './google-maps-loader';
import api from '../js/api-helper';

const checkCoords = (addressObj, callback) => {
  const addressData = { ...addressObj };
  // if we have coords on BE we will return them
  if (addressData.latitude && addressData.longitude) {
    callback({
      latitude: addressData.latitude,
      longitude: addressData.longitude
    });
  }
};

const getCurrentCoords = (addressObj, useCurrentPosition, callback) => {
  const addressData = { ...addressObj };
  // if we don't have any data
  if (addressData.address || (addressData.latitude && addressData.longitude))
    return;
  // we will use current user coords if useCurrentPosition set to true
  if (useCurrentPosition && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
      callback({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      });
    });
    // otherwise we will return null
  } else {
    callback(null);
  }
};

const findCoords = (addressObj, APIKey, callback) => {
  const addressData = { ...addressObj };
  // if we have only user address we will find coords
  if (addressData.address && !(addressData.latitude && addressData.longitude)) {
    googleMapsLoader(APIKey).then(google => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: addressData.address }, function(
        results,
        status
      ) {
        if (status === 'OK') {
          callback({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng()
          });
        }
      });
    });
  }
};

const setCoords = (addressCoords, addressObj, callback) => {
  const addressData = { ...addressObj };
  // here we update coords on BE
  api.execute(addressData.endpoint, {
    complete: true,
    address: addressData.address,
    latitude: addressCoords.latitude,
    longitude: addressCoords.longitude
  });
  callback(addressCoords);
};

const handle = (addressObj, APIKey, useCurrentPosition, callback) => {
  checkCoords(addressObj, callback);
  getCurrentCoords(addressObj, useCurrentPosition, callback);
  findCoords(addressObj, APIKey, function(coords) {
    setCoords(coords, addressObj, callback);
  });
};

export default {
  getCurrentCoords,
  checkCoords,
  findCoords,
  setCoords,
  handle
};
