import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from '../link';
import Text from 'components/text';

const ProductColors = ({ title, colors, urlAnchorText }) => {
  return colors.length === 0 ? null : (
    <div className="glasses-colors" id={urlAnchorText}>
      <Text.div theme={Text.themes.small}>{title}</Text.div>
      <ul>
        {colors.map(({ code, isCurrent, link, image } = {}) => {
          const colorName = link && link.text;

          return (
            <li key={link.text + link.url}>
              <Link
                title={colorName}
                className={cn('glasses-color', { 'is-current': isCurrent })}
                hiddenText={true}
                style={{
                  backgroundColor: code,
                  backgroundImage: image ? `url(${image})` : null
                }}
                {...link}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ProductColors.propTypes = {
  title: PropTypes.string,
  urlAnchorText: PropTypes.string,
  colors: PropTypes.arrayOf(
    PropTypes.exact({
      code: PropTypes.string,
      image: PropTypes.string,
      isCurrent: PropTypes.bool,
      link: PropTypes.exact(Link.propTypes)
    })
  )
};

ProductColors.defaultProps = {
  colors: [],
  urlAnchorText: 'colors'
};

export default ProductColors;
