import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

const FooterLinkList = ({ id, links }) => (
  <div className="footer-link-list" key={id}>
    <ul>
      {links.map(link => (
        <li key={link.text}>
          <Link
            className="footer-link-list-link"
            iconBefore="chevron-right"
            themes={[Link.themes.linkBig]}
            {...link}
          />
        </li>
      ))}
    </ul>
  </div>
);

FooterLinkList.defaultProps = {
  linkList: []
};

FooterLinkList.propTypes = {
  id: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
};

export default FooterLinkList;
