import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from 'components/icon';

const themeNames = {
  arrowRight: 'theme-arrow-right',
  buttonGray: 'theme-button-gray',
  buttonCyan: 'theme-button-cyan',
  buttonCyanSmall: 'theme-button-cyan-small',
  buttonCyanLight: 'theme-button-cyan-light',
  buttonOutline: 'theme-button-outline',
  buttonWhite: 'theme-button-white',
  circle: 'theme-circle',
  circleDark: 'theme-circle-dark',
  circleSmall: 'theme-circle-small',
  link: 'theme-link',
  plain: 'theme-plain',
  linkBig: 'theme-link-big',
  linkBigUppercase: 'theme-link-big-uppercase',
  linkGray: 'theme-link-gray',
  linkGrayUnderline: 'theme-link-gray-underline',
  linkWhite: 'theme-link-white',
  uppercase: 'theme-uppercase',
  wide: 'theme-wide'
};

// NOTE: This component exists because designers often get confused about links and buttons, styling links as buttons and buttons as links willy nilly. Clickable is a place for both link and button styles. This is an attempt to reduce complexity by decoupling visual style from function.
const Clickable = React.forwardRef(
  (
    {
      children,
      className,
      element,
      iconAfter,
      iconBefore,
      themes,
      ctaTitle,
      ctaType,
      ...restProps
    },
    ref
  ) => {
    return React.createElement(
      element,
      {
        ...restProps,
        ref,
        className: cn('clickable', themes, className, {
          'has-icon-after': iconAfter,
          'has-icon-before': iconBefore
        }),
        'data-cta-type': ctaType ? ctaType : null,
        'data-cta-title': ctaTitle ? ctaTitle : null,
        itemProp: restProps.itemProp ? 'item' : null
      },
      <React.Fragment>
        {iconBefore && <Icon className="clickable-icon" name={iconBefore} />}
        {children && (
          <span
            className="clickable-children"
            itemProp={restProps.itemProp ? 'name' : null}
          >
            {children}
          </span>
        )}
        {themes.includes(themeNames.arrowRight) && (
          <Icon className="clickable-icon" name="arrow-right" />
        )}
        {iconAfter && <Icon className="clickable-icon" name={iconAfter} />}
      </React.Fragment>
    );
  }
);

Clickable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  element: PropTypes.string.isRequired,
  iconAfter: PropTypes.string,
  iconBefore: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(themeNames))),
  ctaTitle: PropTypes.string,
  ctaType: PropTypes.string
};

// NOTE: Show 'Clickable' in error messages instead of 'forwardRef'
Clickable.displayName = 'Clickable';

Clickable.propTypesMeta = 'exclude';

Clickable.defaultProps = {
  themes: []
};

Clickable.themes = themeNames;

export default Clickable;
