import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import OnPageEditing from 'components/on-page-editing';
import Text from 'components/text';

const LinkList = ({ items, OPE, title }) => (
  <div className="link-list">
    <OnPageEditing name={OPE.title}>
      {title && <Text.h2 theme={Text.themes.bold}>{title}</Text.h2>}
    </OnPageEditing>

    <OnPageEditing name={OPE.items}>
      <ul>
        {items.map(item => (
          <li key={item.url + item.text}>
            <Link
              className="link-list-item"
              iconAfter="chevron-right"
              themes={[Link.themes.buttonCyanLight]}
              {...item}
            />
          </li>
        ))}
      </ul>
    </OnPageEditing>
  </div>
);

LinkList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  OPE: PropTypes.exact({
    items: PropTypes.string,
    title: PropTypes.string
  }),
  title: PropTypes.string
};

LinkList.defaultProps = {
  items: [],
  OPE: {}
};

export default LinkList;
