import React from 'react';
import PropTypes from 'prop-types';

import YouTubePlayer from 'react-player/lib/players/YouTube';
import config from './youtube-config';

import OnPageEditing from '../on-page-editing';

const EmbedVideo = ({ OPE, url }) => {
  return (
    <div className="embed-video">
      <OnPageEditing name={OPE.url} editType={OnPageEditing.editTypes.flyout}>
        <YouTubePlayer
          className="embed-video-player embed-video-player--youtube"
          url={url}
          {...config}
        />
      </OnPageEditing>
    </div>
  );
};

EmbedVideo.propTypes = {
  OPE: PropTypes.exact({
    url: PropTypes.string
  }),
  url: PropTypes.string.isRequired
};

EmbedVideo.defaultProps = {
  OPE: {}
};

export default EmbedVideo;
