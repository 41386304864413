import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import FluidImage from 'components/fluid-image';
import ProductCard from 'components/product-card';
import ProductList from 'components/product-list';
import Text from 'components/text';

const editorThemes = {
  alignLeft: 'theme-align-left',
  alignRight: 'theme-align-right'
};

const ImageAndProducts = ({ editorTheme, title, image, products }) => {
  const hasSingleProduct = products.length === 1;

  return (
    <div className="image-and-products">
      {title && (
        <div className="image-and-products-title">
          <Text.h2 theme={Text.themes.uppercase}>{title}</Text.h2>
        </div>
      )}
      <div
        className={cn('image-and-products-content', editorTheme, {
          'has-single-product': hasSingleProduct
        })}
      >
        <div className="image-and-products-image">
          <FluidImage {...image} />
        </div>
        <div className="image-and-products-products">
          {hasSingleProduct ? (
            <ProductCard theme={ProductCard.themes.jumbo} {...products[0]} />
          ) : (
            <ProductList
              products={products}
              size={
                products.length >= 3
                  ? ProductList.sizes.twoColumns
                  : ProductList.sizes.oneColumn
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

ImageAndProducts.propTypes = {
  title: PropTypes.string,
  editorTheme: PropTypes.oneOf(Object.values(editorThemes)),
  image: PropTypes.exact(FluidImage.propTypes),
  products: PropTypes.arrayOf(PropTypes.exact(ProductCard.propTypes))
};

ImageAndProducts.defaultProps = {
  products: []
};

export default ImageAndProducts;
