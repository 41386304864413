import React from 'react';

const modalContext = React.createContext({
  isAppointmentBookingModalOpen: false,
  isLoginModalOpen: false,
  isSearchModalOpen: false,
  isCartModalOpen: false,
  isMobileMenuOpen: false,
  toggleAppointmentBookingModal: () => {},
  toggleLoginModal: () => {},
  toggleSearchModal: () => {},
  toggleCartModal: () => {},
  toggleMobileMenu: () => {},
  closeMobileMenu: () => {},
  closeModals: () => {}
});

export default modalContext;
