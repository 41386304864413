import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import messenger from 'utils/messenger';

import TinyTransition from 'react-tiny-transition';

import Button from '../button';
import ContentContainer from '../content-container';
import Icon from '../icon';

class Message extends React.Component {
  static propTypes = {
    numberOfSecondsToShowMessage: PropTypes.number,
    isErrorMessage: PropTypes.bool,
    text: PropTypes.string
  };

  static defaultProps = {
    numberOfSecondsToShowMessage: 10
  };

  state = {
    isErrorMessage: this.props.isErrorMessage,
    isVisible: false,
    text: this.props.text
  };

  show = ({ message, isErrorMessage }) => {
    this.setState(
      { isVisible: !!message, isErrorMessage, text: message },

      () => {
        if (this.state.isErrorMessage) return;

        setTimeout(() => {
          this.hide();
        }, this.props.numberOfSecondsToShowMessage * 1000);
      }
    );
  };

  hide = () => {
    this.setState({ isVisible: false });
  };

  componentDidMount() {
    if (this.props.text) {
      this.show({
        message: this.props.text,
        isErrorMessage: this.props.isErrorMessage
      });
    }

    messenger.onErrorMessage(({ message }) => {
      this.show({ message, isErrorMessage: true });
    });

    messenger.onMessage(({ message }) => {
      this.show({ message, isErrorMessage: false });
    });

    messenger.onHideMessage(() => this.hide());
  }

  render() {
    return (
      <TinyTransition>
        {this.state.isVisible && (
          <div
            className={cn('message', {
              'is-error': this.state.isErrorMessage
            })}
          >
            <ContentContainer>
              <div className="message-inner">
                <p>{this.state.text}</p>
                <Button
                  className="message-close"
                  onClick={this.hide}
                  themes={[]}
                >
                  <Icon name="x" />
                </Button>
              </div>
            </ContentContainer>
          </div>
        )}
      </TinyTransition>
    );
  }
}

export default Message;
