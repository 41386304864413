import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Checkbox from 'components/checkbox';

const brightness = (hexColor = '#ffffff') => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  // NOTE: https://en.wikipedia.org/wiki/Relative_luminance
  return 0.2125 * r + 0.7152 * g + 0.0722 * b;
};

const ColorSelect = ({ idPrefix, options }) => {
  return (
    <div className="color-select">
      <ul>
        {options.map(({ colorCode, input, image, label }) => (
          <li key={input.value}>
            <Checkbox
              idPrefix={idPrefix}
              theme={Checkbox.themes.hiddenCheckbox}
              title={label}
              {...input}
            >
              <span
                className="color-select-item"
                style={{
                  backgroundColor: colorCode,
                  backgroundImage: image && `url(${image})`,
                  color: brightness(colorCode) > 128 ? 'black' : 'white'
                }}
              >
                <span className="color-select-item-text">{label}</span>
                <Icon name="checkmark" />
              </span>
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  );
};

ColorSelect.propTypes = {
  idPrefix: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      colorCode: PropTypes.string,
      image: PropTypes.string,
      label: PropTypes.string,
      input: PropTypes.exact(Checkbox.propTypes)
    })
  )
};

ColorSelect.propTypesMeta = {
  idPrefix: 'exclude'
};

ColorSelect.defaultProps = {
  options: []
};

export default ColorSelect;
