import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ComponentList from 'components/component-list';
import ContentContainer from 'components/content-container';
import PageHeader from 'components/page-header';
import ProductList from 'components/product-list';
import RichText from 'components/rich-text';
import MagazineRelatedPages from 'components/magazine-related-pages/magazine-related-pages';

const ArticlePage = ({
  blocks,
  firstText,
  header,
  ingress,
  secondText,
  sidebar,
  hasSidebar,
  magazineRelatedPages
}) => {
  return (
    <div
      className={cn('article-page', { 'article-page--sidebar': hasSidebar })}
    >
      {header && <PageHeader {...header} />}

      <ContentContainer>
        <div className="article-page__content">
          <div className="article-page__main-column">
            <div className="article-page__lead">
              {ingress && (
                <RichText
                  className="article-page__ingress"
                  isCentered={false}
                  theme={RichText.themes.big}
                  {...ingress}
                />
              )}
              {firstText && <RichText {...firstText} />}
            </div>
            {blocks && (
              <ComponentList
                additionalComponentProps={{
                  ProductList: {
                    size: hasSidebar ? ProductList.sizes.twoColumns : undefined
                  },
                  AccordionList: {
                    isNarrow: !hasSidebar
                  },
                  Accordion: {
                    isNarrow: !hasSidebar
                  },
                  ProductListBlock: {
                    productListSize: hasSidebar
                      ? ProductList.sizes.twoColumns
                      : undefined
                  }
                }}
                {...blocks}
              />
            )}
            {secondText && <RichText {...secondText} />}
          </div>

          {sidebar && hasSidebar && (
            <div className="article-page__side-column">
              <ComponentList {...sidebar} />
            </div>
          )}
        </div>
        {magazineRelatedPages && (
          <MagazineRelatedPages {...magazineRelatedPages} />
        )}
      </ContentContainer>
    </div>
  );
};

ArticlePage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  firstText: PropTypes.exact(RichText.propTypes),
  hasSidebar: PropTypes.bool,
  header: PropTypes.exact(PageHeader.propTypes),
  ingress: PropTypes.exact(RichText.propTypes),
  secondText: PropTypes.exact(RichText.propTypes),
  sidebar: PropTypes.exact(ComponentList.propTypes),
  magazineRelatedPages: PropTypes.exact(MagazineRelatedPages.propTypes)
};

ArticlePage.defaultProps = {
  hasSidebar: true
};

export default ArticlePage;
