import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ModalContext from '../../context/modal';

const MobileStickyHeader = ({ children }) => {
  const headerRef = useRef(null);
  const [isHidden, setIsHidden] = useState(null);
  const modalContext = useContext(ModalContext);
  const isMenuOpened = useRef(false);

  useEffect(() => {
    const headerHeight = headerRef.current.offsetHeight;

    function handleScroll() {
      if (
        window.scrollY > window.lastScrollY &&
        window.scrollY > headerHeight &&
        !isMenuOpened.current
      ) {
        setIsHidden(true);
      } else if (window.scrollY < window.lastScrollY) {
        setIsHidden(false);
      }
      window.lastScrollY = window.scrollY;
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    isMenuOpened.current = modalContext.isMobileMenuOpen;
  }, [modalContext]);

  return (
    <>
      <div
        ref={headerRef}
        className={cn('mobile-sticky-header', {
          'mobile-sticky-header--hidden': isHidden
        })}
      >
        {children}
      </div>
      <div className="mobile-sticky-header__spacer"></div>
    </>
  );
};

MobileStickyHeader.propTypes = {
  children: PropTypes.node
};
MobileStickyHeader.propTypesMeta = 'exclude';

export default MobileStickyHeader;
