import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  big: 'theme-big',
  bold: 'theme-bold',
  bigAndBold: 'theme-big-and-bold',
  boldUppercase: 'theme-bold-uppercase',
  boldUppercaseSmall: 'theme-bold-uppercase-small',
  filled: 'theme-filled',
  extraSmall: 'theme-extra-small',
  h1: 'theme-h1',
  h2: 'theme-h2',
  h3: 'theme-h3',
  small: 'theme-small',
  uppercase: 'theme-uppercase',
  uppercaseBig: 'theme-uppercase-big',
  uppercaseLarge: 'theme-uppercase-large',
  uppercaseHuge: 'theme-uppercase-huge',
  uppercaseJumbo: 'theme-uppercase-jumbo',
  uppercaseSmall: 'theme-uppercase-small'
};

const Text = ({ children, className, element, theme, ...restProps }) => {
  return React.createElement(
    element,
    { ...restProps, className: cn('text', theme, className) },
    children
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  element: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

Text.propTypesMeta = 'exclude';

Text.defaultProps = {
  element: 'p'
};

Text.themes = themes;

/* eslint-disable react/no-multi-comp */
Text.h1 = props => <Text {...props} element="h1" />;
Text.h2 = props => <Text {...props} element="h2" />;
Text.h3 = props => <Text {...props} element="h3" />;
Text.h4 = props => <Text {...props} element="h4" />;
Text.h5 = props => <Text {...props} element="h5" />;
Text.p = props => <Text {...props} element="p" />;
Text.span = props => <Text {...props} element="span" />;
Text.div = props => <Text {...props} element="div" />;

export default Text;
