import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import get from 'lodash/get';

import useIsMounted from 'hooks/use-is-mounted';
import useEvent from 'hooks/use-event';
import useToggle from 'hooks/use-toggle';
import useClickOutside from 'hooks/use-click-outside';
import useFormValidation from 'hooks/use-form-validation';

import HiddenInput from 'components/hidden-input';
import Icon from 'components/icon';
import Link from 'components/link';
import Text from 'components/text';

// NOTE: Since an option can't have `null` or `false` as a value (the text label of the option will be used instead), the "null" choice is represented using a single space. An empty string is serialized to an empty value when submitting a form (which is what we want). Comparing against `magicNullValue` also lets the component return `null` from its `onChange` callback when selecting the placeholder option.
const magicNullValue = '';

const themes = {
  simple: 'theme-simple'
};

const Select = ({
  ariaLabelledBy,
  defaultSelectedValue,
  defaultScrollToValue,
  disabled,
  idPrefix,
  inlineLabel,
  label,
  labelLink,
  labelVisible,
  name,
  onChange,
  options,
  placeholder,
  theme,
  labelAnchorScrollText,
  labelAnchorScrollHref,
  validations
}) => {
  const [error, validate] = useFormValidation(name, validations);

  const [isOpen, toggle, close] = useToggle(false);

  const [hasTouch, setHasTouch] = React.useState(false);
  useEvent('touchstart', () => setHasTouch(true));

  const fakeSelectRef = React.useRef();
  useClickOutside(fakeSelectRef, close);

  const [value, setValue] = React.useState(defaultSelectedValue);

  const isMounted = useIsMounted();
  React.useEffect(() => {
    // NOTE: The `onChange` callback indicates the user action of selecting, so it's not called for the initial render
    isMounted && onChange(value);
  }, [value]);

  const handleChange = value => {
    setValue(value === magicNullValue ? null : value);
    close();
    // NOTE: Let DOM update before validating
    requestAnimationFrame(validate);
  };

  const valueLabel = React.useMemo(
    () => get(options.find(o => o.value === value), 'label', placeholder),
    [options, value]
  );

  // HTML elements:
  const [scrollContainer, setScrollContainer] = React.useState();
  const [scrollToOption, setScrollToOption] = React.useState();

  // Scroll dropdown so that the selected option or the 'defaultScrollToValue' option is visible
  React.useEffect(() => {
    if (!isOpen || value || !scrollContainer || !scrollToOption) return;

    const elementPosition = scrollToOption.offsetTop;
    const elementHeight = scrollToOption.offsetHeight;
    const containerHeight = scrollContainer.offsetHeight;
    scrollContainer.scrollTop =
      elementPosition + elementHeight - containerHeight / 2;
  }, [isOpen, scrollContainer, scrollToOption, value]);

  // NOTE: Proxy variable to be able to use in map function for options
  const selectedValue = value;
  const id = idPrefix + name;

  return (
    <React.Fragment>
      {labelVisible && (
        <Text
          className="select-label"
          element="label"
          htmlFor={id}
          theme={Text.themes.small}
        >
          {label}
          {labelLink && (
            <span className="select-label-link">
              <Link {...labelLink} />
            </span>
          )}
          {labelAnchorScrollText && labelAnchorScrollHref && (
            <span className="select-label-link select-label-link--anchor">
              <Link text={labelAnchorScrollText} url={labelAnchorScrollHref} />
            </span>
          )}
        </Text>
      )}

      <div
        className={cn('select', theme, {
          'is-active': isOpen,
          'has-touch': hasTouch,
          'has-error': error,
          'is-mounted': isMounted,
          'is-disabled': disabled
        })}
      >
        {options.length === 1 && (
          <div className="select-single">
            {options.map(({ label, value }) => (
              <React.Fragment key={value}>
                <HiddenInput name={name} value={value} />
                {label}
              </React.Fragment>
            ))}
          </div>
        )}

        {options.length > 1 && (
          <React.Fragment>
            <select
              aria-label={labelVisible ? undefined : label}
              aria-labelledby={ariaLabelledBy}
              disabled={disabled}
              name={name}
              id={id}
              onChange={e => handleChange(e.target.value)}
              value={value || ''}
            >
              {placeholder && (
                <option value={magicNullValue}>{placeholder}</option>
              )}

              {options.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>

            <div className="select-fake">
              <div
                aria-hidden="true"
                className="select-element"
                onClick={disabled ? () => {} : toggle}
                ref={fakeSelectRef}
              >
                {inlineLabel && (
                  <span className="select-fake-label">{label}</span>
                )}

                <span className="select-fake-value">{valueLabel}</span>
                <Icon
                  className={cn('select-icon', { 'is-active': isOpen })}
                  name="chevron-down"
                />
              </div>
              {isOpen && (
                <ul className="select-dropdown" ref={setScrollContainer}>
                  {options.map(({ label, value }) => {
                    const isSelected = value === selectedValue;
                    const isScrollToOption = selectedValue
                      ? value
                      : value === defaultScrollToValue;
                    return (
                      <li
                        aria-hidden="true"
                        className={cn('select-option', {
                          'is-selected': isSelected
                        })}
                        key={value}
                        onClick={() => handleChange(value)}
                        ref={isScrollToOption ? setScrollToOption : () => {}}
                      >
                        <span>{label}</span>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>

            {error && <div className="select-error-message">{error}</div>}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

Select.propTypes = {
  ariaLabelledBy: PropTypes.string,
  defaultSelectedValue: PropTypes.string,
  defaultScrollToValue: PropTypes.string,
  disabled: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  idPrefix: PropTypes.string,
  label: PropTypes.string,
  labelLink: PropTypes.exact(Link.propTypes),
  labelVisible: PropTypes.bool,
  labelAnchorScrollText: PropTypes.string,
  labelAnchorScrollHref: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  validations: PropTypes.arrayOf(PropTypes.string)
};

Select.propTypesMeta = {
  ariaLabelledBy: 'exclude',
  disabled: 'exclude',
  idPrefix: 'exclude',
  inlineLabel: 'exclude',
  labelAnchorScrollHref: 'exclude',
  labelVisible: 'exclude',
  theme: 'exclude',
  validations: 'exclude'
};

Select.defaultProps = {
  defaultSelectedId: null,
  onChange: () => {},
  options: []
};

Select.themes = themes;

export default Select;
