import React from 'react';
import PropTypes from 'prop-types';

const EmailTemplateSummary = ({
  discount,
  discountLabel,
  reorderLink,
  shippingLabel,
  paymentMethodLabel,
  paymentMethod,
  shippingCost,
  totalPriceLabel,
  totalPrice
}) => (
  <div className="email-template-summary">
    <div className="email-template-summary-price">
      <table width="100%" cellSpacing="0" cellPadding="0">
        <tr>
          <td>{discountLabel}:</td>
          <td align="right"> {discount}</td>
        </tr>
        <tr>
          <td>{shippingLabel}:</td>
          <td align="right"> {shippingCost}</td>
        </tr>
        <tr>
          <td>
            <strong>{totalPriceLabel}</strong>:
          </td>
          <td align="right">
            <strong>
              <span>{totalPrice}</span>
            </strong>
          </td>
        </tr>
      </table>
      <p className="email-template-summary-payment-method">
        {paymentMethodLabel}: {paymentMethod}
      </p>
      <div className="email-template-summary-reorder">
        <a href={reorderLink.url}>{reorderLink.text}</a>
      </div>
    </div>
  </div>
);

EmailTemplateSummary.propTypes = {
  discount: PropTypes.string,
  discountLabel: PropTypes.string,
  reorderLink: PropTypes.object,
  paymentMethodLabel: PropTypes.string,
  paymentMethod: PropTypes.string,
  shippingLabel: PropTypes.string,
  shippingCost: PropTypes.string,
  totalPriceLabel: PropTypes.string,
  totalPrice: PropTypes.string
};

EmailTemplateSummary.propTypesMeta = 'exclude';

export default EmailTemplateSummary;
