import PropTypes from 'prop-types';

import Cart from 'components/cart';

const EditCartResponse = {};

EditCartResponse.propTypes = {
  cart: PropTypes.exact(Cart.propTypes),
  numberOfProductsInCart: PropTypes.number
};

export default EditCartResponse;
