import isRunningOnClient from '@creuna/utils/is-running-on-client';

const GoogleMapsLoader = isRunningOnClient ? require('google-maps') : false;

export default function(apiKey) {
  return new Promise(resolve => {
    GoogleMapsLoader.KEY = apiKey;
    GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
    GoogleMapsLoader.load(google => {
      resolve(google);
    });
  });
}
