import React from 'react';
import PropTypes from 'prop-types';

import AppointmentBookingResultRegion from 'components/appointment-booking-modal/appointment-booking-result-region';
import AppointmentBookingResultShop from 'components/appointment-booking-modal/appointment-booking-result-shop';
import AppointmentBookingResultAppointment from 'components/appointment-booking-modal/appointment-booking-result-appointment';
import AppointmentBookingResultTime from 'components/appointment-booking-modal/appointment-booking-result-time';
import AppointmentBookingResultAgree from 'components/appointment-booking-modal/appointment-booking-result-agree';

const AppointmentBookingResultList = props => {
  return (
    <div className="appointment-booking-result-list">
      {props.step === 1 && (
        <AppointmentBookingResultRegion
          region={props.region}
          onClickHandle={() => {
            props.onClickGoToStep(0);
          }}
        />
      )}
      {props.step > 1 && (
        <AppointmentBookingResultShop
          shop={props.shop}
          onClickHandle={() => {
            props.onClickGoToStep(1);
          }}
        />
      )}
      {props.step > 2 && (
        <AppointmentBookingResultAppointment
          appointment={props.appointment}
          timeText={props.timeText}
          priceText={props.priceText}
          paymentTypeText={props.paymentTypeText}
          assistant={props.assistant}
          assistantId={props.assistantId}
          assistantText={props.assistantText}
          onClickHandle={() => {
            props.onClickGoToStep(2);
          }}
        />
      )}
      {props.step > 3 && (
        <AppointmentBookingResultTime
          date={props.activeDate}
          weekday={props.activeWeekday}
          month={props.activeMonth}
          time={props.timeSlot}
          preposition={props.preposition}
          onClickHandle={() => {
            props.onClickGoToStep(3);
          }}
        />
      )}
      {props.step === 5 && (
        <AppointmentBookingResultAgree
          text={props.agreeText}
          status={props.agreeStatus}
        />
      )}
    </div>
  );
};

AppointmentBookingResultList.propTypes = {
  region: PropTypes.string,
  step: PropTypes.number,
  onClickGoToStep: PropTypes.func,
  shop: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string
  }),
  appointment: PropTypes.shape({
    appointmentName: PropTypes.string,
    appointmentTime: PropTypes.string,
    appointmentPrice: PropTypes.string
  }),
  timeText: PropTypes.string,
  priceText: PropTypes.string,
  paymentTypeText: PropTypes.string,
  assistant: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    timeSlots: PropTypes.arrayOf(PropTypes.string)
  }),
  assistantId: PropTypes.string,
  assistantText: PropTypes.string,
  activeDate: PropTypes.string,
  activeWeekday: PropTypes.string,
  activeMonth: PropTypes.string,
  preposition: PropTypes.string,
  timeSlot: PropTypes.string,
  agreeStatus: PropTypes.bool,
  agreeText: PropTypes.string
};

AppointmentBookingResultList.propTypesMeta = {
  region: 'exclude',
  step: 'exclude',
  shop: 'exclude',
  appointment: 'exclude',
  assistant: 'exclude',
  assistantId: 'exclude',
  activeDate: 'exclude',
  activeWeekday: 'exclude',
  activeMonth: 'exclude',
  timeSlot: 'exclude',
  agreeStatus: 'exclude',
  agreeText: 'exclude'
};

export default AppointmentBookingResultList;
