import React from 'react';
import PropTypes from 'prop-types';
import InspirationalItem from 'components/inspirational-items/inspirational-item';

const MagazineListItem = ({ ...props }) => {
  return (
    <InspirationalItem {...props} className="inspirational-item--in-magazine" />
  );
};

MagazineListItem.propTypes = Object.assign({}, InspirationalItem.propTypes, {
  category: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
});

export default MagazineListItem;
