import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';

const AppointmentBookingStepBackButton = ({ text, onClick = () => {} }) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isAndroid = /android/i.test(navigator.userAgent);

  return (
    <button onClick={onClick} className="appointment-booking-back-button">
      <Icon
        name="arrow-left"
        className="appointment-booking-back-button__icon"
      />
      <span
        className={cn('appointment-booking-back-button__text', {
          'appointment-booking-back-button__text--in-safari': isSafari,
          'appointment-booking-back-button__text--in-android': isAndroid
        })}
      >
        {text}
      </span>
    </button>
  );
};

AppointmentBookingStepBackButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
};

AppointmentBookingStepBackButton.propTypesMeta = 'exclude';

export default AppointmentBookingStepBackButton;
