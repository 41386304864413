import React from 'react';
import PropTypes from 'prop-types';

import PubSub from 'pubsub-js';

import api from 'js/api-helper';
import checkApiPropTypes from 'js/check-api-prop-types';
import topics from 'js/topics.json';

import AddToCartResponse from 'components/contact-lens-page/add-to-cart-response';
import Button from 'components/button';
import Form from 'components/form';
import PageSpinner from 'components/page-spinner';
import Select from 'components/select';
import Text from 'components/text';
import Link from 'components/link';
import ValidationErrorTexts from 'components/validation-error-texts';
import ReservInStore from 'components/reserve-in-store/reserve-in-store';
import cn from 'classnames';

const ActionTypes = {
  addToCard: 'addToCard',
  reserve: 'reserve'
};

const GlassesForm = ({
  addToCartEndpoint,
  reserveEndpoint,
  inputs,
  onUpdate,
  onRef,
  submitLabel,
  registerLabel,
  updateSizeEndpoint,
  validationErrors,
  hideAddToCartyButton = false,
  purchaseInformation
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const [actionType, setActionType] = React.useState(ActionTypes.addToCard);

  const onSubmit = () => setLoading(true);

  const onResponse = response => {
    if (actionType === ActionTypes.reserve) {
      PubSub.publish(topics.reserveUpdate, response);
      checkApiPropTypes(response, ReservInStore.propTypes, 'ReservInStore');
    } else {
      PubSub.publish(topics.cartUpdate, response);
      checkApiPropTypes(
        response,
        AddToCartResponse.propTypes,
        'AddToCartResponse'
      );
    }
    setLoading(false);
  };

  const onChange = value => {
    if (updateSizeEndpoint) {
      setLoading(true);
      api
        .execute(updateSizeEndpoint, value)
        .then(onUpdate)
        .finally(() => setLoading(false));
    }
  };

  if (inputs.length === 0) return null;

  return (
    <>
      <Form
        className="glasses-form"
        endpoint={
          actionType === 'reserve' ? reserveEndpoint : addToCartEndpoint
        }
        onSubmit={onSubmit}
        onChange={onChange}
        onRef={onRef}
        onResponse={onResponse}
        showSubmitButton={false}
        validationErrors={validationErrors}
      >
        {inputs.map((input, i) => {
          return input.options && input.options.length > 0 ? (
            <div className="glasses-form-input-wrapper" key={i}>
              <Text.div
                className={cn('glasses-form-select', {
                  'is-hidden': input.options.length === 1
                })}
                key={input.name}
                theme={Text.themes.small}
              >
                <Select
                  {...input}
                  idPrefix="glasses-select-"
                  labelVisible={true}
                  validations={['isRequired']}
                  labelAnchorScrollText={null}
                />
              </Text.div>
            </div>
          ) : null;
        })}

        <PageSpinner shouldContainInParent={true} isLoading={isLoading} />
        <div className="glasses-form-actions">
          {hideAddToCartyButton &&
            purchaseInformation &&
            purchaseInformation.text &&
            purchaseInformation.link && (
              <p className="glasses-form-purchase-information">
                {purchaseInformation.text}
              </p>
            )}
          <div className="glasses-form-button-wrapper">
            {!hideAddToCartyButton && (
              <Button
                className="glasses-form-submit"
                data-test-add-to-cart-button
                iconAfter="basket"
                type="submit"
                text={submitLabel}
                themes={[Button.themes.buttonGray]}
                onClick={() => setActionType(ActionTypes.addToCard)}
              />
            )}
            {registerLabel && (
              <Button
                className="glasses-form-register"
                type="submit"
                onClick={() => setActionType(ActionTypes.reserve)}
                text={registerLabel}
                themes={[Button.themes.buttonCyan]}
              />
            )}

            {hideAddToCartyButton &&
              purchaseInformation &&
              purchaseInformation.link && (
                <Link
                  className="glasses-form-purchase-link"
                  iconAfter="pin"
                  themes={[Link.themes.buttonGray]}
                  {...purchaseInformation.link}
                />
              )}
          </div>
        </div>
      </Form>
      <ReservInStore />
    </>
  );
};

GlassesForm.propTypes = {
  addToCartEndpoint: PropTypes.string,
  reserveEndpoint: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.exact(Select.propTypes)),
  onUpdate: PropTypes.func,
  onRef: PropTypes.func,
  submitLabel: PropTypes.string,
  registerLabel: PropTypes.string,
  updateSizeEndpoint: PropTypes.string,
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes),
  productName: PropTypes.string,
  hideAddToCartyButton: PropTypes.bool,
  purchaseInformation: PropTypes.exact({
    text: PropTypes.string,
    link: PropTypes.exact(Link.propTypes)
  })
};

GlassesForm.propTypesMeta = {
  productName: 'exclude',
  purchaseInformation: 'exclude'
};

GlassesForm.defaultProps = {
  inputs: [],
  onRef: () => {}
};

export default GlassesForm;
