import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';

const EmailTemplateHeader = ({ title, logo, text }) => (
  <div className="email-template-header">
    <Image {...logo} />
    <hr />
    <h1>{title}</h1>
    <p>{text}</p>
  </div>
);

EmailTemplateHeader.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.object,
  text: PropTypes.string
};

EmailTemplateHeader.propTypesMeta = 'exclude';

export default EmailTemplateHeader;
