import React from 'react';
import PropTypes from 'prop-types';
import AppointmentBookingAssistant from 'components/appointment-booking-modal/appointment-booking-assistant';
import AppointmentBookingDateList from 'components/appointment-booking-modal/appointment-booking-date-list';
import AppointmentBookingTimeList from 'components/appointment-booking-modal/appointment-booking-time-list';

const AppointmentBookingAvailability = props => {
  return (
    <div>
      <AppointmentBookingAssistant
        defaultText={props.assistantDefaultText}
        cancelText={props.assistantCancelText}
        label={props.assistantLabel}
        assistants={props.assistants}
        assistantId={props.assistantId}
        onClickAssistant={props.onClickAssistant}
        onClickRemoveAssistant={props.onClickRemoveAssistant}
        preselectedOpticianId={props.preselectedOpticianId}
      />
      <AppointmentBookingDateList
        dates={props.dates}
        activeDate={props.activeDate}
        currentWeekDay={props.currentWeekDay}
        carouselTitle={props.carouselTitle}
        carouselPreviousItemText={props.carouselPreviousItemText}
        carouselNextItemText={props.carouselNextItemText}
        onClickDate={props.onClickDate}
      />
      <AppointmentBookingTimeList
        title={props.timeListTitle}
        timeSlots={props.timeSlots}
        onClickTime={props.onClickTime}
      />
    </div>
  );
};

AppointmentBookingAvailability.propTypes = {
  assistantDefaultText: PropTypes.string.isRequired,
  assistantCancelText: PropTypes.string.isRequired,
  assistantLabel: PropTypes.string.isRequired,
  onClickAssistant: PropTypes.func,
  onClickRemoveAssistant: PropTypes.func,
  assistants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      dates: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          weekday: PropTypes.string,
          weekdayShort: PropTypes.string,
          month: PropTypes.string,
          timeSlots: PropTypes.arrayOf(PropTypes.string),
          timeListTitle: PropTypes.string.isRequired
        })
      )
    })
  ),
  assistantId: PropTypes.string,
  onClickDate: PropTypes.func,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      weekday: PropTypes.string.isRequired,
      weekdayShort: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      timeSlots: PropTypes.arrayOf(PropTypes.string).isRequired,
      timeListTitle: PropTypes.string.isRequired
    })
  ),
  activeDate: PropTypes.string,
  currentWeekDay: PropTypes.string.isRequired,
  carouselTitle: PropTypes.string.isRequired,
  carouselPreviousItemText: PropTypes.string.isRequired,
  carouselNextItemText: PropTypes.string.isRequired,
  timeListTitle: PropTypes.string.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickTime: PropTypes.func,
  preselectedOpticianId: PropTypes.string
};

AppointmentBookingAvailability.propTypesMeta = {
  assistantId: 'exclude',
  activeDate: 'exclude',
  timeSlots: 'exclude',
  preselectedOpticianId: 'exclude'
};

export default AppointmentBookingAvailability;
