import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const EmptyListMessage = ({ className, text }) => (
  <div className={cn('empty-list-message', className)}>{text}</div>
);

EmptyListMessage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

EmptyListMessage.propTypesMeta = 'exclude';

export default EmptyListMessage;
