import React from 'react';
import PropTypes from 'prop-types';

import rangeMap from '@creuna/utils/range-map';
import { useMultiSpringEffect } from 'use-spring-effect';

import ImageLoader from 'js/responsive-images';

import Button from 'components/button';
import Icon from 'components/icon';
import Spinner from 'components/spinner';

const ZoomModal = ({ src, imagePadding, imageWidth, onCloseButtonClick }) => {
  const [imageHeight, setImageHeight] = React.useState(0);
  const [image, setImage] = React.useState();

  const [transitionTo] = useMultiSpringEffect(
    { x: 0, y: 0 },
    ({ x, y }) => {
      if (image) image.style.transform = `translate(${x}px, ${y}px)`;
    },
    { stiffness: 150, damping: 20 },
    [image]
  );

  const onMouseMove = e => {
    const padding = imagePadding;
    const winW = window.innerWidth;
    const winH = window.innerHeight;
    const xDiff = (imageWidth - winW) / 2 + padding;
    const yDiff = (imageHeight - winH) / 2 + padding;

    const x =
      winW < imageWidth + 2 * padding
        ? rangeMap(e.clientX, 0, winW, xDiff, -xDiff)
        : 0;

    const y =
      winH < imageHeight + 2 * padding
        ? rangeMap(e.clientY, 0, winH, yDiff, -yDiff)
        : 0;

    transitionTo({ x, y });
  };

  React.useEffect(() => {
    if (!image) return;
    if (!image.complete || !image.naturalHeight) {
      image.onload = () => {
        setImageHeight(image.offsetHeight);
      };
    } else {
      setImageHeight(image.offsetHeight);
    }
  }, [image]);

  return (
    <div className="product-image-zoom" onMouseMove={onMouseMove}>
      <div className="product-image-zoom-spinner">
        <Spinner theme={Spinner.themes.big} />
      </div>

      <img
        src={ImageLoader.getNewSrc(src, imageWidth)}
        style={{ width: imageWidth }}
        ref={setImage}
      />
      <Button
        className="product-image-zoom-close"
        onClick={onCloseButtonClick}
        themes={[Button.themes.circle]}
      >
        <Icon name="x" />
      </Button>
    </div>
  );
};

ZoomModal.propTypes = {
  src: PropTypes.string.isRequired,
  imagePadding: PropTypes.number,
  imageWidth: PropTypes.number,
  onCloseButtonClick: PropTypes.func.isRequired
};

ZoomModal.propTypesMeta = 'exclude';

ZoomModal.defaultProps = {
  imagePadding: 100,
  imageWidth: 1400
};

export default ZoomModal;
