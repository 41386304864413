import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useFormValidation from 'hooks/use-form-validation';

import Icon from 'components/icon';

import InfoNote from 'components/info-note';

const themes = {
  alignTop: 'theme-align-top',
  grid: 'theme-grid',
  hiddenCheckbox: 'theme-hidden-checkbox',
  link: 'theme-link'
};

const Checkbox = ({
  children,
  className,
  defaultChecked,
  disabled,
  idPrefix,
  label,
  description,
  tooltip,
  name,
  title,
  onChange,
  theme,
  value,
  validationMessageHidden,
  validations
}) => {
  const [error, validate] = useFormValidation(name, validations);
  const id = idPrefix + '-' + name + (value ? '-' + value : '');

  return (
    <div className={cn('checkbox', theme, { 'has-error': error }, className)}>
      <input
        type="checkbox"
        value={value}
        defaultChecked={defaultChecked}
        id={id}
        name={name}
        onChange={e => {
          validate();
          onChange(e.target.checked);
        }}
        disabled={disabled}
      />

      <label htmlFor={id} title={title}>
        <div className="checkbox-fake">
          <Icon name="checkmark" className="checkbox-checkmark" />
        </div>
        {children ? children : <span>{label}</span>}
      </label>
      {description && (
        <div className="checkbox-description">
          <div className="checkbox-description-text">{description}</div>
          {tooltip && (
            <InfoNote {...tooltip} className="checkbox-description-tooltip" />
          )}
        </div>
      )}
      {!!error && !validationMessageHidden && (
        <div className="checkbox-error">{error}</div>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  idPrefix: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.exact(InfoNote.propTypes),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(Object.values(themes)),
  value: PropTypes.string,
  validationMessageHidden: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.string)
};

Checkbox.propTypesMeta = {
  className: 'exclude',
  idPrefix: 'exclude',
  theme: 'exclude',
  title: 'exclude',
  validationMessageHidden: 'exclude',
  validations: 'exclude'
};

Checkbox.defaultProps = {
  idPrefix: '',
  onChange: () => {}
};

Checkbox.themes = themes;

export default Checkbox;
