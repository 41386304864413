import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

// Use class component to be able to ref
class ContentContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    element: PropTypes.string
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    element: 'div'
  };

  state = {};

  render() {
    const Element = this.props.element;

    return (
      <Element className={cn('content-container', this.props.className)}>
        {this.props.children}
      </Element>
    );
  }
}

export default ContentContainer;
