import { useEffect, useRef, useState } from 'react';

const useInterval = (callback, delayInSeconds) => {
  const savedCallback = useRef();

  const [seconds, setSeconds] = useState(0);

  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  function reset() {
    setSeconds(0);
  }

  function stop() {
    setIsStopped(true);
  }

  function start() {
    setIsStopped(false);
  }

  function pause() {
    setIsPaused(true);
  }

  function unpause() {
    setIsPaused(false);
  }

  // Set up the interval.
  useEffect(() => {
    let interval = null;

    if (!delayInSeconds) return () => clearInterval(interval);

    function tick() {
      savedCallback.current();
    }

    if (!isPaused && !isStopped) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);

        if (seconds === 0) return; // we skip the first iteration

        if (seconds % delayInSeconds === 0) tick();
      }, 1000);
    } else if ((isPaused || isStopped) && seconds !== 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isPaused, isStopped, seconds]);

  return {
    reset,
    stop,
    start,
    pause,
    unpause,
    isPaused,
    isStopped
  };
};

export default useInterval;
