import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/select';

const InputTable = ({ columnLabels, rows }) => {
  if (!rows) return null;

  return (
    <table className="input-table">
      <thead>
        <tr>
          <th />
          {columnLabels.map((label, index) => (
            <th id={`column-${index}-header`} key={label}>
              {label}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={row.label}>
            <th id={`row-${rowIndex}-header`}>{row.label}</th>
            {row.inputs.map((input, colIndex) => {
              const ariaId = `colum-${colIndex}-header row-${rowIndex}-header`;

              return (
                <td key={input.name}>
                  <Select ariaLabelledBy={ariaId} {...input} />
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

InputTable.propTypes = {
  columnLabels: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      inputs: PropTypes.arrayOf(PropTypes.exact(Select.propTypes))
    })
  )
};

InputTable.defaultProps = {
  columnLabels: [],
  rows: []
};

export default InputTable;
