/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import NewsletterForm from 'components/newsletter-form';
import cn from 'classnames';

const themes = {
  full: 'theme-full',
  small: 'theme-small',
  compact: 'theme-compact'
};

const colors = {
  cyan: 'cyan',
  gray: 'gray',
  orange: 'orange',
  cold: 'cold',
  clinical: 'clinical',
  blue: 'blue',
  nude: 'nude',
  mint: 'mint',
  purple: 'purple'
};

const NewsletterFormBlock = ({ ...props }) => {
  return (
    <div
      className={cn('newsletter-form-block', props.theme, {
        [`newsletter-form-block--${colors[props.color]}`]: colors[props.color]
      })}
    >
      <NewsletterForm {...props} color={props.color} />
    </div>
  );
};

NewsletterFormBlock.propTypes = Object.assign({}, NewsletterForm.propTypes, {
  color: PropTypes.oneOf(Object.values(colors)),
  theme: PropTypes.oneOf(Object.values(themes))
});

NewsletterFormBlock.themes = themes;

export default NewsletterFormBlock;
