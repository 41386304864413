import React from 'react';
import PropTypes from 'prop-types';

import Collapse from 'react-tiny-collapse';

import useIsMounted from 'hooks/use-is-mounted';

import Checkbox from 'components/checkbox';
import Link from '../link';
import TextInput from 'components/text-input';
import Text from 'components/text';

/* eslint-disable react/no-multi-comp */
// Utility function to make the render method more compact.
const getTextInput = (props, validation, type, autocomplete) =>
  props ? (
    <TextInput
      idPrefix="personal-information"
      validations={validation}
      autocomplete={autocomplete ? autocomplete : undefined}
      type={type}
      {...props}
    />
  ) : null;

const PersonalInformationForm = ({
  address,
  birthDate,
  birthDateLink,
  changeEmailLink,
  changePasswordLink,
  city,
  deliveryAddress,
  deliveryAddressTitle,
  deliveryCity,
  deliveryFirstName,
  deliveryLastName,
  deliveryPostalCode,
  email,
  firstName,
  lastName,
  password,
  phone,
  postalCode,
  registerAccount,
  separateDeliveryAddress,
  showDeliveryAddress,
  showDeliveryAddressCheckbox,
  title
}) => {
  const isMounted = useIsMounted();

  const [showDelivery, setShowDelivery] = React.useState(showDeliveryAddress);

  const toggleSeparateDeliveryAddress = checked => {
    setShowDelivery(checked);
  };

  return (
    <React.Fragment>
      <div className="personal-information-group">
        {title && <h3>{title}</h3>}

        <div className="personal-information-input personal-information-input--full">
          {getTextInput(email, ['isEmail'], 'email', 'email')}
        </div>

        {(changeEmailLink || changePasswordLink) && (
          <div className="personal-information-input">
            <Text.div
              className="personal-information-form-links"
              theme={Text.themes.small}
            >
              {changeEmailLink && <Link {...changeEmailLink} />}

              {changePasswordLink && <Link {...changePasswordLink} />}
            </Text.div>
          </div>
        )}

        <div className="personal-information-input personal-information-input--full">
          {getTextInput(password, [], 'password', 'new-password')}
        </div>

        <div className="personal-information-input">
          {getTextInput(firstName, [], '', 'given-name')}
        </div>

        <div className="personal-information-input">
          {getTextInput(lastName, [], '', 'family-name')}
        </div>

        <div className="personal-information-input personal-information-input--break">
          {getTextInput(phone, ['isPhoneNumber'], '', 'tel')}
        </div>

        <div className="personal-information-input">
          {getTextInput(address, [], '', 'street-address')}
        </div>

        <div className="personal-information-input personal-information-input--fourth">
          {getTextInput(postalCode, ['isPostalCode'], '', 'postal-code')}
        </div>

        <div className="personal-information-input personal-information-input--fourth">
          {getTextInput(city, [], '', '')}
        </div>

        {separateDeliveryAddress && showDeliveryAddressCheckbox && (
          <div className="personal-information-input personal-information-input--full">
            <Checkbox
              idPrefix="personal-information"
              theme={Checkbox.themes.grid}
              onChange={toggleSeparateDeliveryAddress}
              {...separateDeliveryAddress}
            />
          </div>
        )}
      </div>

      {deliveryFirstName &&
        deliveryLastName &&
        deliveryAddress &&
        deliveryPostalCode && (
          <div className="personal-information-collapse">
            <Collapse
              unmountChildren={true}
              isOpen={!isMounted || showDelivery}
            >
              <div className="personal-information-group">
                <h3>{deliveryAddressTitle}</h3>

                <div className="personal-information-input">
                  {getTextInput(deliveryFirstName, [], '', 'given-name')}
                </div>

                <div className="personal-information-input">
                  {getTextInput(deliveryLastName, [], '', 'family-name')}
                </div>

                <div className="personal-information-input">
                  {getTextInput(deliveryAddress, [], '', 'street-address')}
                </div>

                <div className="personal-information-input personal-information-input--fourth">
                  {getTextInput(
                    deliveryPostalCode,
                    ['isPostalCode'],
                    '',
                    'postal-code'
                  )}
                </div>

                <div className="personal-information-input personal-information-input--fourth">
                  {getTextInput(deliveryCity, [], '', '')}
                </div>
              </div>
            </Collapse>
          </div>
        )}

      <div className="personal-information-group personal-information-group--centered">
        <div className="personal-information-input personal-information-input--third">
          {getTextInput(birthDate, ['isBirthDate'], '', '')}
        </div>
        {birthDateLink && (
          <Text className="personal-information-link" theme={Text.themes.small}>
            <Link {...birthDateLink} />
          </Text>
        )}
      </div>

      {registerAccount && (
        <div className="personal-information-group">
          <Checkbox
            idPrefix="personal-information"
            theme={Checkbox.themes.grid}
            {...registerAccount}
          />
        </div>
      )}
    </React.Fragment>
  );
};

PersonalInformationForm.propTypes = {
  address: PropTypes.exact(TextInput.propTypes).isRequired,
  birthDate: PropTypes.exact(TextInput.propTypes).isRequired,
  birthDateLink: PropTypes.exact(Link.propTypes),
  changeEmailLink: PropTypes.exact(Link.propTypes),
  changePasswordLink: PropTypes.exact(Link.propTypes),
  city: PropTypes.exact(TextInput.propTypes),
  deliveryAddress: PropTypes.exact(TextInput.propTypes),
  deliveryAddressTitle: PropTypes.string,
  deliveryCity: PropTypes.exact(TextInput.propTypes),
  deliveryFirstName: PropTypes.exact(TextInput.propTypes),
  deliveryLastName: PropTypes.exact(TextInput.propTypes),
  deliveryPostalCode: PropTypes.exact(TextInput.propTypes),
  email: PropTypes.exact(TextInput.propTypes).isRequired,
  firstName: PropTypes.exact(TextInput.propTypes).isRequired,
  lastName: PropTypes.exact(TextInput.propTypes).isRequired,
  password: PropTypes.exact(TextInput.propTypes),
  phone: PropTypes.exact(TextInput.propTypes).isRequired,
  postalCode: PropTypes.exact(TextInput.propTypes).isRequired,
  registerAccount: PropTypes.exact(Checkbox.propTypes),
  separateDeliveryAddress: PropTypes.exact(Checkbox.propTypes),
  showDeliveryAddress: PropTypes.bool,
  showDeliveryAddressCheckbox: PropTypes.bool,
  title: PropTypes.string
};

PersonalInformationForm.propTypesMeta = {
  loginFormId: 'exclude',
  showDeliveryAddress: 'exclude',
  showDeliveryAddressCheckbox: 'exclude'
};

PersonalInformationForm.defaultProps = {
  onValidate: () => {},
  showDeliveryAddress: false,
  showDeliveryAddressCheckbox: true
};

export default PersonalInformationForm;
