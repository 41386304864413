import React from 'react';
import PropTypes from 'prop-types';

import GenericFilter from 'components/generic-filter';
import ColorSelect from 'components/color-select';
import Link from 'components/link';
import ShapeSelect from 'components/shape-select';
import Text from 'components/text';

const FilterGroup = ({
  colorSelect,
  genericFilter,
  link,
  shapeSelect,
  title
}) => {
  let FilterComponent;

  const filter = colorSelect ? (
    <ColorSelect idPrefix="color-filter-" {...colorSelect} />
  ) : shapeSelect ? (
    <ShapeSelect idPrefix="category-filter" {...shapeSelect} />
  ) : genericFilter ? (
    <GenericFilter idPrefix="category-filter" {...genericFilter} />
  ) : null;

  return !FilterComponent && !filter ? null : (
    <Text.div className="filter-group" theme={Text.themes.small}>
      <Text.h3 theme={Text.themes.boldUppercaseSmall}>{title}</Text.h3>
      <div className="filter-group-content">
        {filter}
        {FilterComponent}
      </div>
      {link && <Link {...link} />}
    </Text.div>
  );
};

FilterGroup.propTypes = {
  colorSelect: PropTypes.exact(ColorSelect.propTypes),
  genericFilter: PropTypes.exact(GenericFilter.propTypes),
  link: PropTypes.exact(Link.propTypes),
  shapeSelect: PropTypes.exact(ShapeSelect.propTypes),
  title: PropTypes.string
};

export default FilterGroup;
