import React from 'react';
import PropTypes from 'prop-types';

import useFormValidation from 'hooks/use-form-validation';

const Radio = ({
  attributes,
  children,
  defaultChecked,
  idPrefix,
  label,
  name,
  onChange,
  value,
  validations,
  validationMessageHidden
}) => {
  const [error, validate] = useFormValidation(name, validations);

  const id = idPrefix + '-' + name + '-' + value;

  return (
    <div className="radio">
      <input
        {...attributes}
        defaultChecked={defaultChecked}
        type="radio"
        id={id}
        name={name}
        onChange={e => {
          onChange(e.target.value);
          validate();
        }}
        value={value}
      />

      <label htmlFor={id}>{children || label}</label>

      {!validationMessageHidden && error && (
        <div className="radio-error-message">{error}</div>
      )}
    </div>
  );
};

Radio.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  defaultChecked: PropTypes.bool,
  idPrefix: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.string),
  validationMessageHidden: PropTypes.bool
};

Radio.propTypesMeta = {
  attributes: 'exclude',
  idPrefix: 'exclude',
  validations: 'exclude',
  validationMessageHidden: 'exclude'
};

Radio.defaultProps = {
  idPrefix: '',
  onChange: () => {}
};

export default Radio;
