import React, { useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';

import Collapse from 'react-tiny-collapse';

import ContentContainer from 'components/content-container';
import Cart from 'components/cart';
import GlobalSearch from 'components/global-search';
import Link from 'components/link';
import LoginModal from 'components/login-modal';
import MainNav from './main-nav';
import Modal from 'components/modal';

import ModalContext from '../../context/modal';

const HeaderModals = ({
  isDesktop,
  isTablet,
  isMobile,
  cart,
  cartId,
  cartLink,
  loginModal,
  myPageLink,
  mainNavigation,
  search,
  searchId,
  onCartUpdate
}) => {
  const modalContext = useContext(ModalContext);
  const ModalContentWrapper = isTablet ? 'div' : ContentContainer;

  useEffect(() => {
    if (!isMobile && modalContext.isMobileMenuOpen)
      modalContext.closeMobileMenu();
  }, [isMobile]);

  const hideMobileMenu = () => {
    modalContext.closeMobileMenu();
  };

  const toggleLogin = e => {
    e.preventDefault();
    modalContext.toggleLoginModal();
  };

  const hideModals = () => {
    modalContext.closeModals();
  };

  return (
    <Fragment>
      <MainNav
        hideMobileMenu={hideMobileMenu}
        isDesktop={isDesktop}
        isExpanded={modalContext.isMobileMenuOpen}
        myPageLink={myPageLink}
        toggleLogin={toggleLogin}
        {...mainNavigation}
      />

      <Modal
        animate={false}
        className="header-login"
        containerClassName="header-login-inner"
        contentClassName="header-login-content"
        id={searchId}
        isVisible={modalContext.isLoginModalOpen}
        hide={hideModals}
      >
        <Collapse unmountChildren={true} isOpen={modalContext.isLoginModalOpen}>
          <ModalContentWrapper>
            <LoginModal {...loginModal} />
          </ModalContentWrapper>
        </Collapse>
      </Modal>

      <Modal
        animate={false}
        className="header-search"
        containerClassName="header-search-inner"
        contentClassName="header-search-content"
        hide={hideModals}
        id={searchId}
        isVisible={modalContext.isSearchModalOpen}
      >
        <Collapse
          unmountChildren={false}
          isOpen={modalContext.isSearchModalOpen}
        >
          <GlobalSearch {...search} />
        </Collapse>
      </Modal>

      <Modal
        animate={false}
        className="header-cart"
        containerClassName="header-cart-inner"
        contentClassName="header-cart-content"
        hide={hideModals}
        id={cartId}
        isVisible={modalContext.isCartModalOpen}
      >
        <Collapse isOpen={modalContext.isCartModalOpen}>
          <ModalContentWrapper>
            <Cart onUpdate={onCartUpdate} theme={Cart.themes.mini} {...cart}>
              <Link
                data-test-confirmation-go-to-cart-button
                iconAfter="chevron-right"
                themes={[Link.themes.buttonCyan, Link.themes.wide]}
                {...cartLink}
              />
            </Cart>
          </ModalContentWrapper>
        </Collapse>
      </Modal>
    </Fragment>
  );
};

HeaderModals.propTypes = {
  isDesktop: PropTypes.bool,
  isTablet: PropTypes.bool,
  isMobile: PropTypes.bool,
  cart: PropTypes.exact(Cart.propTypes),
  cartId: PropTypes.string,
  cartLink: PropTypes.exact(Link.propTypes),
  loginModal: PropTypes.exact(LoginModal.propTypes),
  myPageLink: PropTypes.exact(Link.propTypes),
  mainNavigation: PropTypes.exact(MainNav.propTypes),
  search: PropTypes.exact(GlobalSearch.propTypes),
  searchId: PropTypes.string,
  onCartUpdate: PropTypes.func
};

export default HeaderModals;
