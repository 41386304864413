import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from 'components/text';
import Image from 'components/image';
import RichText from 'components/rich-text';

const ProductsHeader = ({ title, text, image }) => {
  const hasImage = image && image.src;

  return (
    <div
      className={cn('products-header', {
        'products-header--image': hasImage
      })}
    >
      <div className="products-header__text">
        <div className="products-header__text-content">
          {title && (
            <Text.h2 className="products-header__title">{title}</Text.h2>
          )}
          {text && (
            <RichText
              text={text}
              hasBottomMargin={false}
              className="products-header__lead"
            />
          )}
        </div>
      </div>
      {hasImage && (
        <div className="products-header__image">
          <Image {...image} />
        </div>
      )}
    </div>
  );
};

ProductsHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.exact(Image.propTypes)
};

ProductsHeader.propTypesMeta = {};

export default ProductsHeader;
