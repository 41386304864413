import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Link from 'components/link';
import Text from 'components/text';

const FooterSocialMedia = ({ title, links }) => (
  <div className="footer-social-media-list">
    <Text.p theme={Text.themes.small}>{title}</Text.p>
    <ul>
      {links.map(({ image, link }) => (
        <li key={link.url + link.text}>
          <Link hiddenText={true} {...link}>
            <Image {...image} />
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

FooterSocialMedia.defaultProps = {
  links: []
};

FooterSocialMedia.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      image: PropTypes.exact(Image.propTypes)
    })
  )
};

export default FooterSocialMedia;
