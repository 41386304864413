import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link';
import Image from 'components/image/image';
import cn from 'classnames';

const Logo = ({ className, imageClassName, link, image }) => {
  return (
    <a href={link.url} className={cn('logo', className)}>
      <img
        src={image.src}
        alt="Interoptik logo"
        className={cn('logo__image', imageClassName)}
      />
    </a>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  image: PropTypes.exact(Image.propTypes).isRequired,
  link: PropTypes.exact(Link.propTypes).isRequired
};

Logo.propTypesMeta = {
  className: 'exclude',
  imageClassName: 'exclude'
};

export default Logo;
