import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import Grid from '../grid';
import Image from 'components/image';
import ProductList from '../product-list';
import SearchResultHeader from './search-result-header';

import Link from 'components/link';

const SearchResults = ({
  articles,
  articlesHeader,
  articlesBottomLink,
  children,
  products,
  productsHeader,
  productsBottomLink
}) => {
  const hasResults =
    get(products, 'products', []).length > 0 || articles.length > 0;
  return (
    <React.Fragment>
      {hasResults && (
        <Grid theme={[Grid.themes.leftColumnSuperWide]}>
          <Grid.Column>
            {productsHeader && <SearchResultHeader {...productsHeader} />}
            {products && (
              <div className="global-search-results-products">
                <ProductList
                  theme={[
                    ProductList.themes.narrow,
                    ProductList.themes.noMargin
                  ]}
                  {...products}
                />
              </div>
            )}
            {productsBottomLink && (
              <div className="global-search-results-footer">
                <Link
                  themes={[Link.themes.buttonCyan]}
                  {...productsBottomLink}
                />
              </div>
            )}
          </Grid.Column>

          <Grid.Column>
            {articlesHeader && <SearchResultHeader {...articlesHeader} />}
            {articles.length > 0 && (
              <ul className="global-search-articles">
                {articles.map(({ image, text, url }) => (
                  <li key={url}>
                    <a href={url} className="global-search-article">
                      <Image {...image} />
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {articlesBottomLink && (
              <div className="global-search-results-footer">
                <Link
                  themes={[Link.themes.buttonCyan]}
                  {...articlesBottomLink}
                />
              </div>
            )}
          </Grid.Column>
        </Grid>
      )}

      {children(hasResults)}
    </React.Fragment>
  );
};

SearchResults.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.exact({
      image: PropTypes.exact(Image.propTypes),
      text: PropTypes.string,
      url: PropTypes.string
    })
  ),
  articlesBottomLink: PropTypes.exact(Link.propTypes),
  productsBottomLink: PropTypes.exact(Link.propTypes),
  articlesHeader: PropTypes.exact(SearchResultHeader.propTypes),
  children: PropTypes.func,
  products: PropTypes.exact(ProductList.propTypes),
  productsHeader: PropTypes.exact(SearchResultHeader.propTypes)
};

SearchResults.defaultProps = {
  articles: [],
  children: () => {}
};

export default SearchResults;
