import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

const PaginationControls = ({ previousLink, nextLink, links }) => {
  return (
    <div className="pagination-controls">
      <nav className="pagination-controls__list">
        <div className="pagination-controls__container">
          {previousLink && (
            <div className="pagination-controls__item">
              {previousLink.isDisabled ? (
                <span className="pagination-controls__browse-link pagination-controls__browse-link--disabled">
                  <Icon name="previous-enabled" />
                  <span className="pagination-controls__link-text">
                    {previousLink.text}
                  </span>
                </span>
              ) : (
                <a
                  href={previousLink.url}
                  className="pagination-controls__browse-link"
                >
                  <Icon name="previous-enabled" />
                  <span className="pagination-controls__link-text">
                    {previousLink.text}
                  </span>
                </a>
              )}
            </div>
          )}
        </div>
        <div className="pagination-controls__pages-container">
          <ul className="pagination-controls__pages-list">
            {links.map((link, index) => {
              return (
                <li className="pagination-controls__page-item" key={index}>
                  {link.isDisabled ? (
                    <span className="pagination-controls__page-link pagination-controls__page-link--disabled">
                      <span className="pagination-controls__page-link-content">
                        {link.text}
                      </span>
                    </span>
                  ) : (
                    <a
                      href={link.url}
                      className="pagination-controls__page-link"
                    >
                      <span className="pagination-controls__page-link-content">
                        {link.text}
                      </span>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="pagination-controls__container">
          {nextLink && (
            <div className="pagination-controls__item">
              {nextLink.isDisabled ? (
                <span className="pagination-controls__browse-link pagination-controls__browse-link--disabled">
                  <Icon name="next-enabled" />
                  <span className="pagination-controls__link-text">
                    {nextLink.text}
                  </span>
                </span>
              ) : (
                <a
                  href={nextLink.url}
                  className="pagination-controls__browse-link"
                >
                  <span className="pagination-controls__link-text">
                    {nextLink.text}
                  </span>
                  <Icon name="next-enabled" />
                </a>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

PaginationControls.propTypes = {
  previousLink: PropTypes.exact({
    text: PropTypes.string,
    url: PropTypes.string,
    isDisabled: PropTypes.bool
  }),
  nextLink: PropTypes.exact({
    text: PropTypes.string,
    url: PropTypes.string,
    isDisabled: PropTypes.bool
  }),
  links: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      url: PropTypes.string,
      isDisabled: PropTypes.bool
    })
  )
};

PaginationControls.defaultProps = {
  links: []
};

export default PaginationControls;
