import React from 'react';
import PropTypes from 'prop-types';
import AppointmentBookingTimeItem from 'components/appointment-booking-modal/appointment-booking-time-item';

const AppointmentBookingTimeList = props => {
  return (
    <div className="appointment-booking-time-list">
      <span className="appointment-booking-time-list__title">
        {props.title}
      </span>
      <div className="appointment-booking-time-list__inner">
        {props.timeSlots.map((time, index) => (
          <AppointmentBookingTimeItem
            key={index}
            time={time}
            onClickTime={props.onClickTime}
          />
        ))}
      </div>
    </div>
  );
};

AppointmentBookingTimeList.propTypes = {
  title: PropTypes.string.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickTime: PropTypes.func
};

AppointmentBookingTimeList.propTypesMeta = {
  timeSlots: 'exclude'
};

export default AppointmentBookingTimeList;
