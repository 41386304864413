import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const AppointmentBookingResultAgree = props => {
  return (
    <div className="appointment-booking-result-agree">
      {props.status && (
        <Fragment>
          <div className="appointment-booking-result-agree__checkmark-box">
            <Icon name="checkmark" />
          </div>
          <span className="appointment-booking-result-agree__text">
            {props.text}
          </span>
        </Fragment>
      )}
    </div>
  );
};

AppointmentBookingResultAgree.propTypes = {
  text: PropTypes.string,
  status: PropTypes.bool
};

AppointmentBookingResultAgree.propTypesMeta = 'exclude';

export default AppointmentBookingResultAgree;
