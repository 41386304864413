import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';
import RichText from '../rich-text';
import BookingLink from 'components/booking-link';
import OpeningHoursMeta from 'components/openingHoursMeta/openingHoursMeta';

const StoreMeta = ({
  storeName,
  openingHours,
  openingHoursLabel,
  openingHoursMeta,
  contactInfoLabel,
  address,
  addressMeta,
  geoMeta,
  bookingLink,
  phone,
  email
}) => (
  <div className="store-meta" itemScope itemType="http://schema.org/Optician">
    <div className="store-meta__content">
      <div className="store-meta__container">
        <p className="store-meta__heading" itemProp="name">
          {storeName}
        </p>
        <RichText
          text={address}
          element="address"
          className="store-meta__address"
        />
        {addressMeta && (
          <div
            itemScope
            itemProp="address"
            itemType="http://schema.org/PostalAddress"
          >
            {addressMeta.streetAddress && (
              <meta
                itemProp="streetAddress"
                content={addressMeta.streetAddress}
              />
            )}
            {addressMeta.postalCode && (
              <meta itemProp="postalCode" content={addressMeta.postalCode} />
            )}
            {addressMeta.addressLocality && (
              <meta
                itemProp="addressLocality"
                content={addressMeta.addressLocality}
              />
            )}
          </div>
        )}
        {geoMeta && (
          <div
            itemProp="geo"
            itemScope
            itemType="http://schema.org/GeoCoordinates"
          >
            {geoMeta.lat && <meta itemProp="latitude" content={geoMeta.lat} />}
            {geoMeta.lng && <meta itemProp="longitude" content={geoMeta.lng} />}
          </div>
        )}
      </div>
      <div className="store-meta__container">
        <p className="store-meta__heading">{openingHoursLabel}</p>
        <RichText text={openingHours} />
        {openingHoursMeta && <OpeningHoursMeta {...openingHoursMeta} />}
      </div>
      <div className="store-meta__container">
        <p className="store-meta__heading">{contactInfoLabel}</p>
        <Link className="store-meta__link" {...phone} />
        <Link className="store-meta__link" {...email} />

        {phone && phone.text && (
          <meta itemProp="telephone" content={phone.text} />
        )}
        {email && email.text && <meta itemProp="email" content={email.text} />}
      </div>
    </div>
    <div className="store-meta__actions">
      <BookingLink themes={[BookingLink.themes.buttonCyan]} {...bookingLink} />
    </div>
  </div>
);

StoreMeta.propTypes = {
  storeName: PropTypes.string,
  openingHours: PropTypes.string,
  openingHoursLabel: PropTypes.string,
  openingHoursMeta: PropTypes.exact(OpeningHoursMeta.propTypes),
  contactInfoLabel: PropTypes.string,
  address: PropTypes.string,
  addressMeta: PropTypes.shape({
    streetAddress: PropTypes.string,
    postalCode: PropTypes.string,
    addressLocality: PropTypes.string
  }),
  geoMeta: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string
  }),
  phone: PropTypes.exact(Link.propTypes),
  email: PropTypes.exact(Link.propTypes),
  bookingLink: PropTypes.exact(BookingLink.propTypes)
};

export default StoreMeta;
