import React from 'react';

export default function useToggle(initialValue = false) {
  const [isActive, setActive] = React.useState(initialValue);

  const toggle = () => setActive(active => !active);
  const deactivate = () => setActive(false);

  return [isActive, toggle, deactivate];
}
