import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from '../button';
import CartProductDetails from './cart-product-details';
import CartProductStatus from './cart-product-status';
import Image from '../image';
import Form from 'components/form';
import Icon from '../icon';
import Link from '../link';
import Price from '../price';
import Text from '../text';

class CartProduct extends React.Component {
  static propTypes = {
    collapseLabel: PropTypes.string,
    decreaseQuantityEndpoint: PropTypes.string,
    decreaseQuantityLabel: PropTypes.string,
    details: PropTypes.exact(CartProductDetails.propTypes),
    discount: PropTypes.string,
    expandLabel: PropTypes.string,
    id: PropTypes.string, // NOTE: Used as key in Cart
    image: PropTypes.exact(Image.propTypes),
    increaseQuantityEndpoint: PropTypes.string,
    increaseQuantityLabel: PropTypes.string,
    lineThrough: PropTypes.bool,
    onSubmit: PropTypes.func,
    onUpdate: PropTypes.func,
    price: PropTypes.exact(Price.propTypes),
    priceLabel: PropTypes.string,
    productUrl: PropTypes.string,
    quantity: PropTypes.number,
    quantityLabel: PropTypes.string,
    removeFromCartEndpoint: PropTypes.string,
    removeFromCartLabel: PropTypes.string,
    shouldBeEditable: PropTypes.bool,
    shouldShowDetails: PropTypes.bool,
    status: PropTypes.exact(CartProductStatus.propTypes),
    title: PropTypes.string,
    unitPrice: PropTypes.exact(Price.propTypes),
    unitPriceLabel: PropTypes.string
  };

  static propTypesMeta = {
    decreaseQuantityLabel: 'exclude', // NOTE: Provided by Cart
    increaseQuantityLabel: 'exclude', // NOTE: Provided by Cart
    lineThrough: 'exclude',
    removeFromCartLabel: 'exclude', // NOTE: Provided by Cart
    shouldBeEditable: 'exclude',
    shouldShowDetails: 'exclude'
  };

  state = {
    isDetailsExpanded: false
  };

  toggle = e => {
    e.preventDefault();
    this.setState(state => ({ isDetailsExpanded: !state.isDetailsExpanded }));
  };

  render() {
    const priceThemes = [].concat(
      this.props.lineThrough ? Price.themes.lineThrough : []
    );

    return (
      <tbody
        data-test-cart-product
        className={cn('cart-product', {
          'line-through': this.props.lineThrough
        })}
      >
        {this.props.status && (
          <CartProductStatus colSpan={5} {...this.props.status} />
        )}
        <tr>
          <td className="cart-product-image">
            <div className="cart-product-image">
              <Image theme={Image.themes.contained} {...this.props.image} />
            </div>
          </td>

          <td className="cart-product-name">
            {this.props.productUrl ? (
              <Link url={this.props.productUrl} text={this.props.title} />
            ) : (
              <p>{this.props.title}</p>
            )}
            {this.props.details && this.props.shouldShowDetails && (
              <div className="cart-product-show-details">
                <Button
                  onClick={this.toggle}
                  themes={[Button.themes.link]}
                  text={
                    this.state.isDetailsExpanded
                      ? this.props.collapseLabel
                      : this.props.expandLabel
                  }
                />
              </div>
            )}
            {this.props.discount && (
              <div className="cart-product-discount">
                <Text.div
                  theme={Text.themes.filled}
                  className="cart-product-tag"
                >
                  {this.props.discount}
                </Text.div>
              </div>
            )}
          </td>

          <td className="cart-product-quantity">
            {this.props.quantityLabel && (
              <div className="cart-product-quantity-label">
                {this.props.quantityLabel}
              </div>
            )}
            <div className="cart-product-form">
              {this.props.shouldBeEditable && (
                <Form
                  endpoint={this.props.decreaseQuantityEndpoint}
                  onSubmit={this.props.onSubmit}
                  onResponse={this.props.onUpdate}
                >
                  <Button
                    aria-label={this.props.decreaseQuantityLabel}
                    type="submit"
                    themes={[Button.themes.circleSmall]}
                  >
                    <Icon name="small-minus" />
                  </Button>
                </Form>
              )}

              <p>{this.props.quantity}</p>

              {this.props.shouldBeEditable && (
                <Form
                  endpoint={this.props.increaseQuantityEndpoint}
                  onSubmit={this.props.onSubmit}
                  onResponse={this.props.onUpdate}
                >
                  <Button
                    aria-label={this.props.increaseQuantityLabel}
                    type="submit"
                    themes={[Button.themes.circleSmall]}
                  >
                    <Icon name="small-plus" />
                  </Button>
                </Form>
              )}
            </div>
          </td>

          {this.props.unitPriceLabel && this.props.unitPrice && (
            <td>
              {this.props.unitPriceLabel && (
                <div className="cart-product-unit-price-label">
                  {this.props.unitPriceLabel}
                </div>
              )}
              <div className="cart-product-price">
                <Price
                  theme={priceThemes.concat(Price.themes.mediumFontWeight)}
                  {...this.props.unitPrice}
                />
              </div>
            </td>
          )}

          <td>
            {this.props.priceLabel && (
              <div className="cart-product-price-label">
                {this.props.priceLabel}
              </div>
            )}
            <div className="cart-product-price">
              <Price theme={priceThemes} {...this.props.price} />
            </div>
          </td>

          {this.props.shouldBeEditable && (
            <td className="cart-product-remove">
              <Form
                endpoint={this.props.removeFromCartEndpoint}
                onSubmit={this.props.onSubmit}
                onResponse={this.props.onUpdate}
              >
                <Button
                  aria-label={this.props.removeFromCartLabel}
                  type="submit"
                  themes={[Button.themes.circleSmall]}
                >
                  <Icon name="small-x" />
                </Button>
              </Form>
            </td>
          )}
        </tr>
        {this.props.details && this.props.shouldShowDetails && (
          <CartProductDetails
            isExpanded={this.state.isDetailsExpanded}
            {...this.props.details}
          />
        )}
      </tbody>
    );
  }
}

export default CartProduct;
