import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingTimeItem = props => {
  return (
    <div className="appointment-booking-time-item">
      <span
        className="appointment-booking-time-item__inner"
        onClick={() => {
          props.onClickTime(props.time);
        }}
      >
        {props.time}
      </span>
    </div>
  );
};

AppointmentBookingTimeItem.propTypes = {
  time: PropTypes.string.isRequired,
  onClickTime: PropTypes.func
};

AppointmentBookingTimeItem.propTypesMeta = 'exclude';

export default AppointmentBookingTimeItem;
