import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const AppointmentBookingResultTime = props => {
  const dayNumber = new Date(props.date).getDate();

  return (
    <div
      className="appointment-booking-result-time"
      onClick={props.onClickHandle}
    >
      <div className="appointment-booking-result-time__time-box">
        <Icon name="icon-clock" />
      </div>
      <strong className="appointment-booking-result-time__title">
        {`${props.weekday} ${dayNumber} `}
        <span className="appointment-booking-result-time__text-lowercase">
          {props.month}
        </span>
      </strong>
      <span className="appointment-booking-result-time__description">
        &nbsp;· {props.preposition} {props.time}
      </span>
      <div className="appointment-booking-result-time__edit-box">
        <Icon name="icon-edit" />
      </div>
    </div>
  );
};

AppointmentBookingResultTime.propTypes = {
  onClickHandle: PropTypes.func,
  date: PropTypes.string,
  weekday: PropTypes.string,
  month: PropTypes.string,
  time: PropTypes.string,
  preposition: PropTypes.string
};

AppointmentBookingResultTime.propTypesMeta = {
  date: 'exclude',
  weekday: 'exclude',
  month: 'exclude',
  time: 'exclude'
};

export default AppointmentBookingResultTime;
