import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import Icon from 'components/icon';

const AppointmentBookingSeeNearest = props => {
  return (
    <div className="appointment-booking-see-nearest">
      <Button
        className="appointment-booking-see-nearest__button"
        onClick={props.onClickSeeNearestShops}
      >
        <span className="appointment-booking-see-nearest__icon-holder">
          <Icon name="pin" />
        </span>
        <span className="appointment-booking-see-nearest__text">
          {props.text}
        </span>
      </Button>
    </div>
  );
};

AppointmentBookingSeeNearest.propTypes = {
  onClickSeeNearestShops: PropTypes.func,
  text: PropTypes.string
};

export default AppointmentBookingSeeNearest;
