import React from 'react';
import PropTypes from 'prop-types';
import Text from '../text';
import MagazineListItem from 'components/magazine-list-item/magazine-list-item';
import ImageLink from 'components/image-link-list/image-link';

const MagazineRelatedPages = ({ title, items }) => {
  return items && items.length > 0 ? (
    <div className="magazine-related-pages">
      {title && (
        <div className="magazine-related-pages__title">
          <Text.h2 theme={Text.themes.uppercaseBig}>{title}</Text.h2>
        </div>
      )}
      <ul className="magazine-related-pages__list">
        {items.map(listItem => {
          return (
            <div
              className="magazine-related-pages__list-item"
              key={listItem.id}
            >
              <ImageLink
                url={listItem.link.url}
                ingress={listItem.leadText}
                text={listItem.titleText}
                image={listItem.image}
                ctaText={listItem.link.text}
                category={listItem.category}
              />
            </div>
          );
        })}
      </ul>
    </div>
  ) : null;
};

MagazineRelatedPages.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(MagazineListItem.propTypes))
};

MagazineRelatedPages.defaultProps = {};

export default MagazineRelatedPages;
