import React from 'react';
import PropTypes from 'prop-types';
import PhotoBlockItem from './photo-block-item';

const formatsOneImage = {
  squareBig: 'square-big',
  landscape: 'landscape'
};

const formatsTwoImages = {
  square: 'square',
  tall: 'tall'
};

const PhotoBlock = ({ photos, formatOneImage, formatTwoImages }) => {
  const itemFormat = photos.length === 2 ? formatTwoImages : formatOneImage;
  return photos && photos.length > 0 ? (
    <div className="photo-block">
      <div className="photo-block__photos">
        {photos.map((photo, index) => (
          <PhotoBlockItem
            {...photo}
            key={index}
            format={itemFormat}
            className={photos.length === 2 ? 'photo-block__item--half' : null}
          />
        ))}
      </div>
    </div>
  ) : null;
};

PhotoBlock.propTypes = {
  formatOneImage: PropTypes.oneOf(Object.values(formatsOneImage)).isRequired,
  formatTwoImages: PropTypes.oneOf(Object.values(formatsTwoImages)).isRequired,
  photos: PropTypes.arrayOf(PropTypes.exact(PhotoBlockItem.propTypes))
};

PhotoBlock.defaultProps = {};

export default PhotoBlock;
