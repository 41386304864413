import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';

// NOTE: Any valid DOM prop is a valid prop for Button
const Button = React.forwardRef(
  ({ children, className, text, themes, type, ...restProps }, ref) => {
    return (
      <Clickable
        {...restProps}
        className={className}
        element="button"
        ref={ref}
        themes={themes}
        type={type}
      >
        {text}
        {children}
      </Clickable>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
  themes: PropTypes.any,
  type: PropTypes.string
};

Button.propTypesMeta = {
  className: 'exclude',
  themes: 'exclude',
  type: 'exclude'
};

Button.defaultProps = {
  type: 'button',
  themes: [Clickable.themes.buttonCyan]
};

// NOTE: Show 'Button' in error messages instead of 'forwardRef'
Button.displayName = 'Button';

Button.themes = Clickable.themes;

export default Button;
