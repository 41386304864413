import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Text from '../text';
import RichText from '../rich-text';
import BookingLink from '../booking-link';
import Button from '../button';
import Image from '../image';
import Collapse from 'react-tiny-collapse';

const Optician = ({
  image,
  title,
  subtitle,
  bodyText,
  readMoreLabel,
  textThresholdInNumberOfChars,
  bookingLink
}) => {
  const [textIsExpanded, setTextIsExpanded] = useState(true);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    if (!bodyText) return undefined;

    const { text } = bodyText;

    if (text.length > textThresholdInNumberOfChars) {
      setShowReadMore(true);
      setTextIsExpanded(false);
    }
  }, []);

  return (
    <div className="optician">
      <div className="optician-inner">
        {image && (
          <div className="optician-image">
            <Image {...image} />
          </div>
        )}
        <div className="optician-title">
          <Text.h3 theme={Text.themes.bold} className="optician-title-text">
            {title}
          </Text.h3>
        </div>
        <div className="optician-subtitle">
          <Text.p>{subtitle}</Text.p>
        </div>
        {bodyText && (
          <div className="optician-body-text">
            <Collapse isOpen={textIsExpanded}>
              <RichText {...bodyText} hasBottomMargin={false} />
            </Collapse>
          </div>
        )}
        {showReadMore && (
          <div className="optician-button">
            <Button
              onClick={() => setTextIsExpanded(!textIsExpanded)}
              text={readMoreLabel}
              themes={[Button.themes.link]}
            />
          </div>
        )}
        {bookingLink && (
          <div className="optician-link">
            <BookingLink
              {...bookingLink}
              themes={[BookingLink.themes.buttonCyan]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

Optician.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  bodyText: PropTypes.exact(RichText.propTypes),
  readMoreLabel: PropTypes.string,
  textThresholdInNumberOfChars: PropTypes.number,
  bookingLink: PropTypes.exact(BookingLink.propTypes)
};

Optician.defaultProps = {
  textThresholdInNumberOfChars: 200
};

Optician.propTypesMeta = {
  textThresholdInNumberOfChars: 'exclude'
};

export default Optician;
