import React from 'react';
import PropTypes from 'prop-types';

const HiddenInput = ({ name, value }) => (
  <input type="hidden" name={name} value={value} />
);

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default HiddenInput;
