import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import RichText from 'components/rich-text';
import LinkToMainSite from 'components/link-to-main-site';
import ContentContainer from 'components/content-container';

const MagazineFooter = ({
  linkToMainSite,
  logo,
  textHtmlcolumn1,
  textHtmlcolumn2,
  textHtmlcolumn3
}) => {
  return (
    <div className="magazine-footer">
      <ContentContainer className="magazine-footer__inner">
        <div className="magazine-footer__column magazine-footer__column--1">
          {logo && <Logo {...logo} className="magazine-footer__logo" />}

          {textHtmlcolumn1 && (
            <RichText
              text={textHtmlcolumn1}
              hasBottomMargin={false}
              isCentered={false}
              className="rich-text--in-magazine-footer"
            />
          )}
        </div>
        <div className="magazine-footer__column magazine-footer__column--2">
          {textHtmlcolumn2 && (
            <RichText
              text={textHtmlcolumn2}
              hasBottomMargin={false}
              isCentered={false}
              className="rich-text--in-magazine-footer"
            />
          )}
        </div>
        <div className="magazine-footer__column magazine-footer__column--3">
          {textHtmlcolumn3 && (
            <RichText
              text={textHtmlcolumn3}
              hasBottomMargin={false}
              isCentered={false}
              className="rich-text--in-magazine-footer"
            />
          )}
        </div>

        {linkToMainSite && (
          <LinkToMainSite
            {...linkToMainSite}
            className="magazine-footer__back-link link-to-main-site--in-footer"
          />
        )}
      </ContentContainer>
    </div>
  );
};

MagazineFooter.propTypes = {
  linkToMainSite: PropTypes.exact(LinkToMainSite.propTypes).isRequired,
  logo: PropTypes.exact(Logo.propTypes).isRequired,
  textHtmlcolumn1: PropTypes.string,
  textHtmlcolumn2: PropTypes.string,
  textHtmlcolumn3: PropTypes.string
};

export default MagazineFooter;
