import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Icon from '../icon';

const ProductSellingPoints = ({ className, items }) => (
  <ul className={cn('product-selling-points', className)}>
    {items.map(item => (
      <li key={item}>
        <Icon name="checkmark" />
        <span>{item}</span>
      </li>
    ))}
  </ul>
);

ProductSellingPoints.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired
};

ProductSellingPoints.propTypesMeta = {
  className: 'exclude'
};

ProductSellingPoints.defaultProps = {
  items: []
};

export default ProductSellingPoints;
