import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const AppointmentBookingDateItem = props => {
  useEffect(() => {
    if (props.isDateActive) props.onClickDate(props.date);
  }, []);

  return (
    <div
      className={cn('appointment-booking-date-item', {
        'is-active': props.isDateActive,
        'first-day': props.isFirstDayOfMonth,
        'is-disabled': props.isDateDisabled
      })}
      onClick={() => {
        props.onClickDate(props.date);
      }}
    >
      <span className="appointment-booking-date-item__text">
        {props.weekday}
      </span>
      <span className="appointment-booking-date-item__date">
        {props.dayNumber}
      </span>
      <span className="appointment-booking-date-item__month">
        {props.month}
      </span>
    </div>
  );
};

AppointmentBookingDateItem.propTypes = {
  date: PropTypes.string.isRequired,
  dayNumber: PropTypes.number.isRequired,
  weekday: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  isDateActive: PropTypes.bool.isRequired,
  isFirstDayOfMonth: PropTypes.bool.isRequired,
  isDateDisabled: PropTypes.bool.isRequired,
  onClickDate: PropTypes.func
};

AppointmentBookingDateItem.propTypesMeta = 'exclude';

export default AppointmentBookingDateItem;
