import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

const AppointmentBookingFooter = props => {
  return (
    <div className="appointment-booking-footer">
      <span className="appointment-booking-footer__text">{props.text}</span>
      <Link
        className="appointment-booking-footer__link"
        text={props.linkText}
        url={props.linkUrl}
      />
    </div>
  );
};

AppointmentBookingFooter.propTypes = {
  text: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string
};

export default AppointmentBookingFooter;
