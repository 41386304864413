/* eslint-disable react/prop-types */
import React from 'react';
import Link from 'components/link';
import PropTypes from 'prop-types';

const MagazineHeaderCTA = ({ url, text, onClick }) => {
  return (
    <div className="magazine-header__cta-links-item">
      <a href={url} className="magazine-header__cta-link" onClick={onClick}>
        {text}
      </a>
    </div>
  );
};

MagazineHeaderCTA.propTypes = Object.assign({}, Link.propTypes, {
  onClick: PropTypes.func
});

MagazineHeaderCTA.propTypesMeta = 'exclude';

export default MagazineHeaderCTA;
