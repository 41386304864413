import React from 'react';
import PropTypes from 'prop-types';

import Text from '../text';
import Image from '../image';
import RichText from '../rich-text';

const StoreBody = ({ leadText, bodyText, image }) => (
  <div className="store-body">
    <div className="store-body-lead">
      <Text.p theme={Text.themes.bigAndBold}>{leadText}</Text.p>
    </div>
    <div className="store-body-text">
      <RichText {...bodyText} />
    </div>
    <div className="store-body-image">
      <Image {...image} />
    </div>
  </div>
);

StoreBody.propTypes = {
  leadText: PropTypes.string,
  bodyText: PropTypes.exact(RichText.propTypes),
  image: PropTypes.exact(Image.propTypes)
};

export default StoreBody;
