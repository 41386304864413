import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import WithModalProvider from '../../hoc/with-modal-provider';

import AppointmentBookingTrigger from 'components/appointment-booking-trigger';
import AppointmentBookingModal from 'components/appointment-booking-modal/appointment-booking-modal';

const BookingLink = props => {
  return (
    <Fragment>
      <WithModalProvider>
        <AppointmentBookingTrigger
          tabIndex={props.tabIndex}
          className={props.className}
          url={props.url}
          text={props.text}
          themes={props.themes}
          isStorePreselected={props.preselectedShopId ? true : false}
          isPreselectedAppointmentId={
            props.preselectedAppointmentId ? true : false
          }
          isPreselectedOpticianId={props.preselectedOpticianId ? true : false}
        />
        <AppointmentBookingModal
          {...props.appointmentBookingModal}
          preselectedShopId={props.preselectedShopId}
          preselectedAppointmentId={props.preselectedAppointmentId}
          preselectedOpticianId={props.preselectedOpticianId}
        />
      </WithModalProvider>
    </Fragment>
  );
};

BookingLink.propTypes = {
  preselectedShopId: PropTypes.string,
  preselectedAppointmentId: PropTypes.string,
  preselectedOpticianId: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  appointmentBookingModal: PropTypes.exact(AppointmentBookingModal.propTypes),
  url: PropTypes.string,
  text: PropTypes.string,
  themes: PropTypes.any
};

BookingLink.propTypesMeta = {
  className: 'exclude',
  themes: 'exclude',
  tabIndex: 'exclude'
};

BookingLink.themes = AppointmentBookingTrigger.themes;

export default BookingLink;
