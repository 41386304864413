import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Text from 'components/text';

const themes = {
  big: 'theme-big',
  lineThrough: 'theme-line-through',
  vertical: 'theme-vertical'
};

const Price = ({
  children,
  className,
  discountedPrice,
  element,
  originalPrice,
  priceLabel,
  theme
}) => {
  const Element = element;
  const smallPrice = discountedPrice && (
    <Text.div className={cn('price-small', theme)} theme={Text.themes.small}>
      {originalPrice}
    </Text.div>
  );
  const bigPrice = (
    <div className={cn('price-big', theme)}>
      {priceLabel && <span className="price-label">{priceLabel}</span>}
      {discountedPrice || originalPrice}
    </div>
  );

  return children ? (
    children(smallPrice, bigPrice)
  ) : (
    <Element className={cn('price', className, theme)}>
      {bigPrice}
      {smallPrice}
    </Element>
  );
};

Price.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  discountedPrice: PropTypes.string,
  element: PropTypes.string,
  originalPrice: PropTypes.string,
  priceLabel: PropTypes.string,
  theme: propTypeTheme(themes)
};

Price.propTypesMeta = {
  className: 'exclude',
  element: 'exclude',
  theme: 'exclude'
};

Price.defaultProps = {
  element: 'div'
};

Price.themes = themes;

export default Price;
