import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  big: 'theme-big',
  checkmarks: 'theme-checkmarks',
  compactWhite: 'theme-compact-white',
  fancyBullets: 'theme-fancy-bullets',
  withinCollapsable: 'theme-within-collapsable'
};

const RichText = ({
  className,
  element,
  text,
  theme,
  isCentered,
  hasBottomMargin
}) =>
  React.createElement(element, {
    className: cn(
      'rich-text',
      { 'text-centered': isCentered, 'bottom-margin': hasBottomMargin },
      className,
      theme
    ),
    dangerouslySetInnerHTML: { __html: text }
  });

RichText.propTypes = {
  className: PropTypes.string,
  element: PropTypes.string,
  text: PropTypes.string,
  isCentered: PropTypes.bool,
  hasBottomMargin: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes))
};

RichText.propTypesMeta = {
  className: 'exclude',
  element: 'exclude',
  theme: 'exclude',
  isCentered: 'exclude',
  hasBottomMargin: 'exclude'
};

RichText.defaultProps = {
  element: 'div',
  isCentered: true,
  hasBottomMargin: true
};

RichText.themes = themes;

export default RichText;
