import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/form';
import TextInput from 'components/text-input';
import PageSpinner from 'components/page-spinner';
import ValidationErrorTexts from 'components/validation-error-texts';
import Button from 'components/button';
import HiddenInput from 'components/hidden-input';

const ReserveInStoreForm = ({
  inputName,
  inputPhone,
  form,
  validationErrors,
  posId,
  shopId,
  onResponse = () => {}
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const onSubmit = () => setLoading(true);

  const onResponseComplete = res => {
    setLoading(false);
    onResponse(res);
  };

  return (
    <Form
      onSubmit={onSubmit}
      showSubmitButton={false}
      onResponse={onResponseComplete}
      {...form}
      validationErrors={validationErrors}
      className="reserve-in-store-form"
    >
      <PageSpinner shouldContainInParent={true} isLoading={isLoading} />
      <div className="">
        <HiddenInput name="shopId" value={shopId} />
        <HiddenInput name="posId" value={posId} />
        <TextInput
          name="username"
          className="reserve-in-store-form__input"
          type="text"
          validations={['isRequired', 'isUserName']}
          autocomplete="name"
          {...inputName}
          theme={[TextInput.themes.bordered]}
        />
        <TextInput
          className="reserve-in-store-form__input"
          name="phonenumber"
          type="tel"
          validations={['isRequired', 'isPhoneNumber']}
          autocomplete="tel"
          {...inputPhone}
          theme={[TextInput.themes.bordered]}
        />
        <Button
          type="submit"
          className="reserve-in-store-form__button"
          themes={[Button.themes.buttonCyanSmall]}
        >
          {form.submitLabel}
        </Button>
      </div>
    </Form>
  );
};

ReserveInStoreForm.propTypes = {
  inputName: PropTypes.exact(TextInput.propTypes),
  inputPhone: PropTypes.exact(TextInput.propTypes),
  form: PropTypes.exact(Form.propTypes),
  onResponse: PropTypes.func,
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes),
  posId: PropTypes.string,
  shopId: PropTypes.string
};

ReserveInStoreForm.propTypesMeta = {
  posId: 'exclude',
  shopId: 'exclude'
};

export default ReserveInStoreForm;
