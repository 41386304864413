import React from 'react';
import PropTypes from 'prop-types';

import CollapseList from 'components/collapse-list';
import Icon from 'components/icon';
import Image from 'components/image';
import Checkbox from 'components/checkbox';
import Text from 'components/text';

const ShapeSelect = ({
  collapseLabel,
  expandLabel,
  idPrefix,
  maxLength,
  options
}) => {
  return (
    <div className="shape-select">
      <CollapseList {...{ collapseLabel, expandLabel, maxLength }}>
        {options.map(({ input, image, label }) => (
          <Checkbox
            idPrefix={idPrefix}
            key={input.value}
            theme={Checkbox.themes.hiddenCheckbox}
            {...input}
          >
            <span className="shape-select-item">
              <Image {...image} />
              <Text.span
                className="shape-select-item-text"
                theme={Text.themes.extraSmall}
              >
                {label}
              </Text.span>
              <div className="shape-select-item-checkmark">
                <Icon name="checkmark" />
              </div>
            </span>
          </Checkbox>
        ))}
      </CollapseList>
    </div>
  );
};

ShapeSelect.propTypes = {
  collapseLabel: PropTypes.string,
  expandLabel: PropTypes.string,
  idPrefix: PropTypes.string,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      input: PropTypes.exact(Checkbox.propTypes),
      image: PropTypes.exact(Image.propTypes),
      label: PropTypes.string
    })
  )
};

ShapeSelect.propTypesMeta = {
  idPrefix: 'exclude'
};

ShapeSelect.defaultProps = {
  options: []
};

export default ShapeSelect;
