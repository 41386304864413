/* eslint-disable no-unused-vars */
import React, { useContext, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from 'components/content-container';
import Icon from 'components/icon';
import Link from 'components/link';
import Text from 'components/text';
import ModalContext from '../../context/modal';
import SkipToMainButton from 'components/skip-to-main-button';
import SiteInfoBanner from 'components/site-info-banner';
import MobileStickyHeader from './mobile-sticky-header';

import logo from '../../assets/logo.png';
import { mainContent } from '../../js/local-ids.json';

const ThemedLink = props => <Link themes={[Link.themes.linkGray]} {...props} />;

/* eslint-disable react/no-multi-comp */
const HeaderInner = ({
  isDesktop,
  numberOfProducts,
  loginId,
  searchId,
  cartId,
  cartLink,
  supportLink,
  magazineLink,
  myPageLink,
  linkToHome,
  phoneLink,
  searchPageLink,
  mobileTopBarLinks,
  isLoggedIn,
  hideMenuText,
  showMenuText,
  skipToMainText,
  siteInfoBanner,
  showSiteInfoBanner,
  bookAppointmentLink
}) => {
  const scrollDestination = useRef();
  const modalContext = useContext(ModalContext);

  const toggleMobileMenu = () => {
    if (scrollDestination.current) scrollDestination.current.scrollIntoView();

    modalContext.toggleMobileMenu();
  };

  const toggleLogin = e => {
    e.preventDefault();
    modalContext.toggleLoginModal();
  };

  const toggleCart = e => {
    e.preventDefault();
    modalContext.toggleCartModal();
  };

  const toggleSearch = e => {
    e.preventDefault();
    modalContext.toggleSearchModal();
  };

  const toggleAppointmentBookingModal = e => {
    e.preventDefault();
    modalContext.toggleAppointmentBookingModal();
  };

  return (
    <Fragment>
      {skipToMainText && (
        // eslint-disable-next-line no-warning-comments
        <SkipToMainButton text={skipToMainText} targetId={mainContent} /> //TODO: get id of next element, blockId, etc
      )}
      {showSiteInfoBanner && <SiteInfoBanner {...siteInfoBanner} />}
      <div className="header-mobile-top-bar" ref={scrollDestination}>
        <ContentContainer className="header-mobile-top-bar-inner">
          <div className="header-mobile-top-bar-links">
            {/*{mobileTopBarLinks.map(link => (
                <Link
                  key={link.url + link.text}
                  themes={[Link.themes.linkWhite]}
                  {...link}
                />
              ))}*/}
            {myPageLink && (
              <Link
                aria-controls={loginId}
                aria-expanded={modalContext.isLoginModalOpen}
                onClick={isLoggedIn ? null : toggleLogin}
                themes={[Link.themes.linkWhite]}
                {...myPageLink}
              />
            )}

            {supportLink && (
              <Link themes={[Link.themes.linkWhite]} {...supportLink} />
            )}
            {/*{magazineLink && (
              <Link themes={[Link.themes.linkWhite]} {...magazineLink} />
            )}*/}
          </div>
          {bookAppointmentLink && (
            <Link
              isNoFollow={true}
              className="header-mobile-top-bar__book-appointment"
              themes={[Link.themes.buttonCyan]}
              onClick={toggleAppointmentBookingModal}
              {...bookAppointmentLink}
            />
          )}
          {searchPageLink && (
            <Link
              aria-controls={searchId}
              aria-expanded={modalContext.isSearchModalOpen}
              hiddenText={true}
              onClick={toggleSearch}
              themes={[Link.themes.linkWhite]}
              {...searchPageLink}
            >
              <Icon name="magnifier" />
            </Link>
          )}
        </ContentContainer>
      </div>
      <MobileStickyHeader>
        <Text element="nav" className="header-bar" theme={Text.themes.small}>
          <ContentContainer className="header-bar-content">
            <button
              id="menu-toggle"
              aria-label={
                modalContext.isMobileMenuOpen ? hideMenuText : showMenuText
              }
              className={cn('header-mobile-menu-toggle', {
                'is-active': modalContext.isMobileMenuOpen
              })}
              onClick={toggleMobileMenu}
            >
              <i className="header-hamburger">
                <i className="header-hamburger-line top" />
                <i className="header-hamburger-line mid" />
                <i className="header-hamburger-line bot" />
              </i>
            </button>
            <ul className="header-nav-left">
              {phoneLink && (
                <li>
                  <ThemedLink url={phoneLink.url}>
                    <Icon className="header-phone-icon" name="phone" />
                    {phoneLink.text}
                  </ThemedLink>
                </li>
              )}
              {supportLink && (
                <li>
                  <ThemedLink {...supportLink} />
                </li>
              )}
              {magazineLink && (
                <li>
                  <ThemedLink {...magazineLink} />
                </li>
              )}
            </ul>
            <Link className="header-logo" hiddenText={true} {...linkToHome}>
              <img src={logo} alt="Interoptik logo" />
            </Link>

            <ul className="header-nav-right">
              {isDesktop && myPageLink && (
                <li>
                  <ThemedLink
                    aria-controls={loginId}
                    aria-expanded={modalContext.isLoginModalOpen}
                    className="header-login-link"
                    onClick={isLoggedIn ? null : toggleLogin}
                    {...myPageLink}
                  />
                </li>
              )}
              {searchPageLink && (
                <li className="header-nav-search-button">
                  <Link
                    aria-controls={searchId}
                    aria-expanded={modalContext.isSearchModalOpen}
                    hiddenText={true}
                    onClick={toggleSearch}
                    themes={[Link.themes.circle]}
                    {...searchPageLink}
                  >
                    <Icon name="magnifier" />
                  </Link>
                </li>
              )}
              {cartLink && (
                <li className="small-margin">
                  <Link
                    aria-controls={cartId}
                    aria-expanded={modalContext.isCartModalOpen}
                    className={cn('header-cart-button', {
                      'has-products': numberOfProducts
                    })}
                    hiddenText={true}
                    onClick={toggleCart}
                    themes={
                      numberOfProducts > 0
                        ? [Link.themes.circleDark]
                        : [Link.themes.circle]
                    }
                    {...cartLink}
                  >
                    <React.Fragment>
                      <Icon name="basket" />
                      {numberOfProducts > 0 && (
                        <span className="header-cart-count">
                          {numberOfProducts}
                        </span>
                      )}
                    </React.Fragment>
                  </Link>
                </li>
              )}
            </ul>
          </ContentContainer>
        </Text>
      </MobileStickyHeader>
    </Fragment>
  );
};

HeaderInner.propTypes = {
  isDesktop: PropTypes.bool,
  numberOfProducts: PropTypes.number,
  loginId: PropTypes.string,
  searchId: PropTypes.string,
  cartId: PropTypes.string,
  cartLink: PropTypes.exact(Link.propTypes),
  supportLink: PropTypes.exact(Link.propTypes),
  magazineLink: PropTypes.exact(Link.propTypes),
  myPageLink: PropTypes.exact(Link.propTypes),
  linkToHome: PropTypes.exact(Link.propTypes),
  phoneLink: PropTypes.exact(Link.propTypes),
  searchPageLink: PropTypes.exact(Link.propTypes),
  mobileTopBarLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  isLoggedIn: PropTypes.bool,
  hideMenuText: PropTypes.string,
  showMenuText: PropTypes.string,
  skipToMainText: PropTypes.string,
  showSiteInfoBanner: PropTypes.bool,
  siteInfoBanner: PropTypes.shape(SiteInfoBanner.propTypes),
  bookAppointmentLink: PropTypes.exact(Link.propTypes)
};

HeaderInner.propTypesMeta = 'exclude';

HeaderInner.defaultProps = {
  mobileTopBarLinks: []
};

export default HeaderInner;
