import React, { useContext } from 'react';
import ModalContext from '../../context/modal';
import Link from 'components/link';
import PropTypes from 'prop-types';
import AppointmentBookingModal from 'components/appointment-booking-modal/appointment-booking-modal';

import MagazineHeaderCTA from './magazine-header-cta';

const MagazineHeaderAppointment = ({
  bookAppointmentLink,
  appointmentBookingModalData
}) => {
  const modalContext = useContext(ModalContext);

  const toggleModal = e => {
    e.preventDefault();
    modalContext.toggleAppointmentBookingModal();
  };

  return (
    <>
      <MagazineHeaderCTA {...bookAppointmentLink} onClick={toggleModal} />
      <AppointmentBookingModal {...appointmentBookingModalData} />
    </>
  );
};

MagazineHeaderAppointment.propTypes = {
  bookAppointmentLink: PropTypes.exact(Link.propTypes),
  appointmentBookingModalData: PropTypes.exact(
    AppointmentBookingModal.propTypes
  )
};

MagazineHeaderAppointment.propTypesMeta = 'exclude';

export default MagazineHeaderAppointment;
