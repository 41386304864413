import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'components/carousel';
import AppointmentBookingDateItem from 'components/appointment-booking-modal/appointment-booking-date-item';

const AppointmentBookingDateList = props => {
  const dayNumber = props.dates.map(item => {
    let dN = new Date(item.date).getDate();
    return dN;
  });

  const isFirstDayOfMonth = props.dates.map((item, index) => {
    if (index === 0 || dayNumber[index] === 1) {
      return true;
    } else {
      return false;
    }
  });

  const isDateDisabled = props.dates.map(item => {
    return item.timeSlots.length === 0;
  });

  const activeDateIndex = props.dates.findIndex(
    item => item.date === props.activeDate
  );

  return (
    <div className="appointment-booking-date-list">
      <Carousel
        previousItemText={props.carouselPreviousItemText}
        nextItemText={props.carouselNextItemText}
        shouldShowDots={false}
        hasQuickAnimations={true}
        startIndex={activeDateIndex}
        numberOfSlidesToJump={3}
        numberOfSlidesToShow={6}
        shouldLoop={false}
        shouldShowNavText={true}
        title={props.carouselTitle}
      >
        {props.dates.map((item, index) => (
          <AppointmentBookingDateItem
            key={item.date}
            date={item.date}
            dayNumber={dayNumber[index]}
            weekday={index === 0 ? props.currentWeekDay : item.weekdayShort}
            month={item.month}
            isDateActive={item.date === props.activeDate}
            isFirstDayOfMonth={isFirstDayOfMonth[index]}
            isDateDisabled={isDateDisabled[index]}
            onClickDate={props.onClickDate}
          />
        ))}
      </Carousel>
    </div>
  );
};

AppointmentBookingDateList.propTypes = {
  onClickDate: PropTypes.func,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      weekday: PropTypes.string.isRequired,
      weekdayShort: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      timeSlots: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ),
  activeDate: PropTypes.string,
  currentWeekDay: PropTypes.string.isRequired,
  carouselTitle: PropTypes.string.isRequired,
  carouselPreviousItemText: PropTypes.string.isRequired,
  carouselNextItemText: PropTypes.string.isRequired
};

AppointmentBookingDateList.propTypesMeta = {
  activeDate: 'exclude'
};

export default AppointmentBookingDateList;
