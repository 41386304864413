import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'components/grid';
import TextInput from 'components/text-input';
import Form from 'components/form';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import PageSpinner from 'components/page-spinner';

const ForgotPasswordPage = ({ form, email, layout, section }) => {
  return (
    <MyPageLayout {...layout}>
      <Grid theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <MyPageSection headingLevel={1} {...section}>
            <Form showSubmitButton={true} {...form}>
              {({ isLoading }) => (
                <React.Fragment>
                  <PageSpinner
                    shouldContainInParent={true}
                    isLoading={isLoading}
                  />
                  <TextInput
                    validations={['isRequired', 'isEmail']}
                    type="email"
                    autocomplete="email"
                    isRequired={true}
                    {...email}
                  />
                </React.Fragment>
              )}
            </Form>
          </MyPageSection>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

ForgotPasswordPage.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  email: PropTypes.exact(TextInput.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes)
};

export default ForgotPasswordPage;
