import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';

const ProductLogo = ({ alt, fallbackText, src }) => (
  <div className="product-logo">
    {src ? (
      <Image src={src} alt={alt} responsive={false} />
    ) : (
      <p>{fallbackText}</p>
    )}
  </div>
);

ProductLogo.propTypes = {
  alt: PropTypes.string,
  fallbackText: PropTypes.string,
  src: PropTypes.string
};

export default ProductLogo;
