import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

const LoginModal = ({ links, registerLink, registerLinkLabel, title }) => (
  <div className="login-modal">
    <h2>{title}</h2>
    {links.map(link => (
      <div className="login-modal-link-wrapper" key={link.label}>
        <p>{link.label}</p>
        <Link className="login-modal-button" {...link.link} />
      </div>
    ))}
    <div className="login-modal-register">
      <p>{registerLinkLabel}</p>
      <Link className="login-modal-register-link" {...registerLink} />
    </div>
  </div>
);

LoginModal.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  registerLink: PropTypes.exact(Link.propTypes),
  registerLinkLabel: PropTypes.string,
  title: PropTypes.string
};

LoginModal.defaultProps = {
  links: []
};

export default LoginModal;
