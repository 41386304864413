import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';
import Transition from 'react-tiny-transition';

import ContentContainer from 'components/content-container';
import Icon from 'components/icon';
import Link from 'components/link';
import MouseoverLink from 'components/mouseover-link';
import Text from 'components/text';
import SubMenu from './sub-menu';
import ModalContext from '../../context/modal';

const MainNav = ({
  bookAppointmentLink,
  findStoreLink,
  hideMobileMenu,
  isDesktop,
  isExpanded = false,
  items
}) => {
  const raf = React.useRef();
  const [activeSubMenu, setActiveSubMenu] = React.useState('');
  const [shouldAnimateSubMenu, setShouldAnimateSubMenu] = React.useState(false);

  const hideSubMenus = () => {
    setShouldAnimateSubMenu(true);
    raf.current = requestAnimationFrame(() => setActiveSubMenu(''));
  };

  const openSubMenu = menuName => {
    const hasActiveSubMenu = !!activeSubMenu;

    setShouldAnimateSubMenu(!hasActiveSubMenu);

    if (menuName === activeSubMenu) {
      hideSubMenus();
    } else {
      raf.current = requestAnimationFrame(() => setActiveSubMenu(menuName));
    }
  };

  // Cleanup on unmount
  React.useEffect(() => () => cancelAnimationFrame(raf.current), []);

  const shouldAnimate = shouldAnimateSubMenu || !isDesktop;
  const WrapperElement = isDesktop ? 'div' : Transition;
  const hasActiveSubMenu = !!activeSubMenu;
  const modalContext = useContext(ModalContext);
  const toggleAppointmentBookingModal = e => {
    e.preventDefault();
    modalContext.toggleAppointmentBookingModal();
  };

  return (
    <React.Fragment>
      <WrapperElement>
        <div
          className={cn('main-nav', {
            'is-expanded': !isDesktop && isExpanded
          })}
        >
          <ContentContainer className="main-nav-content">
            <ul className="main-nav-items">
              {items.map(({ link, content }) => {
                const isActive = activeSubMenu === link.url;

                return (
                  <li key={link.url}>
                    <MouseoverLink
                      data-test-main-nav-link={link.text}
                      className={cn('main-nav-link', {
                        'is-active': isActive
                      })}
                      onMousehold={() => openSubMenu(link.url)}
                      {...link}
                    />

                    <div
                      className={cn('main-nav-button', {
                        'is-active': isActive
                      })}
                    >
                      <span className="main-nav-button-inner">
                        <a
                          href={link.url}
                          className="main-nav-button-inner-link"
                        >
                          <Text.span theme={Text.themes.uppercaseSmall}>
                            {link.text}
                          </Text.span>
                        </a>
                        <button
                          onClick={() => openSubMenu(link.url)}
                          className="main-nav-button-inner-button"
                        >
                          <Icon name="chevron-down" />
                        </button>
                      </span>
                    </div>
                    {shouldAnimate ? (
                      <Collapse isOpen={isActive} className="main-nav-submenu">
                        <SubMenu
                          hide={hideSubMenus}
                          {...content}
                          isActive={isActive}
                        />
                      </Collapse>
                    ) : (
                      <div
                        className={cn('main-nav-submenu', {
                          'main-nav-submenu--active': isActive
                        })}
                      >
                        <SubMenu
                          hide={hideSubMenus}
                          isActive={isActive}
                          {...content}
                        />
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>

            <div className="main-nav-buttons">
              {findStoreLink && (
                <Link
                  className="main-nav-find-store"
                  iconBefore="pin"
                  themes={[Link.themes.buttonWhite]}
                  {...findStoreLink}
                />
              )}

              {bookAppointmentLink && (
                <Link
                  isNoFollow={true}
                  className="main-nav-book-appointment"
                  themes={[Link.themes.buttonCyan]}
                  onClick={toggleAppointmentBookingModal}
                  {...bookAppointmentLink}
                />
              )}
            </div>
          </ContentContainer>
        </div>
      </WrapperElement>

      <Transition duration={500}>
        {isDesktop && hasActiveSubMenu && (
          <div className="main-nav-overlay" onClick={hideSubMenus} />
        )}
      </Transition>

      <Transition duration={500}>
        {!isDesktop && isExpanded && (
          <div className="main-nav-overlay" onClick={hideMobileMenu} />
        )}
      </Transition>
    </React.Fragment>
  );
};

MainNav.propTypes = {
  bookAppointmentLink: PropTypes.exact(Link.propTypes),
  findStoreLink: PropTypes.exact(Link.propTypes),
  hideMobileMenu: PropTypes.func,
  isDesktop: PropTypes.bool,
  isExpanded: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact({
        url: PropTypes.string,
        text: PropTypes.string
      }),
      content: PropTypes.exact(SubMenu.propTypes)
    })
  )
};

MainNav.propTypesMeta = {
  isDesktop: 'exclude',
  isExpanded: 'exclude'
};

MainNav.defaultProps = {
  items: []
};

export default MainNav;
