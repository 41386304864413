import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Text from 'components/text';
import MyStore from 'components/my-store';

const MyStoreBanner = ({ title, lead, myStore }) => (
  <div className="my-store-banner">
    {title && <Text.h2 theme={Text.themes.uppercaseBig}>{title}</Text.h2>}
    {lead && <Text.p>{lead}</Text.p>}
    <MyStore {...myStore} />
  </div>
);

MyStoreBanner.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string,
  lead: PropTypes.string,
  myStore: PropTypes.exact(MyStore.propTypes)
};

export default MyStoreBanner;
