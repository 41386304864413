import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RichText from 'components/rich-text';

const bgColors = {
  white: 'white',
  mint: 'mint',
  orange: 'orange',
  blue: 'blue',
  purple: 'purple'
};

const TextWithBgColorBlock = ({ textHtml, bgColor = bgColors.white }) => {
  return (
    <div
      className={cn('text-with-bg-color-block', {
        [`text-with-bg-color-block--${bgColor}`]: bgColor
      })}
    >
      <RichText
        text={textHtml}
        isCentered={true}
        theme={RichText.themes.withinCollapsable}
        className="text-with-bg-color-block__content"
      />
    </div>
  );
};

TextWithBgColorBlock.propTypes = {
  bgColor: PropTypes.oneOf(Object.values(bgColors)),
  textHtml: PropTypes.string
};

export default TextWithBgColorBlock;
