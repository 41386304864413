/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';

import cn from 'classnames';

import messenger from 'js/utils/messenger';
import apiHelper from 'js/api-helper';
import useValidationLogic from './use-validation-logic';

import Button from 'components/button';
import ValidationErrorTexts from 'components/validation-error-texts';

const isNotEmpty = value => !!value;

const maybeCastBoolean = value =>
  value === 'true' ? true : value === 'false' ? false : value;

const serializeForm = form => {
  const data = serialize(form, { hash: true, empty: true });

  return Object.entries(data).reduce((accum, [key, value]) => {
    const newValue = Array.isArray(value)
      ? value.filter(isNotEmpty)
      : maybeCastBoolean(value);

    return newValue === '' ? accum : { ...accum, [key]: newValue };
  }, {});
};

const Context = React.createContext({
  attachInput: () => {},
  detachInput: () => {},
  dirtyFields: {},
  errors: {},
  onChange: () => {}
});

const Form = ({
  children,
  className,
  endpoint,
  id,
  onChange,
  onRef,
  onResponse,
  onSubmit,
  showSubmitButton,
  submitLabel,
  validationErrors,
  validationEndpoint
}) => {
  const [form, setForm] = React.useState();
  const [isLoading, setLoading] = React.useState(false);
  const {
    attachInput,
    detachInput,
    dirtyFields,
    errors,
    onInputChange,
    setErrors,
    setAllFieldsDirty,
    validateForm
  } = useValidationLogic(form, validationErrors);

  const submitToApi = React.useCallback(
    data => {
      onSubmit(data);
      console.log(data);
      return apiHelper
        .execute(endpoint, data)
        .then(res => {
          onResponse(res);
        })
        .catch(() => {
          onResponse({});
        })
        .finally(() => {
          if (isLoading) {
            setLoading(false);
          }
        });
    },
    [endpoint, onSubmit, onResponse]
  );
  const submitValidation = data => {
    apiHelper
      .execute(validationEndpoint, data)
      .then(validations => {
        if (Object.keys(validations).length === 0) {
          submitToApi(data);
          return;
        }

        setErrors(validations);

        setAllFieldsDirty();

        return;
      })
      .finally(() => setLoading(false));
  };

  const onFormSubmit = React.useCallback(
    e => {
      e && e.preventDefault();

      messenger.hideMessage();

      const isValid = validateForm();

      if (!isValid) {
        setAllFieldsDirty();

        return;
      }

      const data = serializeForm(form);

      setLoading(true);

      validationEndpoint ? submitValidation(data) : submitToApi(data);
    },
    [form, validateForm, setErrors, setAllFieldsDirty, submitToApi]
  );

  // NOTE: Yes, this is dirty. As far as I can tell this is the only way of exposing the submit function while still having it be preventable.
  React.useEffect(() => {
    onRef({ submit: onFormSubmit });
  }, [onRef, onFormSubmit]);

  return (
    <form
      action={endpoint}
      className={cn('form', className)}
      ref={setForm}
      id={id}
      onSubmit={onFormSubmit}
    >
      <Context.Provider
        value={{
          attachInput,
          detachInput,
          dirtyFields,
          errors,
          onChange: name => {
            onInputChange(name);

            onChange(serializeForm(form));
          }
        }}
      >
        {typeof children === 'function' ? children({ isLoading }) : children}

        {showSubmitButton && (
          <Button className="form-submit" type="submit">
            {submitLabel}
          </Button>
        )}
      </Context.Provider>
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  endpoint: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onRef: PropTypes.func,
  onResponse: PropTypes.func,
  onSubmit: PropTypes.func,
  showSubmitButton: PropTypes.bool,
  submitLabel: PropTypes.string,
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes),
  validationEndpoint: PropTypes.string
};

Form.defaultProps = {
  validationErrors: {},
  onChange: () => {},
  onRef: () => {},
  onResponse: () => {},
  onSubmit: () => {}
};

Form.propTypesMeta = {
  children: 'exclude',
  className: 'exclude',
  id: 'exclude',
  showSubmitButton: 'exclude'
};

Form.Context = Context;

export default Form;
