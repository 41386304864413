import React, { useContext } from 'react';
import ModalContext from '../../context/modal';
import Icon from 'components/icon';
import Link from 'components/link';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import Collapse from 'react-tiny-collapse';
import GlobalSearch from 'components/global-search';

const MagazineHeaderSearch = ({ searchId, search, searchPageLink }) => {
  const modalContext = useContext(ModalContext);

  const hideModals = () => {
    modalContext.closeModals();
  };

  const toggleSearch = e => {
    e.preventDefault();
    modalContext.toggleSearchModal();
  };

  return (
    <div className="magazine-header__search-wrapper">
      {searchPageLink && (
        <Link
          aria-controls={searchId}
          aria-expanded={modalContext.isSearchModalOpen}
          hiddenText={true}
          onClick={toggleSearch}
          themes={[Link.themes.circle]}
          {...searchPageLink}
          className="magazine-header__search-link"
        >
          <Icon name="magnifier" />
        </Link>
      )}

      <Modal
        animate={false}
        className="magazine-header__search-modal"
        containerClassName="magazine-header__search-modal-inner"
        contentClassName="magazine-header__search-modal-content"
        hide={hideModals}
        id={searchId}
        isVisible={modalContext.isSearchModalOpen}
      >
        <Collapse
          unmountChildren={false}
          isOpen={modalContext.isSearchModalOpen}
        >
          <GlobalSearch {...search} />
        </Collapse>
      </Modal>
    </div>
  );
};

MagazineHeaderSearch.propTypes = {
  searchId: PropTypes.string,
  searchPageLink: PropTypes.exact(Link.propTypes),
  search: PropTypes.exact(GlobalSearch.propTypes)
};

MagazineHeaderSearch.propTypesMeta = {};

export default MagazineHeaderSearch;
