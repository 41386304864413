import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import TinyTransition from 'react-tiny-transition';

import Spinner from 'components/spinner';

const PageSpinner = ({ shouldContainInParent, isLoading }) => (
  <TinyTransition>
    {isLoading && (
      <div
        className={cn('page-spinner', {
          'is-contained': shouldContainInParent
        })}
      >
        <div className="page-spinner-wrapper">
          <Spinner theme={Spinner.themes.big} />
        </div>
      </div>
    )}
  </TinyTransition>
);

PageSpinner.propTypes = {
  shouldContainInParent: PropTypes.bool,
  isLoading: PropTypes.bool
};

PageSpinner.propTypesMeta = 'exclude';

export default PageSpinner;
