import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';
import Avatar from 'components/avatar';

const AppointmentBookingResultAppointment = props => {
  return (
    <div
      className="appointment-booking-result-appointment"
      onClick={props.onClickHandle}
    >
      <div className="appointment-booking-result-appointment__pin-box">
        <Icon name="eye" />
      </div>
      <strong className="appointment-booking-result-appointment__title">
        {props.appointment.appointmentName}
      </strong>
      <span className="appointment-booking-result-appointment__description">
        <span>
          {props.appointment.appointmentTime} {props.timeText} ·{' '}
        </span>
        <span>
          {props.priceText} {props.appointment.appointmentPrice}
        </span>
        <span> – {props.paymentTypeText}</span>
      </span>
      <div className="appointment-booking-result-appointment__edit-box">
        <Icon name="icon-edit" />
      </div>
      {Object.keys(props.assistant).length !== 0 && props.assistantId && (
        <div className="appointment-booking-result-appointment__assistant">
          <Avatar
            className="appointment-booking-result-appointment__assistant-image-holder"
            src={props.assistant.image}
            alt={props.assistant.name}
          />
          <span className="appointment-booking-result-appointment__assistant-name">
            {props.assistantText} {props.assistant.name}
          </span>
        </div>
      )}
    </div>
  );
};

AppointmentBookingResultAppointment.propTypes = {
  appointment: PropTypes.shape({
    appointmentName: PropTypes.string,
    appointmentTime: PropTypes.string,
    appointmentPrice: PropTypes.string
  }),
  timeText: PropTypes.string,
  priceText: PropTypes.string,
  paymentTypeText: PropTypes.string,
  assistant: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    timeSlots: PropTypes.arrayOf(PropTypes.string)
  }),
  assistantId: PropTypes.string,
  assistantText: PropTypes.string,
  onClickHandle: PropTypes.func
};

AppointmentBookingResultAppointment.propTypesMeta = {
  appointment: 'exclude',
  assistant: 'exclude',
  assistantId: 'exclude'
};

export default AppointmentBookingResultAppointment;
