import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Text from 'components/text';

const Availability = ({ text }) =>
  !text ? null : (
    <Text.div className="availability" theme={Text.themes.small}>
      <div className="availability-icon">
        <Icon name="truck" />
      </div>
      {text}
    </Text.div>
  );

Availability.propTypes = {
  text: PropTypes.string
};

export default Availability;
