import React from 'react';
import PropTypes from 'prop-types';

import ProductList from 'components/product-list';
import Text from 'components/text';

const ProductListBlock = ({ title, products, productListSize }) => {
  return (
    <div className="product-list-block">
      {title && (
        <Text.h2
          className="product-list-block-title"
          theme={Text.themes.uppercaseBig}
        >
          {title}
        </Text.h2>
      )}
      {products && (
        <div className="product-list-block-products">
          <ProductList
            size={productListSize}
            {...products}
            ctaType="product-list-block"
          />
        </div>
      )}
    </div>
  );
};

ProductListBlock.propTypes = {
  products: PropTypes.exact(ProductList.propTypes),
  productListSize: PropTypes.any,
  title: PropTypes.string
};

ProductListBlock.propTypesMeta = {
  productListSize: 'exclude'
};

export default ProductListBlock;
