import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WithModalProvider from '../../hoc/with-modal-provider';
import useBreakpoints from 'hooks/use-breakpoints';
import Cart from 'components/cart';
import GlobalSearch from 'components/global-search';
import Link from 'components/link';
import LoginModal from 'components/login-modal';
import MainNav from './main-nav';
import HeaderInner from 'components/header/header-inner';
import HeaderModals from 'components/header/header-modals';
import AppointmentBookingModal from 'components/appointment-booking-modal/appointment-booking-modal';
import SiteInfoBanner from 'components/site-info-banner';

const Header = props => {
  const [numberOfProducts, setNumberOfProducts] = useState(
    props.numberOfProductsInCart
  );
  const { isDesktop, isTablet, isMobile } = useBreakpoints(true, true);

  const onCartUpdate = ({ numberOfProductsInCart }) => {
    if (typeof numberOfProductsInCart === 'number') {
      setNumberOfProducts(numberOfProductsInCart);
    }
  };

  const loginId = 'header-login-modal';
  const searchId = 'header-search-modal';
  const cartId = 'header-cart-modal';

  return (
    <header className="header">
      <WithModalProvider>
        <HeaderInner
          isDesktop={isDesktop}
          numberOfProducts={numberOfProducts}
          loginId={loginId}
          searchId={searchId}
          cartId={cartId}
          cartLink={props.cartLink}
          supportLink={props.supportLink}
          magazineLink={props.magazineLink}
          myPageLink={props.myPageLink}
          linkToHome={props.linkToHome}
          phoneLink={props.phoneLink}
          searchPageLink={props.searchPageLink}
          mobileTopBarLinks={props.mobileTopBarLinks}
          isLoggedIn={props.isLoggedIn}
          hideMenuText={props.hideMenuText}
          showMenuText={props.showMenuText}
          skipToMainText={props.skipToMainText}
          showSiteInfoBanner={props.showSiteInfoBanner}
          siteInfoBanner={props.siteInfoBanner}
          bookAppointmentLink={props.mainNavigation.bookAppointmentLink}
        />
        <HeaderModals
          isDesktop={isDesktop}
          isTablet={isTablet}
          isMobile={isMobile}
          cart={props.cart}
          cartId={cartId}
          cartLink={props.cartLink}
          loginModal={props.loginModal}
          myPageLink={props.myPageLink}
          mainNavigation={props.mainNavigation}
          search={props.search}
          searchId={searchId}
          onCartUpdate={onCartUpdate}
        />
        <AppointmentBookingModal {...props.appointmentBookingModalData} />
      </WithModalProvider>
    </header>
  );
};

Header.propTypes = {
  cart: PropTypes.exact(Cart.propTypes),
  cartLink: PropTypes.exact(Link.propTypes),
  hideMenuText: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  linkToHome: PropTypes.exact(Link.propTypes),
  loginModal: PropTypes.exact(LoginModal.propTypes),
  myPageLink: PropTypes.exact(Link.propTypes),
  mainNavigation: PropTypes.exact(MainNav.propTypes),
  mobileTopBarLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  numberOfProductsInCart: PropTypes.number,
  phoneLink: PropTypes.exact(Link.propTypes),
  search: PropTypes.exact(GlobalSearch.propTypes),
  searchPageLink: PropTypes.exact(Link.propTypes),
  showMenuText: PropTypes.string,
  supportLink: PropTypes.exact(Link.propTypes),
  magazineLink: PropTypes.exact(Link.propTypes),
  appointmentBookingModalData: PropTypes.exact(
    AppointmentBookingModal.propTypes
  ),
  skipToMainText: PropTypes.string,
  showSiteInfoBanner: PropTypes.bool,
  siteInfoBanner: PropTypes.shape(SiteInfoBanner.propTypes)
};

Header.defaultProps = {
  mobileTopBarLinks: []
};

export default Header;
