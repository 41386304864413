import PropTypes from 'prop-types';

export default function checkApiPropTypes(data, typeSpec, typeName) {
  if (process.env.NODE_ENV !== 'production') {
    PropTypes.checkPropTypes(
      { response: PropTypes.exact(typeSpec) },
      { response: data },
      '',
      typeName
    );
  }
}
