import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Avatar = props => {
  const [src, setSrc] = useState('');
  const [alt, setAlt] = useState('');
  useEffect(() => {
    // runs when component changed
    setSrc(props.src);
    setAlt(props.alt);
  });

  return (
    <div className={cn('avatar', props.className)}>
      {!src ? null : <img className="avatar__image" src={src} alt={alt} />}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string
};

Avatar.propTypesMeta = {
  className: 'exclude'
};

export default Avatar;
