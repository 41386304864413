import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';

const LinkRow = ({ items }) => {
  return (
    <div className="link-row">
      <ul className="link-row__list">
        {items.map(({ title, link }, index) => (
          <li key={index} className="link-row__item">
            <div className="link-row__item-content">
              {title && <h6 className="link-row__title">{title}</h6>}
              <Link
                {...link}
                themes={[Link.themes.arrowRight, Link.themes.uppercase]}
                className="link-row__link"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

LinkRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      title: PropTypes.string
    })
  )
};

LinkRow.defaultProps = {
  items: []
};

export default LinkRow;
