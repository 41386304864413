import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const MagazineCategory = ({ className, text }) => {
  return <div className={cn('magazine-category', className)}>{text}</div>;
};

MagazineCategory.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

MagazineCategory.propTypesMeta = 'exclude';

export default MagazineCategory;
