import PropTypes from 'prop-types';

// NOTE: This file exists in order to generate a viewmodel for validation messages. Do not use this for rendering.
const ValidationErrorTexts = {};
ValidationErrorTexts.propTypes = {
  isRequired: PropTypes.string,
  isBirthDate: PropTypes.string,
  isUserName: PropTypes.string,
  isEmail: PropTypes.string,
  isPostalCode: PropTypes.string,
  isPhoneNumber: PropTypes.string
};

export default ValidationErrorTexts;
