import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components/button';
import CheckoutLoginForm from 'components/checkout-login-form';
import CheckoutRegisterForm from 'components/checkout-register-form';

const CheckoutLogin = ({
  login,
  isRegistered,
  onComplete,
  register,
  buttonText
}) => {
  return (
    <div className="checkout-login">
      {login && (
        <div className="checkout-login-login">
          <CheckoutLoginForm onLoggedIn={() => onComplete(true)} {...login} />
        </div>
      )}

      {register && (
        <div className="checkout-login-register">
          <CheckoutRegisterForm
            onRegistered={() => onComplete(true)}
            {...register}
          />
        </div>
      )}

      <div
        className={cn('checkout-login-footer', {
          'is-right-aligned': !isRegistered
        })}
      >
        <Button
          data-test-checkout-step-2-button
          onClick={() => onComplete(false)}
          iconAfter="chevron-right"
          themes={
            isRegistered
              ? [Button.themes.buttonCyan]
              : [Button.themes.buttonOutline]
          }
          text={buttonText}
        />
      </div>
    </div>
  );
};

CheckoutLogin.propTypes = {
  login: PropTypes.exact(CheckoutLoginForm.propTypes),
  isRegistered: PropTypes.bool,
  onComplete: PropTypes.func,
  register: PropTypes.exact(CheckoutRegisterForm.propTypes),
  buttonText: PropTypes.string
};

export default CheckoutLogin;
