/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from '../content-container';
import FooterImageList from './footer-image-list';
import FooterLinkList from './footer-link-list';
import NewsletterForm from 'components/newsletter-form';

import FooterSocialMedia from './footer-social-media';
import Image from '../image';
import Link from '../link';
import Text from 'components/text';

const Row = ({ children }) => <div className="footer-row">{children}</div>;
Row.propTypes = { children: PropTypes.node };

const Column = ({ children }) => (
  <div className="footer-column">{children}</div>
);
Column.propTypes = { children: PropTypes.node };

const Footer = ({
  imageLists,
  linkLists,
  mainLinks,
  newsLetterForm,
  secondaryLinks,
  socialMedia,
  text1,
  text2
}) => (
  <footer className="footer">
    <div className="footer-section">
      <ContentContainer>
        <Row>
          {mainLinks.map(({ link, image }) => (
            <Column key={link.url}>
              <div className="footer-image-link">
                <Image {...image} />
                <Link themes={[Link.themes.linkBigUppercase]} {...link} />
              </div>
            </Column>
          ))}
        </Row>

        <Row>
          {linkLists.map(linkList => (
            <Column key={linkList.id}>
              <FooterLinkList {...linkList} />
            </Column>
          ))}
        </Row>

        <Row>
          {secondaryLinks.map(link => (
            <Column key={link.text + link.url}>
              <Link
                themes={[Link.themes.buttonWhite, Link.themes.arrowRight]}
                {...link}
              />
            </Column>
          ))}

          <Column>
            <div className="footer-newsletter">
              <NewsletterForm {...newsLetterForm} />
            </div>
          </Column>
        </Row>
      </ContentContainer>
    </div>

    <div className="footer-section">
      <ContentContainer>
        <div className="footer-image-lists">
          {imageLists.map(list => (
            <FooterImageList key={list.title} {...list} />
          ))}

          <FooterSocialMedia {...socialMedia} />
        </div>

        <div className="footer-texts">
          {text1 && <Text.p theme={Text.themes.small}>{text1}</Text.p>}
          {text2 && <Text.p theme={Text.themes.small}>{text2}</Text.p>}
        </div>
      </ContentContainer>
    </div>
  </footer>
);

Footer.propTypes = {
  imageLists: PropTypes.arrayOf(PropTypes.exact(FooterImageList.propTypes)),
  linkLists: PropTypes.arrayOf(PropTypes.exact(FooterLinkList.propTypes)),
  mainLinks: PropTypes.arrayOf(
    PropTypes.exact({
      image: PropTypes.exact(Image.propTypes),
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  newsLetterForm: PropTypes.exact(NewsletterForm.propTypes),
  secondaryLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  socialMedia: PropTypes.exact(FooterSocialMedia.propTypes),
  text1: PropTypes.string,
  text2: PropTypes.string
};

Footer.defaultProps = {
  imageLists: [],
  linkLists: [],
  mainLinks: [],
  secondaryLinks: []
};

export default Footer;
