import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Collapse from 'react-tiny-collapse';

import propTypeTheme from 'utils/prop-type-theme';
import useFormValidation from 'hooks/use-form-validation';

import Icon from 'components/icon';
import Debounce from 'components/debounce';
import Text from 'components/text';

const themes = {
  bigText: 'theme-big-text',
  dark: 'theme-dark',
  grid: 'theme-grid',
  hiddenLabel: 'theme-hidden-label',
  narrow: 'theme-narrow',
  noValidation: 'theme-no-validation',
  white: 'theme-white',
  wide: 'theme-wide',
  cyan: 'theme-cyan',
  bordered: 'theme-bordered'
};

function makeValidations(validations = [], isRequired = false) {
  return validations.includes('isRequired') || !isRequired
    ? validations
    : ['isRequired'].concat(validations);
}

const TextInput = ({
  autocomplete,
  className,
  disabled,
  formId,
  idPrefix,
  isRequired,
  label,
  labelConditionalText = null,
  name,
  onChange,
  onRef,
  placeholder,
  shouldShowErrorMessage,
  shouldShowErrorOnBottom,
  theme,
  togglePasswordLabel,
  type,
  validations,
  value
}) => {
  const [error, validate, isDirty] = useFormValidation(
    name,
    makeValidations(validations, isRequired)
  );

  const [inputType, setInputType] = React.useState(type);

  const togglePassword = () => {
    if (type === 'password') {
      setInputType(type => (type === 'text' ? 'password' : 'text'));
    }
  };

  const id = `${idPrefix || ''}${name}`;

  const [isFilled, setIsFilled] = useState(false);

  return (
    <div
      className={cn('text-input', className, theme, {
        'has-error': error,
        'is-filled': isFilled
      })}
    >
      {label && (
        <Text element="label" htmlFor={id} theme={Text.themes.small}>
          {label}
          {shouldShowErrorMessage && !shouldShowErrorOnBottom && !!error && (
            <span className="text-input-label-error">{error}</span>
          )}
          {labelConditionalText && (
            <span className="text-input-label-extra-text">
              {labelConditionalText}
            </span>
          )}
        </Text>
      )}

      <input
        autoComplete={autocomplete}
        key={`re-render-on-value-change:${value}`} // input updated to re-render itself after default value change due to issue described here https://github.com/facebook/react/issues/4101#issuecomment-243625941
        defaultValue={value}
        disabled={disabled}
        form={formId}
        id={id}
        placeholder={placeholder}
        name={name}
        type={inputType}
        onBlur={isDirty ? () => {} : validate}
        onChange={e => {
          let inputValue = e.target.value;

          if (inputType === 'email') {
            inputValue = inputValue.toLowerCase();
            e.target.value = inputValue;
          }

          onChange(inputValue);

          inputValue ? setIsFilled(true) : setIsFilled(false);

          if (isDirty) {
            validate();
          }
        }}
        ref={input => {
          onRef(input);
        }}
      />

      {type === 'password' && (
        <button
          className={cn('text-input-password-button', {
            'is-active': inputType === 'password'
          })}
          type="button"
          onClick={togglePassword}
        >
          <span>{togglePasswordLabel}</span>
          <span className="text-input-password-icon">
            <Icon name="eye" />
          </span>
        </button>
      )}

      <Debounce wait={50}>
        <Collapse
          className="text-input-error"
          forceInitialAnimation={true}
          isOpen={shouldShowErrorMessage && shouldShowErrorOnBottom && !!error}
        >
          <div>{error}</div>
        </Collapse>
      </Debounce>
    </div>
  );
};

TextInput.propTypes = {
  autocomplete: PropTypes.oneOf([
    'off',
    'on',
    'name',
    'given-name',
    'family-name',
    'email',
    'new-password',
    'current-password',
    'street-address',
    'postal-code',
    'tel'
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  formId: PropTypes.string,
  idPrefix: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelConditionalText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRef: PropTypes.func,
  placeholder: PropTypes.string,
  shouldShowErrorMessage: PropTypes.bool,
  shouldShowErrorOnBottom: PropTypes.bool,
  theme: propTypeTheme(themes),
  togglePasswordLabel: PropTypes.string,
  type: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string
};

TextInput.propTypesMeta = {
  autocomplete: 'exclude',
  className: 'exclude',
  formId: 'exclude',
  idPrefix: 'exclude',
  labelConditionalText: 'exclude',
  shouldShowErrorMessage: 'exclude',
  shouldShowErrorOnBottom: 'exclude',
  type: 'exclude',
  theme: 'exclude',
  validations: 'exclude',
  validationErrors: 'exclude'
};

TextInput.defaultProps = {
  onChange: () => {},
  onRef: () => {},
  autocomplete: 'off',
  shouldShowErrorMessage: true,
  shouldShowErrorOnBottom: true,
  type: 'text',
  validations: []
};

TextInput.themes = themes;

export default TextInput;
