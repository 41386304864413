import React from 'react';
import PropTypes from 'prop-types';
import AppointmentBookingServiceAccordion from 'components/appointment-booking-modal/appointment-booking-service-accordion';

const AppointmentBookingServiceList = props => {
  return (
    <div className="appointment-booking-service-list">
      {props.services.map(item => {
        return (
          <AppointmentBookingServiceAccordion
            key={item.appointmentGroupId}
            id={item.appointmentGroupId}
            title={item.appointmentGroupName}
            mainIcon={item.appointmentGroupMainIconName}
            appointments={item.appointments}
            timeText={props.timeText}
            priceText={props.priceText}
            onClickService={props.onClickService}
          />
        );
      })}
    </div>
  );
};

AppointmentBookingServiceList.propTypes = {
  onClickService: PropTypes.func,
  timeText: PropTypes.string,
  priceText: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      appointmentGroupId: PropTypes.string.isRequired,
      appointmentGroupName: PropTypes.string.isRequired,
      appointmentGroupMainIconName: PropTypes.string.isRequired,
      appointments: PropTypes.arrayOf(
        PropTypes.shape({
          appointmentId: PropTypes.string.isRequired,
          appointmentName: PropTypes.string.isRequired,
          appointmentTime: PropTypes.string.isRequired,
          appointmentPrice: PropTypes.string.isRequired,
          appointmentLabel: PropTypes.string,
          appointmentDescription: PropTypes.string.isRequired
        })
      )
    })
  )
};

export default AppointmentBookingServiceList;
