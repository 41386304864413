import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import PorterBuddy from 'components/delivery-widget/porter-buddy';

const KlarnaAndPorterbuddy = ({
  klarnaIframeHtml,
  porterBuddyConfig,
  onKlarnaChange,
  onDeliveryWindowChange,
  hasError,
  klarnaErrorMessage
}) => {
  const container = useRef();

  const [config, setConfig] = useState({
    ...porterBuddyConfig,
    availabilityResponse: undefined
  });
  const [availability, setAvailability] = useState();

  async function handleOnDeliveryWindowSelect(deliveryWindow) {
    KlarnaAndPorterbuddy.handleOnKlarnaSuspend();

    await onDeliveryWindowChange(deliveryWindow);

    KlarnaAndPorterbuddy.handleOnKlarnaResume();
  }

  async function handleOnKlarnaChange(postCode) {
    let availabilityResponse = {};

    try {
      availabilityResponse = await onKlarnaChange(postCode);
      setAvailability(availabilityResponse);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setAvailability(undefined);
    }
  }

  useEffect(() => {
    const scriptsTags = container.current.getElementsByTagName('script');

    for (var i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode;
      const newScriptTag = document.createElement('script');
      newScriptTag.type = 'text/javascript';
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }, []);

  useEffect(() => {
    window._klarnaCheckout(({ on, suspend, resume }) => {
      on({
        load: ({ shipping_address }) => {
          const { postal_code } = shipping_address;

          //console.log('--> Loaded Klarna!');

          handleOnKlarnaChange(postal_code);
        },
        shipping_address_change: ({ postal_code }) => {
          //console.log('--> Klarna shipping address change!');

          handleOnKlarnaChange(postal_code);
        }
      });

      KlarnaAndPorterbuddy.handleOnKlarnaSuspend = () => {
        //console.log('--> Suspending Klarna!');

        suspend();
      };

      KlarnaAndPorterbuddy.handleOnKlarnaResume = () => {
        //console.log('--> Resuming Klarna!');

        resume();
      };
    });

    return () => {
      window._klarnaCheckout(({ on }) => {
        on({
          load: undefined,
          shipping_address_change: undefined,
          validation_callback: undefined
        });
      });

      window._klarnaCheckout = undefined;
    };
  }, []);

  useEffect(() => {
    KlarnaAndPorterbuddy.handleOnKlarnaSuspend();
    if (hasError) {
      console.log('--> Klarna validation enabled, should_proceed: false');
      window._klarnaCheckout(({ on }) => {
        on({
          validation_callback: function (_data, callback) {
            callback({
              should_proceed: false,
              message: klarnaErrorMessage
            });
          }
        });
      });
    }
    else {
      console.log('--> Klarna validation removed, validation_callback: undefined');
      window._klarnaCheckout(({ on }) => {
        on({
          validation_callback: undefined
        });
      });
    }
    KlarnaAndPorterbuddy.handleOnKlarnaResume();
  }, [hasError]);

  useEffect(() => {
    setConfig({
      ...config,
      availabilityResponse: availability
    });
  }, [availability]);

  return (
    <div className="klarna-and-porterbuddy">
      <div className="klarna-and-porterbuddy__porterbuddy">
        <PorterBuddy
          config={config}
          onSelect={handleOnDeliveryWindowSelect}
          hasError={hasError}
        />
      </div>
      <div className="klarna-and-porterbuddy__klarna">
        <div ref={container}>
          <div
            data-test-klarna-iframe
            dangerouslySetInnerHTML={{ __html: klarnaIframeHtml }}
          />
        </div>
      </div>
    </div>
  );
};

KlarnaAndPorterbuddy.handleOnKlarnaSuspend = () => {};
KlarnaAndPorterbuddy.handleOnKlarnaResume = () => {};

KlarnaAndPorterbuddy.propTypes = {
  klarnaIframeHtml: PropTypes.string,
  porterBuddyConfig: PropTypes.object,
  onKlarnaChange: PropTypes.func,
  onDeliveryWindowChange: PropTypes.func,
  hasError: PropTypes.bool,
  klarnaErrorMessage: PropTypes.string
};

KlarnaAndPorterbuddy.propTypesMeta = 'exclude';

export default KlarnaAndPorterbuddy;
