import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from '../button';
import CategoryLink from './category-link';
import ShapeLink from './shape-link';
import ContentContainer from 'components/content-container';
import Icon from 'components/icon';
import Link from 'components/link';
import Text from 'components/text';

const SubMenu = ({
  categoriesTitle,
  categories,
  shapesTitle,
  shapes,
  closeLabel,
  hide,
  isActive,
  linkLists
}) => {
  return (
    <div className={cn('submenu', { 'submenu--active': isActive })}>
      <ContentContainer className="submenu-content">
        <Text.div className="submenu-links-container" theme={Text.themes.small}>
          {categories.length > 0 && (
            <div
              className={cn('submenu-link-list', {
                'is-narrow': shapes && shapes.length > 0
              })}
            >
              {categoriesTitle && (
                <Text.h2
                  className="submenu-title"
                  theme={Text.themes.boldUppercaseSmall}
                >
                  {categoriesTitle}
                </Text.h2>
              )}
              <ul>
                {categories.map(link => (
                  <li className="submenu-category-link" key={link.url}>
                    <CategoryLink {...link} />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {shapes && shapes.length > 0 && (
            <div className="submenu-link-list">
              {shapesTitle && (
                <Text.h2
                  className="submenu-title"
                  theme={Text.themes.boldUppercaseSmall}
                >
                  {shapesTitle}
                </Text.h2>
              )}
              <ul>
                {shapes.map(link => (
                  <li className="submenu-shape-link" key={link.url}>
                    <ShapeLink {...link} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {linkLists.map(({ extraLink, links, title } = {}, index) => (
            <div
              className={cn('submenu-link-list', {
                'is-two-columns': links.length > 10
              })}
              key={title || index}
            >
              {title && (
                <Text.h2
                  className="submenu-title"
                  theme={Text.themes.boldUppercaseSmall}
                >
                  {title}
                </Text.h2>
              )}
              <ul>
                {links.map(link => (
                  <li key={link.url}>
                    <Link
                      className="submenu-link"
                      iconBefore="chevron-right"
                      themes={[Link.themes.linkGray]}
                      {...link}
                    ></Link>
                  </li>
                ))}
              </ul>
              {extraLink && <Link {...extraLink} />}
            </div>
          ))}
        </Text.div>
      </ContentContainer>
      <Button
        aria-label={closeLabel}
        className="submenu-close"
        onClick={hide}
        themes={[Button.themes.circle]}
      >
        <Icon name="x" />
      </Button>
    </div>
  );
};

SubMenu.propTypesMeta = {
  isActive: 'exclude'
};

SubMenu.propTypes = {
  categoriesTitle: PropTypes.string,
  isActive: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.exact(CategoryLink.propTypes)),
  shapesTitle: PropTypes.string,
  shapes: PropTypes.arrayOf(PropTypes.exact(ShapeLink.propTypes)),
  closeLabel: PropTypes.string,
  hide: PropTypes.func,
  linkLists: PropTypes.arrayOf(
    PropTypes.exact({
      extraLink: PropTypes.exact(Link.propTypes),
      links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
      title: PropTypes.string
    })
  )
};

SubMenu.defaultProps = {
  categories: [],
  linkLists: []
};

export default SubMenu;
