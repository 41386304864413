import isRunningOnClient from '@creuna/utils/is-running-on-client';

function minWidth(size) {
  return function() {
    return isRunningOnClient
      ? window.matchMedia(`(min-width: ${size}px)`).matches
      : null;
  };
}

function maxWidth(size) {
  return function() {
    return isRunningOnClient
      ? window.matchMedia(`(max-width: ${size}px)`).matches
      : null;
  };
}

const breakpoints = {
  s: 480,
  m: 700,
  l: 1024,
  xl: 1280,
  xxl: 1600
};

export default {
  s: minWidth(breakpoints.s),
  m: minWidth(breakpoints.m),
  l: minWidth(breakpoints.l),
  xl: minWidth(breakpoints.xl),
  xxl: minWidth(breakpoints.xxl),

  sMax: maxWidth(breakpoints.s - 1),
  mMax: maxWidth(breakpoints.m - 1),
  lMax: maxWidth(breakpoints.l - 1),
  xlMax: maxWidth(breakpoints.xl - 1),
  xxlMax: maxWidth(breakpoints.xxl - 1)
};
