import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ProductVariantText = ({ textHtml, className }) => {
  return textHtml ? (
    <div
      className={cn('product-variant-text', className)}
      dangerouslySetInnerHTML={{ __html: textHtml }}
    ></div>
  ) : null;
};

ProductVariantText.propTypes = {
  textHtml: PropTypes.string,
  className: PropTypes.string
};

ProductVariantText.propTypesMeta = 'exclude';

export default ProductVariantText;
