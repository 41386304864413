import React from 'react';
import PropTypes from 'prop-types';

import FluidImage from 'components/fluid-image';
import Text from 'components/text';

const CategoryLink = ({ image, text, url }) => {
  return (
    <a className="category-link" href={url}>
      <div className="category-link-image">
        <FluidImage {...image} />
      </div>
      <Text.span className="category-link-text" theme={Text.themes.small}>
        {text}
      </Text.span>
    </a>
  );
};

CategoryLink.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  text: PropTypes.string,
  url: PropTypes.string
};

export default CategoryLink;
