import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'components/grid';
import TextInput from 'components/text-input';
import Form from 'components/form';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import PageSpinner from 'components/page-spinner';

const ChangePasswordPage = ({
  form,
  layout,
  newPassword,
  oldPassword,
  section
}) => {
  return (
    <MyPageLayout {...layout}>
      <Grid theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <MyPageSection headingLevel={1} {...section}>
            <Form showSubmitButton={true} {...form}>
              {({ isLoading }) => (
                <React.Fragment>
                  <PageSpinner
                    shouldContainInParent={true}
                    isLoading={isLoading}
                  />
                  {oldPassword && (
                    <TextInput
                      validations={['isRequired']}
                      type="password"
                      autocomplete="current-password"
                      {...oldPassword}
                    />
                  )}
                  <TextInput
                    validations={['isRequired']}
                    type="password"
                    autocomplete="new-password"
                    {...newPassword}
                  />
                </React.Fragment>
              )}
            </Form>
          </MyPageSection>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

ChangePasswordPage.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  newPassword: PropTypes.shape(TextInput.propTypes),
  oldPassword: PropTypes.shape(TextInput.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes)
};

export default ChangePasswordPage;
