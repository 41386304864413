import React from 'react';
import serialize from 'form-serialize';

import * as validators from 'js/form-validators';
import omit from 'js/omit';

const defaultErrorMessages = {
  isRequired: 'Feltet er påkrevd',
  isUserName:
    'Navnet må  inneholde både fornavn og etternavn på mer enn 1 bokstav',
  isBirthDate: 'Datoen er ikke riktig',
  isEmail: 'E-postadressen er ikke riktig',
  isPostalCode: 'Postnummer er feil',
  isPhoneNumber: 'Telefonnummeret er ikke riktig'
};

/*
When input elements call `attachInput` (preferrably with the useFormValidation hook), an entry is added in `validations` with the input name as key and the list of validation names as value. Whenever the input calls the `onInputChange` function, the input value is determined using 'form-serialize' and the value is checked for all of the validation rules specified in its entry in `validations`
*/
export default function useValidationLogic(form, errorMessages) {
  // NOTE: 'fields' has input names as keys and a list of validation rule names as values
  const [fields, setFields] = React.useState({});
  // NOTE: 'dirtyFields' has input names as keys and booleans as values and indicates whether a field has been interacted with by a user.
  const [dirtyFields, setDirtyFields] = React.useState({});
  // NOTE: 'errors' has input names as keys and the values can be an error message (string) or `undefined` (when the input has no validation errors).
  const [errors, setErrors] = React.useState({});

  const attachInput = React.useCallback((name, validationRules = []) => {
    validationRules.forEach(ruleName => {
      if (typeof validators[ruleName] !== 'function') {
        throw new Error(`Found no validator named '${ruleName}'`);
      }
    });
    setDirtyFields(state => ({ ...state, [name]: false }));
    setFields(state => ({ ...state, [name]: validationRules }));
  }, []);

  const detachInput = React.useCallback(
    name => setFields(state => omit(state, [name])),
    []
  );

  const validate = React.useCallback(
    (name, value) => {
      const violation = (fields[name] || []).find(
        validationName => !validators[validationName](value)
      );
      if (violation) {
        setErrors(errors => ({
          ...errors,
          [name]: errorMessages[violation] || defaultErrorMessages[violation]
        }));
        return false;
      } else {
        setErrors(errors => omit(errors, [name]));
        return true;
      }
    },
    [fields, errorMessages]
  );

  const validateForm = React.useCallback(() => {
    const formContent = serialize(form, { hash: true, empty: true });
    const validities = Object.entries(formContent).map(([name, value]) =>
      validate(name, value)
    );
    return validities.every(valid => valid);
  }, [form, validate]);

  // This performs an initial validity check of the entire form
  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  const onInputChange = React.useCallback(
    name => {
      const formContent = serialize(form, { hash: true, empty: true });
      validate(name, formContent[name]);
      setDirtyFields(state => ({ ...state, [name]: true }));
    },
    [form, validate]
  );

  const setAllFieldsDirty = React.useCallback(() => {
    setDirtyFields(state =>
      Object.entries(state).reduce(
        (accum, [key]) => ({ ...accum, [key]: true }),
        {}
      )
    );
  }, []);

  return {
    attachInput,
    detachInput,
    dirtyFields,
    errors,
    onInputChange,
    setErrors,
    setAllFieldsDirty,
    validateForm
  };
}
