import React from 'react';
import PropTypes from 'prop-types';

import CarouselContext from './carousel-context';

const CarouselContextProvider = ({ children, currentSlideIndex }) => {
  return (
    <CarouselContext.Provider value={{ currentSlideIndex }}>
      {children}
    </CarouselContext.Provider>
  );
};

CarouselContextProvider.propTypes = {
  children: PropTypes.node,
  currentSlideIndex: PropTypes.number
};

CarouselContextProvider.propTypesMeta = 'exclude';

export default CarouselContextProvider;
