import React, { useState } from 'react';
import PropTypes from 'prop-types';
import modalContext from '../context/modal';
import analytics from 'js/analytics';
import isRunningOnClient from '@creuna/utils/is-running-on-client';

const WithModalProvider = props => {
  const [defaultModalState] = useState({
    appointmentBooking: false,
    login: false,
    search: false,
    cart: false,
    mobileMenu: false
  });

  const [modalState, setModalState] = useState({
    appointmentBooking: false,
    login: false,
    search: false,
    cart: false,
    mobileMenu: false
  });

  const closeModalsHandler = () => {
    if (isRunningOnClient) document.body.style.overflow = 'unset';

    setModalState({ ...defaultModalState });
  };

  const toggleModal = (
    modal,
    isStorePreselected,
    isPreselectedAppointmentId,
    isPreselectedOpticianId
  ) => {
    setModalState(currentState => {
      if (!currentState[modal]) {
        if (isRunningOnClient) document.body.style.overflow = 'hidden';
        analytics.send({
          event: modal + '-open',
          ...(modal === 'appointmentBooking'
            ? {
                store_preselected: isStorePreselected ? true : false,
                appointment_preselected: isPreselectedAppointmentId
                  ? true
                  : false,
                optician_preselected: isPreselectedOpticianId ? true : false
              }
            : {})
        });
      } else {
        if (isRunningOnClient) document.body.style.overflow = 'unset';
      }

      return { ...defaultModalState, [modal]: !modalState[modal] };
    });
  };

  return (
    <modalContext.Provider
      value={{
        isAppointmentBookingModalOpen: modalState.appointmentBooking,
        toggleAppointmentBookingModal: (
          isStorePreselected,
          isPreselectedAppointmentId,
          isPreselectedOpticianId
        ) => {
          toggleModal(
            'appointmentBooking',
            isStorePreselected,
            isPreselectedAppointmentId,
            isPreselectedOpticianId
          );
        },
        isLoginModalOpen: modalState.login,
        toggleLoginModal: () => {
          toggleModal('login');
        },
        isSearchModalOpen: modalState.search,
        toggleSearchModal: () => {
          toggleModal('search');
        },
        isCartModalOpen: modalState.cart,
        toggleCartModal: () => {
          toggleModal('cart');
        },
        isMobileMenuOpen: modalState.mobileMenu,
        toggleMobileMenu: () => {
          toggleModal('mobileMenu');
        },
        closeMobileMenu: closeModalsHandler,
        closeModals: closeModalsHandler
      }}
    >
      {props.children}
    </modalContext.Provider>
  );
};

WithModalProvider.propTypes = {
  children: PropTypes.node
};

export default WithModalProvider;
