import React from 'react';
import PropTypes from 'prop-types';

class StaticContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    shouldUpdate: PropTypes.bool
  };

  static propTypesMeta = 'exclude';

  shouldComponentUpdate() {
    return this.props.shouldUpdate;
  }

  render() {
    return this.props.children;
  }
}

export default StaticContainer;
