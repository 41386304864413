import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Text from 'components/text';

const FooterImageList = ({ title, images }) => (
  <div className="footer-image-list">
    <Text.p theme={Text.themes.small}>{title}</Text.p>
    <ul>
      {images.map(image => (
        <li key={image.src}>
          <Image
            className="footer-image-list-image"
            responsive={false}
            {...image}
          />
        </li>
      ))}
    </ul>
  </div>
);

FooterImageList.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.exact(Image.propTypes))
};

FooterImageList.defaultProps = {
  images: []
};

export default FooterImageList;
