import React from 'react';

import breakpoints from 'js/breakpoints';

export default function useBreakpoints(
  initiallyDesktop,
  initiallyTablet,
  initiallyMobile
) {
  const [state, setState] = React.useState({
    isDesktop: initiallyDesktop,
    isTablet: initiallyTablet,
    isMobile: initiallyMobile
  });

  const onResize = () => {
    setState({
      isDesktop: breakpoints.l(),
      isTablet: breakpoints.m(),
      isMobile: breakpoints.s()
    });
  };

  React.useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return state;
}
