import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import useToggle from 'hooks/use-toggle';

import Icon from 'components/icon';
import Image from 'components/image/image';
import OnPageEditing from 'components/on-page-editing';
import RichText from 'components/rich-text';
import Text from 'components/text';

const AppointmentBookingServiceAccordion = props => {
  const [isExpanded, toggle] = useToggle(false);

  return (
    <div
      className="accordion appointment-booking-service-accordion"
      key={props.id}
    >
      <button
        aria-controls={props.id}
        aria-expanded={isExpanded}
        className={cn({ 'is-active': isExpanded })}
        onClick={toggle}
        type="button"
      >
        <div className="accordion-header appointment-booking-service-accordion__header">
          <div className="accordion-icon appointment-booking-service-accordion__header-icon accordion-icon appointment-booking-service-accordion__header-icon--left">
            <Image src={props.mainIcon} />
          </div>
          <div className="appointment-booking-service-accordion__header-title">
            <OnPageEditing name={props.title}>
              <Text className="accordion-header-text appointment-booking-service-accordion__header-text">
                {props.title}
              </Text>
            </OnPageEditing>
          </div>
          <div className="accordion-icon appointment-booking-service-accordion__header-icon accordion-icon appointment-booking-service-accordion__header-icon--right">
            <Icon name="chevron-down" />
          </div>
          <div className="appointment-booking-service-accordion__header-decoration" />
        </div>
      </button>

      <Collapse componentProps={{ id: props.id }} isOpen={isExpanded}>
        <div className="appointment-booking-service-accordion__inner">
          {props.appointments.map((item, index) => {
            return (
              <OnPageEditing name={item.appointmentName} key={index}>
                <div
                  className="accordion-content appointment-booking-service-accordion__content"
                  onClick={() => {
                    props.onClickService(item.appointmentId);
                  }}
                >
                  <div className="appointment-booking-service-accordion__content-inner">
                    <div className="appointment-booking-service-accordion__label">
                      {item.appointmentLabel && (
                        <span className="appointment-booking-service-accordion__label-inner">
                          {item.appointmentLabel}
                        </span>
                      )}
                    </div>
                    <div className="accordion-content-header appointment-booking-service-accordion__content-header">
                      <span className="appointment-booking-service-accordion__name">
                        {item.appointmentName}
                      </span>
                      <div className="appointment-booking-service-accordion__additional-info">
                        <span className="appointment-booking-service-accordion__time">
                          {`${item.appointmentTime} `}
                          {`${props.timeText} `}
                        </span>
                        <span className="appointment-booking-service-accordion__price">
                          {`${props.priceText} `}
                          {item.appointmentPrice}
                        </span>
                      </div>
                    </div>
                    <RichText
                      element="p"
                      className="accordion-description appointment-booking-service-accordion__description"
                      text={item.appointmentDescription}
                    />
                  </div>
                </div>
              </OnPageEditing>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

AppointmentBookingServiceAccordion.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  mainIcon: PropTypes.string,
  timeText: PropTypes.string,
  priceText: PropTypes.string,
  onClickService: PropTypes.func,
  appointments: PropTypes.arrayOf(
    PropTypes.shape({
      appointmentId: PropTypes.string,
      appointmentName: PropTypes.string,
      appointmentTime: PropTypes.string,
      appointmentPrice: PropTypes.string,
      appointmentDescription: PropTypes.string
    })
  )
};

AppointmentBookingServiceAccordion.propTypesMeta = {
  id: 'exclude',
  title: 'exclude',
  mainIcon: 'exclude'
};

export default AppointmentBookingServiceAccordion;
