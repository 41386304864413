import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/form';
import HiddenInput from 'components/hidden-input';
import Button from 'components/button';
import Icon from '../icon';
import cn from 'classnames';

const CartDiscountDeleteForm = ({
  text,
  discountCode,
  className,
  endpoint,
  submitLabel,
  onSubmit = () => {},
  onUpdate = () => {}
}) => {
  return (
    <div className={cn('cart-discount-delete-form', className)}>
      <div className="cart-discount-delete-form__form-wrapper">
        <Form
          endpoint={endpoint}
          showSubmitButton={false}
          onSubmit={onSubmit}
          onResponse={onUpdate}
        >
          <HiddenInput name="discountCode" value={discountCode} />

          <Button
            aria-label={submitLabel}
            type="submit"
            themes={[Button.themes.circleSmall]}
            className="cart-discount-delete-form__button"
          >
            <Icon name="small-x" />
          </Button>
        </Form>
      </div>
      <div className="cart-discount-delete-form__text-wrapper">{text}</div>
    </div>
  );
};

CartDiscountDeleteForm.propTypes = {
  discountCode: PropTypes.string,
  text: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  className: PropTypes.string
};

CartDiscountDeleteForm.propTypesMeta = {
  className: 'exclude'
};

CartDiscountDeleteForm.defaultProps = {
  endpoint: ''
};

export default CartDiscountDeleteForm;
