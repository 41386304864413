import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from '../content-container';
import Link from '../link';
import Text from 'components/text';

const MyPageLayout = ({
  children,
  className,
  ingress,
  title,
  link,
  logoutLink
}) => (
  <div className={cn('my-page-layout', className)}>
    <ContentContainer>
      {link && (
        <Link
          className="my-page-layout-link"
          iconBefore="chevron-left"
          {...link}
        />
      )}
    </ContentContainer>

    {(title || ingress) && (
      <div className="my-page-layout-header">
        <ContentContainer>
          {title && <Text.h1 theme={Text.themes.uppercaseBig}>{title}</Text.h1>}
          {ingress && <p>{ingress}</p>}
          {logoutLink && (
            <Link className="my-page-layout-logout" {...logoutLink} />
          )}
        </ContentContainer>
      </div>
    )}

    <ContentContainer>
      <div className="my-page-layout-content">{children}</div>
    </ContentContainer>
  </div>
);

MyPageLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ingress: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  logoutLink: PropTypes.exact(Link.propTypes)
};

MyPageLayout.propTypesMeta = {
  className: 'exclude'
};

export default MyPageLayout;
