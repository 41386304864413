import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from '../button';
import ContentContainer from '../content-container';
import Icon from '../icon';
import TabTrapper from './tab-trapper';

class Modal extends React.Component {
  static propTypes = {
    animate: PropTypes.bool,
    className: PropTypes.string,
    closeButtonClassName: PropTypes.string,
    plainCloseButton: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
    modalLabel: PropTypes.string,
    closeButtonText: PropTypes.string,
    containerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    hide: PropTypes.func.isRequired, // will be triggered by click on close button, modal background or by pressing the esc key
    id: PropTypes.string,
    isVisible: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    hideCloseText: PropTypes.bool
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    animate: true,
    modalLabel: '',
    closeButtonText: 'Lukk',
    showCloseButton: true,
    hideCloseText: true,
    plainCloseButton: false
  };

  state = {
    contentHeight: 0,
    fitsOnScreen: false,
    isMounted: false
  };

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      this.modal.addEventListener('keyup', this.handleEscPress);
      this.setState({
        fitsOnScreen: this.modal.offsetHeight < window.innerHeight
      });
    });
  }

  componentWillUnmount() {
    this.modal.removeEventListener('keyup', this.handleEscPress);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible !== prevProps.isVisible && this.props.isVisible) {
      this.onAfterShowModal();
    }
  }

  onAfterShowModal = () => {
    requestAnimationFrame(() => {
      this.modal.focus();
      this.modalWrapper.scrollTop = 0;
    });

    this.setState(
      {
        fitsOnScreen: this.modal.offsetHeight < window.innerHeight
      },
      () => {
        this.setState({ contentHeight: this.modalWrapper.scrollHeight });
      }
    );
  };

  handleEscPress = e => {
    if (e.which === 27) {
      this.props.hide();
    }
  };

  render() {
    const ariaProps = { 'aria-modal': true, role: 'dialog' };
    return !this.state.isMounted
      ? null
      : ReactDOM.createPortal(
          <div
            className={cn('modal', this.props.className, {
              'animation-enabled': this.props.animate,
              'is-visible': this.props.isVisible,
              'fits-on-screen': this.state.fitsOnScreen
            })}
            id={this.props.id}
            ref={d => (this.modalWrapper = d)}
            {...ariaProps}
          >
            <ContentContainer className={this.props.containerClassName}>
              <div
                className="modal-background"
                style={{
                  minHeight: this.state.contentHeight
                }}
                onClick={this.props.hide}
              />

              <div
                className={cn('modal-content', this.props.contentClassName)}
                ref={d => (this.modal = d)}
                tabIndex={-1}
              >
                <TabTrapper isActive={this.props.isVisible}>
                  {this.props.children}
                  {this.props.modalLabel && (
                    <span className="modal-label">{this.props.modalLabel}</span>
                  )}
                  {this.props.showCloseButton && this.props.hideCloseText && (
                    <Button
                      aria-label={this.props.closeButtonText}
                      className={cn(
                        'modal-close',
                        this.props.closeButtonClassName
                      )}
                      onClick={this.props.hide}
                      themes={
                        this.props.plainCloseButton
                          ? [Button.themes.plain]
                          : [Button.themes.circle]
                      }
                    >
                      <Icon name="x" />
                    </Button>
                  )}
                  {!this.props.hideCloseText && (
                    <span
                      className="modal-close modal-close-text"
                      onClick={this.props.hide}
                    >
                      {this.props.closeButtonText}
                    </span>
                  )}
                </TabTrapper>
              </div>
            </ContentContainer>
          </div>,
          document.body
        );
  }
}

export default Modal;
