import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';

const ShapeLink = ({ image, text, url, tabIndex = null }) => {
  return (
    <a className="shape-link" href={url} tabIndex={tabIndex}>
      <div className="shape-link-image">
        <Image responsive={false} {...image} />
      </div>
      <div className="shape-link-text">{text}</div>
    </a>
  );
};

ShapeLink.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  text: PropTypes.string,
  url: PropTypes.string,
  tabIndex: PropTypes.number
};

ShapeLink.propTypesMeta = {
  tabIndex: 'exclude'
};

export default ShapeLink;
