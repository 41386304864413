import React from 'react';
import PropTypes from 'prop-types';

import Collapse from 'react-tiny-collapse';

import Button from '../button';
import MyOrdersItem from './my-orders-item';
import Text from 'components/text';

class MyOrders extends React.Component {
  static propTypes = {
    columnLabels: PropTypes.arrayOf(PropTypes.string),
    collapseLabel: PropTypes.string,
    expandLabel: PropTypes.string,
    showExpandButton: PropTypes.bool,
    maxLength: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.exact(MyOrdersItem.propTypes)),
    title: PropTypes.string
  };

  static defaultProps = {
    columnLabels: [],
    maxLength: 3,
    showExpandButton: true,
    rows: []
  };

  state = {
    isExpanded: !this.props.showExpandButton
  };

  toggle = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }));
  };

  render() {
    const visibleRows = this.props.rows.slice(0, this.props.maxLength);
    const hiddenRows = this.props.rows.slice(
      this.props.maxLength,
      this.props.rows.length
    );

    return (
      <div className="my-orders">
        <Collapse isOpen={true} animateChildren={true}>
          <table>
            <thead>
              <tr>
                {this.props.columnLabels.map(label => (
                  <Text element="th" key={label} theme={Text.themes.small}>
                    {label}
                  </Text>
                ))}
                <th />
              </tr>
            </thead>
            <tbody>
              {visibleRows
                .concat(this.state.isExpanded ? hiddenRows : [])
                .map(row => (
                  <MyOrdersItem
                    columnLabels={this.props.columnLabels}
                    key={row.id}
                    {...row}
                  />
                ))}
            </tbody>
          </table>
        </Collapse>

        {this.props.showExpandButton && (
          <div className="my-orders-button-container">
            <Button
              text={
                this.state.isExpanded
                  ? this.props.collapseLabel
                  : this.props.expandLabel
              }
              onClick={this.toggle}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MyOrders;
