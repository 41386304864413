import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import useToggle from 'hooks/use-toggle';

import Collapse from 'react-tiny-collapse';
import Button from 'components/button';

const themes = {
  cyan: 'theme-cyan'
};

const TeaserExpander = ({
  children,
  isCollapsable,
  showLessText,
  showMoreText,
  theme
}) => {
  const [isActive, toggle] = useToggle(!isCollapsable);

  return (
    <div className={cn('teaser-expander', theme, { 'is-expanded': isActive })}>
      <Collapse className="teaser-expander-content" isOpen={isActive}>
        {children}
      </Collapse>

      {isCollapsable && (
        <>
          {!isActive && (
            <div className="teaser-expander-clicker" onClick={toggle} />
          )}

          <Button
            className="teaser-expander-button"
            text={isActive ? showLessText : showMoreText}
            onClick={toggle}
            type="button"
            themes={
              theme === themes.cyan
                ? [Button.themes.link]
                : [Button.themes.buttonOutline]
            }
          />
        </>
      )}
    </div>
  );
};

TeaserExpander.propTypes = {
  children: PropTypes.node,
  isCollapsable: PropTypes.bool,
  showLessText: PropTypes.string.isRequired,
  showMoreText: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(Object.values(themes))
};

TeaserExpander.propTypesMeta = 'exclude';

TeaserExpander.themes = themes;

export default TeaserExpander;
