import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';
import TextInput from 'components/text-input';
import cn from 'classnames';

const Search = ({
  autoComplete,
  buttonText,
  name,
  value,
  onChange,
  onRef,
  placeholder,
  label,
  showSubmitButton,
  submitButtonText,
  textInputTheme,
  className
}) => (
  <div className={cn('search', className)}>
    <TextInput
      autoComplete={autoComplete}
      className="search-input"
      defaultValue={value}
      name={name}
      onChange={onChange}
      onRef={onRef}
      placeholder={placeholder}
      shouldShowErrorMessage={false}
      type="search"
      theme={textInputTheme}
      label={label}
    />

    {showSubmitButton && (
      <div className="search-button-container">
        <Button
          aria-label={buttonText}
          className={cn('search-button', {
            'search-button--with-text': submitButtonText
          })}
          type="submit"
          themes={submitButtonText ? [Button.themes.buttonCyan] : []}
        >
          {submitButtonText ? (
            <span>{submitButtonText}</span>
          ) : (
            <Icon name="magnifier" />
          )}
        </Button>
      </div>
    )}
  </div>
);

Search.propTypes = {
  autoComplete: PropTypes.string,
  buttonText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRef: PropTypes.func,
  placeholder: PropTypes.string,
  showSubmitButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
  textInputTheme: PropTypes.any,
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string
};

Search.propTypesMeta = {
  autoComplete: 'exclude',
  showSubmitButton: 'exclude',
  textInputTheme: 'exclude',
  className: 'exclude'
};

Search.defaultProps = {
  name: 'search-term',
  showSubmitButton: true,
  value: '',
  submitButtonText: ''
};

export default Search;
