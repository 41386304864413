import React from 'react';
import PropTypes from 'prop-types';

import CollapseList from 'components/collapse-list';
import Checkbox from 'components/checkbox';

const GenericFilter = ({
  collapseLabel,
  expandLabel,
  idPrefix,
  maxLength,
  options
}) => {
  return (
    <div className="generic-filter">
      <CollapseList {...{ collapseLabel, expandLabel, maxLength }}>
        {options.map(option => (
          <Checkbox
            idPrefix={idPrefix}
            key={option.value}
            theme={Checkbox.themes.link}
            {...option}
          />
        ))}
      </CollapseList>
    </div>
  );
};

GenericFilter.propTypes = {
  collapseLabel: PropTypes.string,
  expandLabel: PropTypes.string,
  idPrefix: PropTypes.string,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.exact(Checkbox.propTypes))
};

GenericFilter.propTypesMeta = {
  idPrefix: 'exclude'
};

GenericFilter.defaultProps = {
  options: []
};

export default GenericFilter;
