import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-tiny-collapse';
import Form from 'components/form';
import Checkbox from 'components/checkbox';
import TextInput from 'components/text-input';
import Button from 'components/button';
import cn from 'classnames';

const CartDiscountForm = ({
  className,
  endpoint,
  toggleCheckbox,
  input,
  submitLabel,
  onSubmit = () => {},
  onUpdate = () => {}
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSeparateDeliveryAddress = checked => {
    setIsExpanded(checked);
  };

  let codeInput = null;

  const onInputRef = el => {
    codeInput = el;
  };

  React.useEffect(() => {
    if (isExpanded && codeInput) {
      codeInput.focus();
    }
  }, [isExpanded]);

  return (
    <div className={cn('cart-discount-form', className)}>
      <div className="cart-discount-form__container">
        <Checkbox
          idPrefix="card-discount-form"
          onChange={toggleSeparateDeliveryAddress}
          {...toggleCheckbox}
          useFormsy={false}
        />
        <Collapse
          isOpen={isExpanded}
          duration={300}
          easing="cubic-bezier(0,0,1,1)"
          unmountClosed={false}
        >
          <Form
            endpoint={endpoint}
            showSubmitButton={false}
            onSubmit={onSubmit}
            onResponse={onUpdate}
          >
            <div className="cart-discount-form__wrapper">
              <div className="cart-discount-form__input-column">
                <TextInput
                  onRef={onInputRef}
                  isRequired={true}
                  validations={[]}
                  {...input}
                  className="cart-discount-form__input text-input--in-card-discount-form"
                />
              </div>
              <div className="cart-discount-form__button-column">
                <Button
                  className="cart-discount-form__button"
                  type="submit"
                  text={submitLabel}
                />
              </div>
            </div>
          </Form>
        </Collapse>
      </div>
    </div>
  );
};

CartDiscountForm.propTypes = {
  endpoint: PropTypes.string.isRequired,
  toggleCheckbox: PropTypes.exact(Checkbox.propTypes).isRequired,
  input: PropTypes.exact(TextInput.propTypes).isRequired,
  submitLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  className: PropTypes.string
};

CartDiscountForm.propTypesMeta = {
  className: 'exclude'
};

CartDiscountForm.defaultProps = {
  endpoint: ''
};

export default CartDiscountForm;
