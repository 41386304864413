import React from 'react';
import PropTypes from 'prop-types';

const EmailTemplateContent = ({
  expectedDeliveryDate,
  orderConfirmationLabel,
  orderNumber,
  orderDateLabel,
  orderDate,
  products,
  addressLabel,
  customerName,
  customerEmail,
  customerAddress,
  customerTelephoneNumber,
  quantityLabel,
  totalPriceLabel,
  unitPriceLabel
}) => (
  <div className="email-template-content">
    <p className="email-template-content-order-number">
      {orderConfirmationLabel}: <strong>{orderNumber}</strong>
    </p>
    <p className="email-template-content-order-date">
      {orderDateLabel}: <strong>{orderDate}</strong>
    </p>
    <div className="email-template-content-delivery">
      <p className="email-template-content-delivery-label">
        <strong>{addressLabel}</strong>
      </p>
      <p className="email-template-content-delivery-address">
        {customerAddress}
      </p>
      <p className="email-template-content-delivery-date">
        {expectedDeliveryDate}
      </p>
      <p className="email-template-content-delivery-name">{customerName}</p>
      <p className="email-template-content-delivery-phone">
        {customerTelephoneNumber}
      </p>
      <p className="email-template-content-delivery-mail">{customerEmail}</p>
    </div>
    <div className="email-template-product-list">
      <hr />
      {products.map(product => (
        <div className="email-template-product" key={product.id}>
          <img src={product.image.src} />
          <h2>{product.title}</h2>
          <p className="email-template-product-details">
            {product.details.map((detail, i) => (
              <React.Fragment key={detail.label}>
                {detail.label}: <span>{detail.data}</span>
                {product.details.length - 1 > i ? (
                  <React.Fragment>, </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </p>
          <div className="email-template-price">
            <p>
              {quantityLabel}: <span>{product.quantity}</span>
            </p>
            <table width="100%" cellSpacing="0" cellPadding="0">
              <tr>
                <td>
                  {unitPriceLabel}:
                  <span>{product.unitPrice.originalPrice}</span>
                </td>
                <td align="right">
                  {' '}
                  {totalPriceLabel}:{' '}
                  <strong>{product.price.originalPrice}</strong>
                </td>
              </tr>
            </table>
          </div>
          <hr />
        </div>
      ))}
    </div>
  </div>
);

EmailTemplateContent.defaultProps = {
  products: []
};

EmailTemplateContent.propTypes = {
  expectedDeliveryDate: PropTypes.string,
  orderConfirmationLabel: PropTypes.string,
  orderNumber: PropTypes.string,
  orderDateLabel: PropTypes.string,
  addressLabel: PropTypes.string,
  orderDate: PropTypes.string,
  customerName: PropTypes.string,
  customerAddress: PropTypes.string,
  customerEmail: PropTypes.string,
  customerTelephoneNumber: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, data: PropTypes.string })
      ),
      image: PropTypes.object,
      price: PropTypes.shape({
        originalPrice: PropTypes.string
      }),
      id: PropTypes.string,
      productUrl: PropTypes.string,
      quantity: PropTypes.number,
      title: PropTypes.string,
      unitPrice: PropTypes.shape({
        originalPrice: PropTypes.string
      })
    })
  ),
  quantityLabel: PropTypes.string,
  totalPriceLabel: PropTypes.string,
  unitPriceLabel: PropTypes.string
};

EmailTemplateContent.propTypesMeta = 'exclude';

export default EmailTemplateContent;
