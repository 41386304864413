import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import cn from 'classnames';

import googleMapsLoader from 'js/google-maps-loader';

import MapPopup from './map-popup';
import MapMarker from './map-marker';
import Link from '../link';
import Store from 'components/find-store/store';

class StoreMap extends React.Component {
  static propTypes = {
    googleMapsAPIKey: PropTypes.string,
    mapMarker: PropTypes.exact(MapMarker.propTypes),
    stores: PropTypes.arrayOf(PropTypes.exact(Store.propTypes)),
    mobileLink: PropTypes.exact(Link.propTypes),
    mobileViewIsLink: PropTypes.bool
  };

  static propTypesMeta = {
    mobileViewIsLink: 'exclude'
  };

  state = {};

  google = null;
  map = null;

  getBounds = stores => {
    const google = this.google;
    const bounds = new google.maps.LatLngBounds();

    //loop through all markers and create bounds
    if (stores && stores.length) {
      stores.forEach(store => {
        bounds.extend(new google.maps.LatLng(store.lat, store.lng));
      });
    }

    return bounds;
  };

  fitMapToStores = stores => {
    const bounds = this.getBounds(stores);
    // only 1 marker?
    if (stores.length === 1) {
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(16);
    } else {
      // fit to bounds
      this.map.fitBounds(bounds);
    }
  };

  componentDidMount() {
    googleMapsLoader(this.props.googleMapsAPIKey).then(google => {
      this.google = google;
      this.map = new google.maps.Map(this.wrapper);

      this.fitMapToStores(this.props.stores);

      const popupsAndMarkers = this.props.stores.map(store => {
        return {
          popup: new google.maps.InfoWindow({
            content: ReactDOMServer.renderToString(<MapPopup {...store} />)
          }),
          marker: new google.maps.Marker({
            position: { lat: store.lat, lng: store.lng },
            icon: {
              ...this.props.mapMarker,
              scaledSize: {
                ...this.props.mapMarker.size // we use the same width/height parameters in scaledSize
              }
            },
            title: store.name,
            map: this.map
          })
        };
      });

      popupsAndMarkers.forEach(({ popup, marker }) => {
        marker.addListener('click', () => {
          popupsAndMarkers.forEach(({ popup }) => popup.close());
          popup.open(this.map, marker);
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stores !== this.props.stores) {
      this.fitMapToStores(this.props.stores);
    }
  }

  render() {
    return (
      <div
        className={cn('store-map', {
          'store-map--mobile': this.props.mobileViewIsLink
        })}
      >
        <div className="store-map-map" ref={div => (this.wrapper = div)} />
        {this.props.mobileViewIsLink && this.props.mobileLink && (
          <div className="store-map-link">
            <Link
              {...this.props.mobileLink}
              themes={[Link.themes.buttonCyanSmall, Link.themes.wide]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default StoreMap;
