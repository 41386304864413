import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import useToggle from 'hooks/use-toggle';

import Icon from 'components/icon';
import OnPageEditing from 'components/on-page-editing';
import Text from 'components/text';
import Avatar from 'components/avatar';

const AppointmentBookingAssistantAccordion = props => {
  const [isExpanded, toggle] = useToggle(false);
  const [text, setText] = useState(props.defaultText);
  const [imagePath, setImagePath] = useState('');
  const [imageAlt, setImageAlt] = useState('');
  const [filled, setFilled] = useState(false);

  useEffect(() => {
    setText(props.defaultText);
    setImagePath('');
    setImageAlt('');
    setFilled(false);
  }, [props.assistants]);

  const onClickAssistant = (id, image, name) => {
    toggle();
    setText(`${props.label} ${name}`);
    setImagePath(image);
    setImageAlt(name);
    setFilled(true);
    props.onClickAssistant(id);
  };

  const onClickRemoveAssistant = event => {
    event.stopPropagation();
    setText(props.defaultText);
    setImagePath('');
    setImageAlt('');
    setFilled(false);
    props.onClickRemoveAssistant();
  };

  useEffect(() => {
    if (props.assistants.length > 0 && props.assistantId !== '') {
      const assistant = props.assistants.find(obj => {
        return obj.id === props.assistantId;
      });
      if (assistant) {
        setText(`${props.label} ${assistant.name}`);
        setImagePath(assistant.image);
        setImageAlt(assistant.name);
        setFilled(true);
      }
    } else if (props.assistants.length > 0 && props.preselectedOpticianId) {
      const assistant = props.assistants.find(obj => {
        return obj.id === props.preselectedOpticianId;
      });
      if (assistant) {
        setText(`${props.label} ${assistant.name}`);
        setImagePath(assistant.image);
        setImageAlt(assistant.name);
        setFilled(true);
      }
    }
  }, [props.assistants, props.preselectedOpticianId]);

  return (
    <div className="accordion appointment-booking-assistant-accordion">
      <button
        aria-controls={props.assistantId}
        aria-expanded={isExpanded}
        className={cn({ 'is-active': isExpanded, filled: filled })}
        onClick={toggle}
        type="button"
      >
        <div className="accordion-header appointment-booking-assistant-accordion__header">
          {filled && (
            <Avatar
              className="appointment-booking-assistant-accordion__header-image-holder"
              src={imagePath}
              alt={imageAlt}
            />
          )}
          <div className="appointment-booking-assistant-accordion__header-title">
            <OnPageEditing name={props.defaultText}>
              <Text className="accordion-header-text appointment-booking-assistant-accordion__header-text">
                {text}
              </Text>
            </OnPageEditing>
          </div>
          {!filled && (
            <div className="accordion-icon appointment-booking-assistant-accordion__header-icon accordion-icon appointment-booking-assistant-accordion__header-icon--right">
              <Icon name="chevron-down" />
            </div>
          )}
          {filled && (
            <div
              className="appointment-booking-assistant-accordion__cancel"
              onClick={onClickRemoveAssistant}
            >
              {props.cancelText}
            </div>
          )}
        </div>
      </button>

      <Collapse componentProps={{ id: props.assistantId }} isOpen={isExpanded}>
        <div className="appointment-booking-assistant-accordion__inner">
          {props.assistants.map(item => {
            return (
              <OnPageEditing name={item.name} key={item.id}>
                <div
                  className="accordion-content appointment-booking-assistant-accordion__content"
                  onClick={() => {
                    onClickAssistant(item.id, item.image, item.name);
                  }}
                >
                  <div className="appointment-booking-assistant-accordion__content-inner">
                    <Avatar
                      className="appointment-booking-assistant-accordion__image-holder"
                      src={item.image}
                      alt={item.name}
                    />
                    <span className="appointment-booking-assistant-accordion__name">
                      {item.name}
                    </span>
                  </div>
                </div>
              </OnPageEditing>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

AppointmentBookingAssistantAccordion.propTypes = {
  defaultText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClickAssistant: PropTypes.func,
  onClickRemoveAssistant: PropTypes.func,
  assistants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  assistantId: PropTypes.string,
  preselectedOpticianId: PropTypes.string
};

AppointmentBookingAssistantAccordion.propTypesMeta = {
  assistants: 'exclude',
  assistantId: 'exclude',
  preselectedOpticianId: 'exclude'
};

export default AppointmentBookingAssistantAccordion;
