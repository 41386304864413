import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';

const ProductText = ({ textHtml }) => (
  <RichText text={textHtml} className="product-text" isCentered={false} />
);
ProductText.propTypes = {
  textHtml: PropTypes.string
};

ProductText.propTypesMeta = 'exclude';

export default ProductText;
