import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FluidImage from 'components/fluid-image';
import Icon from 'components/icon';
import Text from 'components/text';
import MagazineCategory from 'components/magazine-category/magazine-category';

const ImageLink = ({ ctaText, ingress, image, text, url, category }) => {
  return (
    <div className="image-link">
      <a
        href={url}
        className="image-link-link"
        data-cta-title={text}
        data-cta-type="image-link"
      >
        <React.Fragment>
          <div className="image-link-image">
            <div className="image-link-image-inner">
              {category && <MagazineCategory text={category} />}
              <FluidImage {...image} />
            </div>
          </div>
          <div className="image-link-content">
            <Text.span
              className={cn('image-link-text', {
                'image-link-text--only': !ingress
              })}
              theme={Text.themes.uppercase}
            >
              {text}
              {!ingress && <Icon name="arrow-right" />}
            </Text.span>
            {ingress && (
              <Text.span
                className="image-link-ingress"
                theme={Text.themes.small}
              >
                {ingress}
              </Text.span>
            )}
            {ctaText && !!ingress && (
              <div className="image-link-cta">
                <span className="image-link-cta-text">{ctaText}</span>
                <span className="image-link-cta-icon">
                  <Icon name="arrow-right" />
                </span>
              </div>
            )}
          </div>
        </React.Fragment>
      </a>
    </div>
  );
};

ImageLink.propTypes = {
  ctaText: PropTypes.string,
  ingress: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes),
  text: PropTypes.string,
  url: PropTypes.string,
  category: PropTypes.string
};

ImageLink.propTypesMeta = {
  category: 'exclude'
};

export default ImageLink;
