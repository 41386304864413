import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingHint = props => {
  return (
    <div className="appointment-booking-hint">
      <span>{props.value}</span>
    </div>
  );
};

AppointmentBookingHint.propTypes = {
  value: PropTypes.string
};

AppointmentBookingHint.propTypesMeta = 'exclude';

export default AppointmentBookingHint;
