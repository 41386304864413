import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';
import Text from 'components/text';

const MyPageSidebarSection = ({ children, ingress, link, title }) => (
  <div className="my-page-sidebar-section">
    <Text.h2 theme={Text.themes.bold}>{title}</Text.h2>
    <Text.p theme={Text.themes.small}>{ingress}</Text.p>
    {children}
    {link && (
      <Text.div
        className="my-page-sidebar-section-button"
        theme={Text.themes.small}
      >
        <Link {...link} />
      </Text.div>
    )}
  </div>
);

MyPageSidebarSection.propTypes = {
  children: PropTypes.node,
  ingress: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string
};

export default MyPageSidebarSection;
