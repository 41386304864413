import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingProgress = props => {
  return (
    <progress
      className="appointment-booking-progress"
      max="100"
      value={props.progress}
    />
  );
};

AppointmentBookingProgress.propTypes = {
  progress: PropTypes.string
};

AppointmentBookingProgress.propTypesMeta = 'exclude';

export default AppointmentBookingProgress;
