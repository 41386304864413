import React from 'react';

import LoginForm from '../login-form';

const LoginPage = props => (
  <div className="login-page">
    <LoginForm theme={LoginForm.themes.maxi} {...props} />
  </div>
);

LoginPage.propTypes = LoginForm.propTypes;

export default LoginPage;
