/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from '../link';
import RichText from 'components/rich-text';
import Store from 'components/find-store/store';
import Button from 'components/button';
import ReserveInStoreForm from './reserve-in-store-form';
import cn from 'classnames';

const ReserveStoresListItem = ({
  storeLabels,
  reservetionErrorMessageText,
  reservetionConfirmationText,
  reserveInStoreButtonText,
  name,
  address,
  openingHours,
  phone,
  email,
  productInStock,
  reserveForm,
  posId,
  shopId
}) => {
  const [formIsShown, setFormIsShown] = useState(false);
  const [buttonIsShown, setButtonIsShown] = useState(true);
  const [reservetionText, setReservetionText] = useState(storeLabels.inStock);
  const [showGreenIcon, setShowGreenIcon] = useState(productInStock);

  const itemRef = useRef();

  const onReservetionComplete = res => {
    if (res.success === false) {
      setShowGreenIcon(false);
      setReservetionText(reservetionErrorMessageText);
      itemRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setReservetionText(reservetionConfirmationText);
      setShowGreenIcon(true);
      setFormIsShown(false);
    }
  };

  const onButtonClick = () => {
    setFormIsShown(true);
    setButtonIsShown(false);
  };

  return (
    <div className="reserve-stores-list__item" ref={itemRef}>
      {name && <h4 className="reserve-stores-list__item-heading">{name}</h4>}
      <div className="reserve-stores-list__content">
        <div className="reserve-stores-list__store-data">
          <table className="reserve-stores-list__store-data-table">
            <tbody>
              <tr className="reserve-stores-list__store-data-row">
                <th className="reserve-stores-list__store-term">
                  {storeLabels.address}
                </th>
                <td className="reserve-stores-list__store-description">
                  {address}
                </td>
              </tr>
              <tr className="reserve-stores-list__store-data-row">
                <th className="reserve-stores-list__store-term">
                  {storeLabels.hours}
                </th>
                <td className="reserve-stores-list__store-description">
                  <RichText
                    element="div"
                    className="reserve-stores-list__store-hours"
                    {...openingHours}
                  />
                </td>
              </tr>
              <tr className="reserve-stores-list__store-data-row">
                <th className="reserve-stores-list__store-term">
                  {storeLabels.phone}
                </th>
                <td className="reserve-stores-list__store-description">
                  <Link text={phone} url={`tel:${phone}`} />
                </td>
              </tr>
              <tr className="reserve-stores-list__store-data-row">
                <th className="reserve-stores-list__store-term">
                  {storeLabels.email}
                </th>
                <td className="reserve-stores-list__store-description">
                  <Link text={email} url={`mailto:${email}`} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="reserve-stores-list__status-container">
          <div className="reserve-stores-list__status">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              className="reserve-stores-list__status-icon"
            >
              <circle
                fill={showGreenIcon ? '#136E3E' : '#dd5225'}
                cx="15"
                cy="15"
                r="15"
              />
              {showGreenIcon ? (
                <path
                  d="M7.5,15.5l6,5,9-11"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                />
              ) : (
                <path
                  d="M20.76,7.83l-5.76,5.76-5.76-5.76-1.41,1.41,5.76,5.76-5.76,5.76,1.41,1.41,5.76-5.76,5.76,5.76,1.41-1.41-5.76-5.76,5.76-5.76-1.41-1.41Z"
                  fill="#fff"
                />
              )}
            </svg>
            <span className="reserve-stores-list__status-text">
              {productInStock ? reservetionText : storeLabels.outOfStock}
            </span>
          </div>
          {productInStock && (
            <>
              <div
                className={cn('reserve-stores-list__reserve-form', {
                  'reserve-stores-list__reserve-form--is-shown': formIsShown
                })}
              >
                <ReserveInStoreForm
                  {...reserveForm}
                  onResponse={onReservetionComplete}
                  posId={posId}
                  shopId={shopId}
                />
              </div>
              {buttonIsShown && (
                <Button
                  className="reserve-stores-list__reserve-button"
                  type="button"
                  onClick={onButtonClick}
                  text={reserveInStoreButtonText}
                  themes={[Button.themes.buttonCyan]}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ReserveStoresListItem.propTypes = Object.assign({}, Store.propTypes, {
  storeLabels: PropTypes.shape({
    address: PropTypes.string,
    hours: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    inStock: PropTypes.string,
    outOfStock: PropTypes.string
  }),
  reservetionErrorMessageText: PropTypes.string,
  reservetionConfirmationText: PropTypes.string,
  reserveInStoreButtonText: PropTypes.string,
  reserveForm: PropTypes.exact(ReserveInStoreForm.propTypes),
  posId: PropTypes.string,
  shopId: PropTypes.string
});

ReserveStoresListItem.propTypesMeta = 'exclude';

ReserveStoresListItem.defaultProps = {
  stores: [],
  storeLabels: {}
};

export default ReserveStoresListItem;
