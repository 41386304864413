import React from 'react';
import PropTypes from 'prop-types';

import ComponentList from 'components/component-list';
import ContentContainer from 'components/content-container';
import Link from 'components/link';
import PageHeader from 'components/page-header';
import RichText from 'components/rich-text';

const BrandPage = ({ blocks, header, ingress, links, text }) => {
  return (
    <React.Fragment>
      {header && <PageHeader {...header} />}

      <ContentContainer>
        <div className="brand-page-text-content">
          {ingress && (
            <RichText
              className="brand-page-ingress"
              theme={RichText.themes.big}
              {...ingress}
            />
          )}
          {text && <RichText className="brand-page-text" {...text} />}
          {links.length > 0 && (
            <ul className="brand-page-links">
              {links.map(link => (
                <li key={link.text}>
                  <Link
                    themes={[Link.themes.arrowRight, Link.themes.uppercase]}
                    {...link}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </ContentContainer>

      <ContentContainer className="brand-page-blocks">
        {blocks && <ComponentList {...blocks} />}
      </ContentContainer>
    </React.Fragment>
  );
};

BrandPage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes),
  header: PropTypes.exact(PageHeader.propTypes),
  ingress: PropTypes.exact(RichText.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  text: PropTypes.exact(RichText.propTypes)
};

BrandPage.defaultProps = {
  links: []
};

export default BrandPage;
