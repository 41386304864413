import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentContainer from 'components/content-container';
import FluidImage from 'components/image';
import OnPageEditing from 'components/on-page-editing';
import MainTitle from 'components/main-title';
import RichText from 'components/rich-text';
import Text from 'components/text';
import Video from 'components/video';

const PageHeader = ({
  image,
  imageCaption,
  OPE,
  preTitle,
  text,
  mediaShouldFillWidth,
  title,
  video
}) => {
  return (
    <div
      className={cn('page-header', {
        'page-header--full-width-media': mediaShouldFillWidth
      })}
    >
      <ContentContainer className="page-header-content">
        <OnPageEditing name={OPE.title}>
          <MainTitle preTitle={preTitle} title={title} />
        </OnPageEditing>

        <div className="page-header-media">
          {video ? (
            <div className="page-header-image">
              <Video {...video} />
            </div>
          ) : (
            <OnPageEditing name={OPE.image}>
              {image && (
                <div className="page-header-image">
                  <FluidImage {...image} />
                </div>
              )}
            </OnPageEditing>
          )}

          {imageCaption && (
            <Text
              className="page-header-image-caption"
              theme={Text.themes.extraSmall}
            >
              {imageCaption}
            </Text>
          )}
        </div>

        <OnPageEditing name={OPE.text}>
          {text && (
            <div className="page-header-text">
              {text && <RichText text={text} />}
            </div>
          )}
        </OnPageEditing>
      </ContentContainer>
    </div>
  );
};

PageHeader.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  imageCaption: PropTypes.string,
  mediaShouldFillWidth: PropTypes.bool,
  OPE: PropTypes.exact({
    image: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  }),
  preTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  video: PropTypes.exact(Video.propTypes)
};

PageHeader.defaultProps = {
  OPE: {},
  mediaShouldFillWidth: false
};

export default PageHeader;
