import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import apiHelper from 'js/api-helper';
import userAddressHandler from 'js/user-address-handler';
import findNearestShops from 'js/find-nearest-shops';
import AppointmentBookingTitle from 'components/appointment-booking-modal/appointment-booking-title';
import AppointmentBookingStepBackButton from 'components/appointment-booking-modal/appointment-booking-step-back-button';
import AppointmentBookingSubtitle from 'components/appointment-booking-modal/appointment-booking-subtitle';
import AppointmentBookingHint from 'components/appointment-booking-modal/appointment-booking-hint';
import AppointmentBookingRegionList from 'components/appointment-booking-modal/appointment-booking-region-list';
import AppointmentBookingShopList from 'components/appointment-booking-modal/appointment-booking-shop-list';
import AppointmentBookingProgress from 'components/appointment-booking-modal/appointment-booking-progress';
import AppointmentBookingResultList from 'components/appointment-booking-modal/appointment-booking-result-list';
import AppointmentBookingServiceList from 'components/appointment-booking-modal/appointment-booking-service-list';
import AppointmentBookingSeeNearest from 'components/appointment-booking-modal/appointment-booking-see-nearest';
import AppointmentBookingAvailability from 'components/appointment-booking-modal/appointment-booking-availability';
import AppointmentBookingForm from 'components/appointment-booking-modal/appointment-booking-form';
import Form from 'components/form/form';
import TextInput from 'components/text-input/text-input';
import AppointmentBookingAgreeBox from 'components/appointment-booking-modal/appointment-booking-agree-box';
import AppointmentBookingFooter from 'components/appointment-booking-modal/appointment-booking-footer';
import Image from 'components/image/image';
import logo from '../../assets/logo.png';
import PageSpinner from '../page-spinner';

const AppointmentBooking = props => {
  const [regionsState, setRegionsState] = useState([]);
  const [regionNameState, setRegionNameState] = useState('');
  const [shopsState, setShopsState] = useState([]);
  const [userCoords, setUserCoords] = useState({});
  const [shop, setShop] = useState({});
  const [shopId, setShopId] = useState('');
  const [step, setStep] = useState(0);
  const [appointmentGroupList, setAppointmentGroupList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [dateListAllOpticians, setDateListAllOpticians] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [appointmentId, setAppointmentId] = useState('');
  const [progress, setProgress] = useState('20');
  const [activeDate, setActiveDate] = useState('');
  const [activeWeekday, setActiveWeekday] = useState('');
  const [activeMonth, setActiveMonth] = useState('');
  const [assistantList, setAssistantList] = useState([]);
  const [assistant, setAssistant] = useState({});
  const [assistantId, setAssistantId] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeListTitle, setTimeListTitle] = useState('');
  const [timeSlot, setTimeSlot] = useState('');
  const [agreeStatus, setAgreeStatus] = useState(false);
  const [agreeText, setAgreeText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [reservedBookingId, setReservedBookingId] = useState('');
  const showSeeNearest = step === 1;
  const showSubtitle = props.subtitles[step];
  const showHint = props.hints[step];
  const showRegions = regionsState
    ? step < 1 && regionsState.length > 0
    : false;
  const showShops = step === 1;
  const showServiceList = step === 2;
  const useBottomPadding = step < 2;
  const showAvailability = step === 3 && activeDate;
  const showForm = step === 4;
  const showFooter = step === 5;

  const updateStep = newStep => {
    setStep(newStep);
    window.history.pushState({ step: newStep }, null);
  };

  const setAppointments = services => {
    setAppointmentGroupList(services);
    if (!appointmentList.length) {
      let appointments = [];
      services.map(group => {
        appointments = appointments.concat(group.appointments);
      });
      setAppointmentList(appointments);
    }
  };

  const getAppointments = useCallback(id => {
    if (id)
      apiHelper
        .get(props.getAppointmentsEndpoint + '?shopId=' + id)
        .then(response => {
          response.services && setAppointments(response.services);
        });
  });

  const setAvailability = (dates, assistants) => {
    setDateList(dates);
    setDateListAllOpticians(dates);
    setAssistantList(assistants);
  };

  const getAvailability = useCallback(id => {
    if (id) {
      setLoading(true);

      apiHelper
        .get(
          props.getAvailabilityEndpoint +
            '?shopId=' +
            shopId +
            '&appointmentTypeCode=' +
            id
        )
        .then(response => {
          setAvailability([], []);
          setAvailability(response.dates, response.assistants);
        })
        .finally(() => setLoading(false));
    }
  });

  const onClickRegion = useCallback(
    regionIndex => {
      setRegionNameState(regionsState[regionIndex].name);
      const shopsArr = [...regionsState[regionIndex].shops];
      setShopsState(shopsArr);
      updateStep(step + 1);
    },
    [regionsState]
  );

  const onClickShop = useCallback(
    shopIndex => {
      getAppointments(shopsState[shopIndex].id);
      setShop(shopsState[shopIndex]);
      setShopId(shopsState[shopIndex].id);
      updateStep(step + 1);
    },
    [shopsState]
  );

  const onClickGoToStep = useCallback(
    newStep => {
      // if not final step
      if (step !== 5) updateStep(newStep);
      // when we go back from step 3 we need to clear assistant
      if (step === 3 && newStep < 3) setAssistantId('');
    },
    [step]
  );

  const onClickService = useCallback(
    serviceId => {
      getAvailability(serviceId);
      setAppointment(
        appointmentList.find(obj => {
          return obj.appointmentId === serviceId;
        })
      );
      setAppointmentId(serviceId);
      updateStep(step + 1);
    },
    [appointmentList, shopId]
  );

  const seeNearestShops = () => {
    const limit = 25;
    const nearestShopsNum = shopsState.length;
    findNearestShops(
      userCoords,
      shopsState,
      limit,
      nearestShopsNum,
      props.googleMapsApiKey
    ).then(shopsResult => {
      setShopsState(shopsResult);
    });
  };

  const onClickSeeNearestShops = () => {
    userAddressHandler.handle(
      props.userAddress,
      props.googleMapsApiKey,
      true,
      function(coords) {
        setUserCoords(coords);
      }
    );
  };

  const firstDateWithTimeSlots =
    dateList &&
    dateList.length > 0 &&
    dateList.find(date => date.timeSlots.length > 0);

  const setCheckedDateParams = checkedDate => {
    setActiveDate(checkedDate.date);
    setActiveWeekday(checkedDate.weekday);
    setActiveMonth(checkedDate.month);
    setTimeSlots(checkedDate.timeSlots);
    setTimeListTitle(checkedDate.timeListTitle);
  };

  const onClickDate = useCallback(
    date => {
      const checkedDate = dateList.find(obj => {
        return obj.date === date;
      });
      setCheckedDateParams(checkedDate);
    },
    [dateList, activeDate]
  );

  const closestAbsDate = (dates, to_find) =>
    dates.reduce(({ absDate }, { absDate: a }) =>
      Math.abs(to_find - a) < Math.abs(to_find - absDate)
        ? { absDate: a }
        : { absDate }
    );

  const onClickAssistant = useCallback(
    id => {
      const assistant = assistantList.find(obj => {
        return obj.id === id;
      });
      setAssistant(assistant);
      setAssistantId(id);
      setDateList(assistant.dates);
      const datesWithTimeSlots = assistant.dates.filter(obj => {
        if (obj.timeSlots && obj.timeSlots.length > 0)
          // set abs date number for future calculations
          return (obj.absDate = Math.abs(new Date(obj.date)));
      });
      // get abs number of checked date
      const checkedDateAbs = Math.abs(new Date(activeDate));
      // get abs number of closest date
      const closestDateAbsWithTimeSlots = closestAbsDate(
        datesWithTimeSlots,
        checkedDateAbs
      );
      // find closest date by abs number of closest date
      const closestDate = datesWithTimeSlots.find(obj => {
        return obj.absDate === closestDateAbsWithTimeSlots.absDate;
      });
      setCheckedDateParams(closestDate);
    },
    [assistantList, activeDate]
  );

  const onClickRemoveAssistant = useCallback(() => {
    setDateList(dateListAllOpticians);
    const checkedDate = dateListAllOpticians.find(obj => {
      return obj.date === activeDate;
    });
    setAssistant({});
    setAssistantId('');
    setTimeSlots(checkedDate.timeSlots);
    setTimeListTitle(checkedDate.timeListTitle);
  });

  const setAssistantChosenOnBE = id => {
    const assistant = assistantList.find(obj => {
      return obj.id === id;
    });
    setAssistant(assistant);
    setAssistantId(id);
  };

  const setReservedBookingIdFromFailedForm = id => {
    setReservedBookingId(id);
  };

  const onClickTime = useCallback(
    time => {
      setTimeSlot(time);
      updateStep(step + 1);
    },
    [timeSlots]
  );

  const onClickAgree = useCallback((status, text) => {
    setAgreeStatus(status);
    setAgreeText(text);
  });

  const onComplete = () => {
    updateStep(step + 1);
  };

  const onClickBackToPrevStep = () => {
    updateStep(step - 1);
  };

  const findShopAndRegionByShopId = (id, regions) => {
    for (let i = 0; i < regions.length; i++) {
      const regionItem = regions[i];
      const shops = regionItem.shops;
      for (let z = 0; z < shops.length; z++) {
        const shopItem = shops[z];
        if (shopItem.id === id) {
          const obj = {};
          obj.shop = shopItem;
          obj.shops = shops;
          obj.regionName = regionItem.name;
          return obj;
        }
      }
    }
  };

  const setPreselectedShop = id => {
    // contains region name and shops info
    const data = findShopAndRegionByShopId(id, regionsState);
    setRegionNameState(data.regionName);
    const shopsArr = [...data.shops];
    setShopsState(shopsArr);
    getAppointments(id);
    setShop(data.shop);
    setShopId(id);
    updateStep(2);
  };

  const setPreselectedAppointmentId = id => {
    getAvailability(id);
    setAppointment(
      appointmentList.find(obj => {
        return obj.appointmentId === id;
      })
    );
    setAppointmentId(id);
    updateStep(3);
  };

  const setPreselectedOpticianId = id => {
    const assistant = assistantList.find(obj => {
      return obj.id === id;
    });
    if (assistant) {
      setAssistant(assistant);
      setAssistantId(id);
      setDateList(assistant.dates);
      const checkedDate = assistant.dates.find(obj => {
        return obj.timeSlots && obj.timeSlots.length > 0;
      });
      setCheckedDateParams(checkedDate);
    }
  };

  useEffect(() => {
    apiHelper.get(props.getRegionsEndpoint).then(response => {
      setRegionsState(response.regions);
    });
  }, []);

  const handlePopstate = event => {
    const newStep =
      event.state &&
      event.state.step &&
      event.state.step !== undefined &&
      event.state.step !== null
        ? event.state.step
        : 0;
    setStep(newStep);
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    return () => window.removeEventListener('popstate', handlePopstate);
  }, []);

  useEffect(() => {
    // in case when we do not have any data we return null
    // in this case find-nearest-shops will sort shops in alph order
    // otherwise we always have lat and long
    if (
      userCoords === null ||
      (userCoords && userCoords.latitude && userCoords.longitude)
    )
      seeNearestShops();
  }, [userCoords]);

  useEffect(() => {
    if (regionsState && regionsState.length > 0 && props.preselectedShopId) {
      setPreselectedShop(props.preselectedShopId);
    }
  }, [regionsState]);

  useEffect(() => {
    if (appointmentList.length > 0 && props.preselectedAppointmentId) {
      setPreselectedAppointmentId(props.preselectedAppointmentId);
    }
  }, [appointmentList]);

  useEffect(() => {
    if (
      assistantList &&
      assistantList.length > 0 &&
      props.preselectedOpticianId
    ) {
      setPreselectedOpticianId(props.preselectedOpticianId);
    }
  }, [assistantList, props.preselectedOpticianId]);

  useEffect(() => {
    // runs when component changed
    if (step === 0) setProgress('20');
    if (step === 1) setProgress('35');
    if (step === 2) setProgress('60');
    if (step === 3) setProgress('80');
    if (step === 4) setProgress('95');
    if (step === 5) setProgress('100');
    if (!activeDate && firstDateWithTimeSlots) {
      // set date params after date list response
      setCheckedDateParams(firstDateWithTimeSlots);
    }
  });

  return (
    <div
      className={cn('appointment-booking', {
        'appointment-booking--bottom-padding': useBottomPadding,
        'step-0': step === 0,
        'step-1': step === 1,
        'step-2': step === 2,
        'step-3': step === 3,
        'step-4': step === 4,
        'step-5': step === 5
      })}
    >
      <PageSpinner shouldContainInParent={true} isLoading={isLoading} />
      <div className="appointment-booking__top-decoration"></div>
      {showSeeNearest && (
        <AppointmentBookingSeeNearest
          text={props.seeNearestText}
          onClickSeeNearestShops={onClickSeeNearestShops}
        />
      )}
      {step !== 0 && (
        <AppointmentBookingStepBackButton
          onClick={onClickBackToPrevStep}
          text={props.stepBackButtonText}
        />
      )}
      <Image
        src={logo}
        className="appointment-booking__logo"
        alt="Interoptik logo"
      />
      <AppointmentBookingProgress progress={progress} />
      <AppointmentBookingTitle value={props.titles[step]} />
      {showSubtitle && (
        <AppointmentBookingSubtitle value={props.subtitles[step]} />
      )}
      {showHint && <AppointmentBookingHint value={props.hints[step]} />}
      <AppointmentBookingResultList
        step={step}
        region={regionNameState}
        shop={shop}
        appointment={appointment}
        timeText={props.timeText}
        priceText={props.priceText}
        paymentTypeText={props.paymentTypeText}
        assistant={assistant}
        assistantId={assistantId}
        assistantText={props.assistantText}
        activeDate={activeDate}
        activeWeekday={activeWeekday}
        activeMonth={activeMonth}
        preposition={props.preposition}
        timeSlot={timeSlot}
        agreeStatus={agreeStatus}
        agreeText={agreeText}
        onClickGoToStep={onClickGoToStep}
      />
      {showRegions && (
        <AppointmentBookingRegionList
          regions={regionsState}
          onClickRegion={onClickRegion}
        />
      )}
      {showShops && (
        <>
          <AppointmentBookingShopList
            shops={shopsState}
            onClickShop={onClickShop}
          />
        </>
      )}
      {showServiceList && (
        <AppointmentBookingServiceList
          services={appointmentGroupList}
          timeText={props.timeText}
          priceText={props.priceText}
          onClickService={onClickService}
        />
      )}
      {showAvailability && (
        <AppointmentBookingAvailability
          assistantDefaultText={props.assistantDefaultText}
          assistantCancelText={props.assistantCancelText}
          assistantLabel={props.assistantLabel}
          assistants={assistantList}
          assistantId={assistantId}
          onClickAssistant={onClickAssistant}
          onClickRemoveAssistant={onClickRemoveAssistant}
          dates={dateList}
          activeDate={activeDate}
          currentWeekDay={props.currentWeekDay}
          carouselTitle={props.carouselTitle}
          carouselPreviousItemText={props.carouselPreviousItemText}
          carouselNextItemText={props.carouselNextItemText}
          onClickDate={onClickDate}
          timeListTitle={timeListTitle}
          timeSlots={timeSlots}
          onClickTime={onClickTime}
          preselectedOpticianId={props.preselectedOpticianId}
        />
      )}
      {showForm && (
        <AppointmentBookingForm
          form={props.form}
          conditionalTextAppointmentType={props.conditionalTextAppointmentType}
          conditionalBookingUserNameText={props.conditionalBookingUserNameText}
          appointmentBookingUserName={props.appointmentBookingUserName}
          appointmentBookingBirthDate={props.appointmentBookingBirthDate}
          appointmentBookingEmail={props.appointmentBookingEmail}
          appointmentBookingPhoneNumber={props.appointmentBookingPhoneNumber}
          appointmentBookingAttentionText={
            props.appointmentBookingAttentionText
          }
          appointmentBookingAddInfo={props.appointmentBookingAddInfo}
          onComplete={onComplete}
          shopId={shopId}
          appointmentId={appointmentId}
          assistantId={assistantId}
          activeDate={activeDate}
          timeSlot={timeSlot}
          agreeBoxData={props.agreeBoxData}
          onClickAgree={onClickAgree}
          setAssistantChosenOnBE={setAssistantChosenOnBE}
          setReservedBooking={setReservedBookingIdFromFailedForm}
          reservedBookingId={reservedBookingId}
        />
      )}
      {showFooter && <AppointmentBookingFooter {...props.footerData} />}
    </div>
  );
};

AppointmentBooking.propTypes = {
  getRegionsEndpoint: PropTypes.string,
  getAppointmentsEndpoint: PropTypes.string,
  getAvailabilityEndpoint: PropTypes.string,
  googleMapsApiKey: PropTypes.string,
  form: PropTypes.exact(Form.propTypes),
  conditionalTextAppointmentType: PropTypes.string,
  conditionalBookingUserNameText: PropTypes.string,
  appointmentBookingUserName: PropTypes.exact(TextInput.propTypes),
  appointmentBookingBirthDate: PropTypes.exact(TextInput.propTypes),
  appointmentBookingEmail: PropTypes.exact(TextInput.propTypes),
  appointmentBookingPhoneNumber: PropTypes.exact(TextInput.propTypes),
  appointmentBookingAttentionText: PropTypes.string,
  appointmentBookingAddInfo: PropTypes.exact(TextInput.propTypes),
  agreeBoxData: PropTypes.exact(AppointmentBookingAgreeBox.propTypes),
  footerData: PropTypes.shape({
    text: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string
  }),
  titles: PropTypes.arrayOf(PropTypes.string),
  subtitles: PropTypes.arrayOf(PropTypes.string),
  hints: PropTypes.arrayOf(PropTypes.string),
  seeNearestText: PropTypes.string,
  assistantText: PropTypes.string,
  assistantDefaultText: PropTypes.string,
  assistantCancelText: PropTypes.string,
  assistantLabel: PropTypes.string,
  timeText: PropTypes.string,
  priceText: PropTypes.string,
  paymentTypeText: PropTypes.string,
  currentWeekDay: PropTypes.string,
  carouselTitle: PropTypes.string,
  carouselPreviousItemText: PropTypes.string,
  carouselNextItemText: PropTypes.string,
  preposition: PropTypes.string,
  preselectedShopId: PropTypes.string,
  preselectedAppointmentId: PropTypes.string,
  preselectedOpticianId: PropTypes.string,
  stepBackButtonText: PropTypes.string,
  userAddress: PropTypes.shape({
    endpoint: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  })
};

AppointmentBooking.propTypesMeta = {
  conditionalTextAppointmentType: 'exclude',
  conditionalBookingUserNameText: 'exclude'
};

export default AppointmentBooking;
