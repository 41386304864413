import React from 'react';
import FluidImage from 'components/fluid-image';
import ProductCard from 'components/product-card';
import PropTypes from 'prop-types';
import cn from 'classnames';

const formats = {
  squareSmall: 'square-small',
  square: 'square-big',
  tall: 'tall', //not supported for the product
  rectangle: 'rectangle'
};

const MoodboardBlockItem = ({
  text,
  format = formats.squareSmall,
  image,
  product
}) => {
  return (
    <div
      className={cn('moodboard-block__item', {
        [`moodboard-block__item--format-${format}`]: format,
        [`moodboard-block__item--product`]: product
      })}
    >
      <div className="moodboard-block__item-spacer"></div>
      {product ? (
        <ProductCard {...product} theme={ProductCard.themes.moodboard} />
      ) : (
        <>
          {image && (
            <FluidImage {...image} className="moodboard-block__item-image" />
          )}
          {text && <div className="moodboard-block__item-text">{text}</div>}
        </>
      )}
    </div>
  );
};

MoodboardBlockItem.propTypes = {
  text: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes),
  format: PropTypes.oneOf(Object.values(formats)).isRequired,
  product: PropTypes.exact(ProductCard.propTypes)
};

export default MoodboardBlockItem;
