import googleMapsLoader from './google-maps-loader';
import googleMapsHelper from './google-maps-helper';

const findNearestShops = (
  originCoords,
  shopsData,
  limit,
  nearestShopsNum,
  APIKey
) => {
  // Google Maps DistanceMatrixService has string length limitation
  // we can't send more than 25 shops in one time
  // as data converted to string goes out of that limit
  // the lower limit property the more safe request we will get
  // for safe request we split our data on smaller parts
  // and use multiple requests

  return new Promise(resolve => {
    if (originCoords === null) {
      let shopsSortedInAlphOrder = [];

      (function sortShopsInAlphOrder() {
        shopsSortedInAlphOrder = shopsData.sort((a, b) => {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      })();

      resolve(shopsSortedInAlphOrder);
      return;
    }

    googleMapsLoader(APIKey).then(google => {
      const newOrigin = googleMapsHelper.prepareLatLongFormatObj(originCoords);
      const origin = new google.maps.LatLng(
        newOrigin.latitude,
        newOrigin.longitude
      );
      const travelMode = 'DRIVING';
      const avoidHighways = false;
      const avoidTolls = false;
      const shops = googleMapsHelper.prepareLatLongFormatArr(shopsData);
      let arrOfShopsArr = [];
      let arrOfDestinationsArr = [];
      let arrOfPromises = [];
      let arrOfShopsWithDistance = [];
      let shopsSortedByDistance = [];
      let nearestShops = [];
      let shopsSortedInAlphOrder = [];
      let shopsResult = [];

      (function splitShopsIfOutOfLimit() {
        if (shops.length <= limit) {
          arrOfShopsArr.push(shops);
        } else {
          while (shops.length) {
            const newArr = shops.splice(0, limit);
            arrOfShopsArr.push(newArr);
          }
        }
      })();

      (function setDestinations() {
        for (let shopsArr of arrOfShopsArr) {
          const destinations = [];
          shopsArr.forEach(shop => {
            const destination = new google.maps.LatLng(
              shop.latitude,
              shop.longitude
            );
            destinations.push(destination);
          });
          arrOfDestinationsArr.push(destinations);
        }
      })();

      const service = new google.maps.DistanceMatrixService();

      (function setPromises() {
        for (let i = 0; i < arrOfShopsArr.length; i++) {
          const places = arrOfShopsArr[i];
          const destinations = arrOfDestinationsArr[i];
          const newPromise = googleMapsHelper.getDistance(
            service.getDistanceMatrix,
            [origin],
            destinations,
            travelMode,
            avoidHighways,
            avoidTolls,
            places
          );
          arrOfPromises.push(newPromise);
        }
      })();

      Promise.all(arrOfPromises).then(arrOfShopsArrWithDistance => {
        for (let shopsArrWithDistance of arrOfShopsArrWithDistance) {
          arrOfShopsWithDistance = arrOfShopsWithDistance.concat(
            shopsArrWithDistance
          );
        }

        (function sortShopsByDistance() {
          shopsSortedByDistance = arrOfShopsWithDistance.sort((a, b) => {
            if (a.value === '990') return 1;
            else if (b.value === '990') return -1;

            return a.distance > b.distance ? 1 : -1;
          });
        })();

        (function setNearestShops() {
          nearestShops = shopsSortedByDistance.slice(0, nearestShopsNum);
        })();

        (function sortShopsInAlphOrder() {
          shopsSortedInAlphOrder = shopsSortedByDistance
            .slice(nearestShopsNum)
            .sort((a, b) => {
              if (a.value === '990') return 1;
              else if (b.value === '990') return -1;

              let textA = a.name.toUpperCase();
              let textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        })();

        shopsResult = nearestShops.concat(shopsSortedInAlphOrder);

        resolve(shopsResult);
      });
    });
  });
};

export default findNearestShops;
