import React from 'react';

import PersonalInformationPage from 'components/personal-information-page';

const EditProfilePage = props => (
  <PersonalInformationPage
    showDeliveryAddress={true}
    showDeliveryAddressCheckbox={false}
    {...props}
  />
);

EditProfilePage.propTypes = PersonalInformationPage.propTypes;

export default EditProfilePage;
