import React from 'react';

const Context = React.createContext({
  isLoading: false,
  setIsLoading: () => {},
  setHasIframeHTML: () => {},
  hasIframeHTML: false
});

export default Context;
