import PropTypes from 'prop-types';

import RichText from 'components/rich-text';

// NOTE: This propType definition is shared between several components, including `find-store.jsx`, `store-list.jsx` and `store-map.jsx` in order to not duplicate a lot of data.
const Store = {};
Store.propTypes = {
  name: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  address: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.string,
  postPlace: PropTypes.string,
  postCode: PropTypes.string,
  county: PropTypes.string,
  openingHours: PropTypes.exact(RichText.propTypes),
  shopId: PropTypes.string,
  productInStock: PropTypes.bool
};

Store.propTypesMeta = {
  lat: 'float',
  lng: 'float'
};

export default Store;
