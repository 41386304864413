const prepareLatLongFormatArr = arr => {
  const newArr = [];
  for (let obj of arr) {
    let newObj = { ...obj };
    newObj.latitude = obj.latitude.replace(/,/g, '.');
    newObj.longitude = obj.longitude.replace(/,/g, '.');
    newArr.push(newObj);
  }
  return newArr;
};

const prepareLatLongFormatObj = obj => {
  return {
    latitude: `${obj.latitude}`.replace(/,/g, '.'),
    longitude: `${obj.longitude}`.replace(/,/g, '.')
  };
};

const getDistance = (
  service,
  origins,
  destinations,
  travelMode,
  avoidHighways,
  avoidTolls,
  places
) => {
  return new Promise(resolve => {
    service(
      {
        origins: origins,
        destinations: destinations,
        travelMode: travelMode,
        avoidHighways: avoidHighways,
        avoidTolls: avoidTolls
      },
      callback
    );

    function callback(response, status) {
      if (status === 'OK') {
        const result = response.rows[0].elements;
        places.forEach((place, index) => {
          if (result[index]) {
            place.distance = result[index].distance.value;
          }
        });
        resolve(places);
      }
    }
  });
};

export default {
  prepareLatLongFormatArr,
  prepareLatLongFormatObj,
  getDistance
};
