import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useBreakpoints from 'hooks/use-breakpoints';
import ModalContext from '../../context/modal';
import AppointmentBooking from 'components/appointment-booking-modal/appointment-booking';
import Modal from 'components/modal';
import Collapse from 'react-tiny-collapse';
import ContentContainer from 'components/content-container';
import Form from 'components/form/form';
import TextInput from 'components/text-input/text-input';
import AppointmentBookingAgreeBox from 'components/appointment-booking-modal/appointment-booking-agree-box';
import AppointmentBookingFooter from 'components/appointment-booking-modal/appointment-booking-footer';

const AppointmentBookingModal = props => {
  const { isTablet } = useBreakpoints(true, true);
  const ModalContentWrapper = isTablet ? 'div' : ContentContainer;
  const modalContext = useContext(ModalContext);
  const appBookId = 'appointment-booking-modal';

  useEffect(() => {
    if (props.openOnPageLoad && !modalContext.isAppointmentBookingModalOpen) {
      modalContext.toggleAppointmentBookingModal();
    }
  }, []);

  return (
    <Modal
      animate={false}
      className="appointment-booking-modal"
      containerClassName="appointment-booking-modal__inner"
      contentClassName="appointment-booking-modal__content"
      id={appBookId}
      isVisible={modalContext.isAppointmentBookingModalOpen}
      hide={modalContext.closeModals}
      hideCloseText={false}
    >
      {modalContext.isAppointmentBookingModalOpen && (
        <Collapse
          unmountChildren={true}
          isOpen={modalContext.isAppointmentBookingModalOpen}
        >
          <ModalContentWrapper>
            <AppointmentBooking
              getRegionsEndpoint={props.getRegionsEndpoint}
              getAppointmentsEndpoint={props.getAppointmentsEndpoint}
              getAvailabilityEndpoint={props.getAvailabilityEndpoint}
              googleMapsApiKey={props.googleMapsApiKey}
              {...props.bookingFormData}
              footerData={props.footerData}
              titles={props.titles}
              subtitles={props.subtitles}
              hints={props.hints}
              seeNearestText={props.seeNearestText}
              assistantText={props.assistantText}
              assistantDefaultText={props.assistantDefaultText}
              assistantCancelText={props.assistantCancelText}
              assistantLabel={props.assistantLabel}
              timeText={props.timeText}
              priceText={props.priceText}
              paymentTypeText={props.paymentTypeText}
              currentWeekDay={props.currentWeekDay}
              carouselTitle={props.carouselTitle}
              carouselPreviousItemText={props.carouselPreviousItemText}
              carouselNextItemText={props.carouselNextItemText}
              timeListTitle={props.timeListTitle}
              preposition={props.preposition}
              logo={props.logo}
              preselectedShopId={props.preselectedShopId}
              preselectedAppointmentId={props.preselectedAppointmentId}
              preselectedOpticianId={props.preselectedOpticianId}
              userAddress={props.userAddress}
            />
          </ModalContentWrapper>
        </Collapse>
      )}
    </Modal>
  );
};

AppointmentBookingModal.propTypes = {
  getRegionsEndpoint: PropTypes.string,
  getAppointmentsEndpoint: PropTypes.string,
  getAvailabilityEndpoint: PropTypes.string,
  googleMapsApiKey: PropTypes.string,
  bookingFormData: PropTypes.shape({
    stepBackButtonText: PropTypes.string,
    form: PropTypes.exact(Form.propTypes),
    conditionalTextAppointmentType: PropTypes.string,
    conditionalBookingUserNameText: PropTypes.string,
    appointmentBookingUserName: PropTypes.exact(TextInput.propTypes),
    appointmentBookingBirthDate: PropTypes.exact(TextInput.propTypes),
    appointmentBookingEmail: PropTypes.exact(TextInput.propTypes),
    appointmentBookingPhoneNumber: PropTypes.exact(TextInput.propTypes),
    appointmentBookingAttentionText: PropTypes.string,
    appointmentBookingAddInfo: PropTypes.exact(TextInput.propTypes),
    agreeBoxData: PropTypes.exact(AppointmentBookingAgreeBox.propTypes)
  }),
  footerData: PropTypes.exact(AppointmentBookingFooter.propTypes),
  titles: PropTypes.arrayOf(PropTypes.string),
  subtitles: PropTypes.arrayOf(PropTypes.string),
  hints: PropTypes.arrayOf(PropTypes.string),
  seeNearestText: PropTypes.string,
  assistantText: PropTypes.string,
  assistantDefaultText: PropTypes.string,
  assistantCancelText: PropTypes.string,
  assistantLabel: PropTypes.string,
  timeText: PropTypes.string,
  priceText: PropTypes.string,
  paymentTypeText: PropTypes.string,
  currentWeekDay: PropTypes.string,
  carouselTitle: PropTypes.string,
  carouselPreviousItemText: PropTypes.string,
  carouselNextItemText: PropTypes.string,
  timeListTitle: PropTypes.string,
  preposition: PropTypes.string,
  logo: PropTypes.string,
  preselectedShopId: PropTypes.string,
  preselectedAppointmentId: PropTypes.string,
  preselectedOpticianId: PropTypes.string,
  userAddress: PropTypes.shape({
    endpoint: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  openOnPageLoad: PropTypes.bool
};

export default AppointmentBookingModal;
