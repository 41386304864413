import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContainedImage from 'components/contained-image';
import ImageLoader from '../../js/responsive-images';

const themes = {
  contained: 'theme-contained'
};

class Image extends React.Component {
  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    containedPosition: PropTypes.string,
    draggable: PropTypes.bool,
    initialSize: PropTypes.number,
    responsive: PropTypes.bool,
    scale: PropTypes.number,
    src: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(themes))
  };

  static propTypesMeta = {
    className: 'exclude',
    containedPosition: 'exclude',
    draggable: 'exclude',
    initialSize: 'exclude',
    responsive: 'exclude',
    scale: 'exclude',
    theme: 'exclude'
  };

  static defaultProps = {
    initialSize: 300,
    scale: 1,
    responsive: true
  };

  state = {
    src: ImageLoader.getNewSrc(this.props.src, this.props.initialSize)
  };

  componentDidMount() {
    const parentNode = this.image && this.image.parentElement;

    if (parentNode && this.props.responsive) {
      this.setState({
        src: ImageLoader.getNewSrc(
          this.props.src,
          parentNode.offsetWidth * this.props.scale,
          this.props.initialSize
        )
      });
    }
  }

  render() {
    if (!this.props.src) {
      return null;
    }
    if (this.props.theme === themes.contained) {
      return (
        <ContainedImage
          alt={this.props.alt}
          className={this.props.className}
          initialSize={this.props.initialSize}
          position={this.props.containedPosition}
          src={this.state.src}
        />
      );
    }

    return !this.props.src ? null : (
      <img
        className={cn('image', this.props.className)}
        src={this.state.src}
        alt={this.props.alt}
        draggable={this.props.draggable}
        ref={img => (this.image = img)}
      />
    );
  }
}

Image.themes = themes;

export default Image;
