export const isRequired = value => {
  if (Array.isArray(value)) return value.length > 0;
  if (typeof value === 'object') return Object.keys(value).length > 0;
  return !!value;
};

export const isUserName = value => {
  if (value.match(/^(([a-z\-'æøåÆØÅA-Z]{2,})\s*){2,}$/)) return true;
  return false;
};

export const isEmail = value => {
  if (typeof value !== 'string') return true;
  return !!value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z0-9-]{1,63}$/);
};

export const isBirthDate = value => {
  if (!value) return true;
  if (!value.match(/\d{8}/)) {
    return false;
  }

  const day = parseInt(value.substr(0, 2));
  const month = parseInt(value.substr(2, 2));
  const year = parseInt(value.substr(4));

  if (day >= 1 && day <= 31 && month >= 1 && month <= 12 && year > 1900) {
    return true;
  }
};

export const isPostalCode = value => {
  if (!value) return true;
  return value.match(/^\d{4}$/) ? true : false;
};

export const isPhoneNumber = value => {
  return !value || value.match(/^\+?[\d-()\s]{7,}\d$/);
};
