import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Link from 'components/link';
import Text from 'components/text';

const RegistrationBanner = ({ description, link, title }) => (
  <div className="registration-banner">
    <Text.h2 theme={Text.themes.uppercaseBig}>{title}</Text.h2>
    <Link
      className="registration-banner-link"
      iconAfter="chevron-right"
      themes={[Link.themes.buttonGray]}
      {...link}
    />
    <ul className="registration-banner-text">
      {description.map(text => (
        <li key={text}>
          <Icon className="registration-banner-icon" name="checkmark" />
          {text}
        </li>
      ))}
    </ul>
  </div>
);

RegistrationBanner.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string
};

export default RegistrationBanner;
