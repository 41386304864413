import React from 'react';
import PropTypes from 'prop-types';

import FindStore from '../find-store';
import Icon from '../icon';
import Link from '../link';
import Text from 'components/text';
import TextInput from 'components/text-input';

class FindStoreBlock extends React.Component {
  static propTypes = {
    findStore: PropTypes.exact(FindStore.propTypes),
    noResultsText: PropTypes.string,
    title: PropTypes.string
  };

  state = {
    resultsVisible: false
  };

  onInteract = e => {
    if (!this.searchWrapper || !this.searchWrapper.contains) {
      return;
    }

    this.setState({
      resultsVisible: this.searchWrapper.contains(e.target)
    });
  };

  componentDidMount() {
    window.addEventListener('focusin', this.onInteract);
    window.addEventListener('click', this.onInteract);
  }

  componentWillUnmount() {
    window.addEventListener('focusin', this.onInteract);
    window.removeEventListener('click', this.onInteract);
  }

  render() {
    return (
      <div className="find-store-block">
        <Icon className="find-store-block-icon" name="pin" />
        <Text.h2 theme={Text.themes.h2}>{this.props.title}</Text.h2>
        <div
          className="find-store-block-content"
          ref={div => (this.searchWrapper = div)}
        >
          <div className="find-store-block-search-icon">
            <Icon name="magnifier" />
          </div>
          <FindStore
            textInputTheme={[TextInput.themes.dark]}
            updateOnChange={true}
            {...this.props.findStore}
          >
            {({ isLoading, searchTerm, stores }) =>
              this.state.resultsVisible &&
              !isLoading &&
              searchTerm &&
              searchTerm.length >= 2 ? (
                <ul className="find-store-block-results">
                  {stores.length > 0 ? (
                    stores.map(store => (
                      <li key={store.name + store.email}>
                        <Link text={store.name} url={store.url} />
                      </li>
                    ))
                  ) : (
                    <li>{this.props.noResultsText}</li>
                  )}
                </ul>
              ) : null
            }
          </FindStore>
        </div>
      </div>
    );
  }
}

export default FindStoreBlock;
