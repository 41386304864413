/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import KlarnaAndPorterbuddy from 'components/klarna-and-porterbuddy';

const fetchHeaders = {
  'content-type': 'application/json',
  accept: 'application/json'
};

const DeliveryWidget = ({
  klarnaIframeHtml,
  porterBuddyConfig,
  porterBuddyWidgetConfig,
  porterBuddyPublicToken,
  porterBuddyAvailabilityEndpoint,
  priceEndpoint,
  porterBuddyErrorMessage,
  klarnaErrorMessage,
  handleDeliveryFail,
  handleDeliverySucceed
}) => {
  const [hasError, setHasError] = useState(false);
  const currentHasError = useRef();
  currentHasError.current = hasError;

  const handleOnDeliveryWindowChange = async deliveryWindow => {
    //console.log('Update delivery method if the state has error:');
    //console.log(currentHasError.current);
    if (currentHasError.current) {
      await handleDeliverySucceed();
    }

    setHasError(false);

    try {
      return await api.execute(priceEndpoint, { deliveryWindow });
    } catch (e) {
      console.log(e.toString());
      setHasError(true);
      await handleDeliveryFail();
    }
  };

  const fetchPorterBuddyAvailability = async postalCode => {
    const payload = JSON.stringify({
      ...porterBuddyConfig,
      destinationAddress: {
        ...porterBuddyConfig.destinationAddress,
        postalCode
      }
    });

    try {
      const availabilityResponse = await fetch(
        porterBuddyAvailabilityEndpoint,
        {
          method: 'POST',
          headers: {
            ...fetchHeaders,
            'x-public-token': porterBuddyPublicToken
          },
          body: payload
        }
      );

      if (!availabilityResponse.ok) {
        // conditionally, the response is an array of error objects.
        const errors = await availabilityResponse.json();

        const { code, message } = errors[0];

        // We assume this is because porterBuddy does not deliver to the specificed postalCode
        if (code === 'LOCATION_NODELIVERY') throw Error(message);

        // We assume that this is because of missing data from Klarna on init
        throw Error(message);
      }

      return await availabilityResponse.json();
    } catch (e) {
      console.log(e.toString());
      setHasError(true);
      await handleDeliveryFail();
    }
  };

  return (
    <div className="delivery-widget">
      {hasError && (
        <div className="delivery-widget__error">
          <p className="delivery-widget__error-text">
            {porterBuddyErrorMessage}
          </p>
        </div>
      )}
      <div className="delivery-widget__widget">
        <KlarnaAndPorterbuddy
          onDeliveryWindowChange={handleOnDeliveryWindowChange}
          porterBuddyConfig={porterBuddyWidgetConfig}
          klarnaIframeHtml={klarnaIframeHtml}
          shouldEvalScript={true}
          hasError={hasError}
          onKlarnaChange={fetchPorterBuddyAvailability}
          klarnaErrorMessage={klarnaErrorMessage}
        />
      </div>
    </div>
  );
};

DeliveryWidget.propTypes = {
  klarnaIframeHtml: PropTypes.string,
  porterBuddyAvailabilityEndpoint: PropTypes.string.isRequired,
  porterBuddyConfig: PropTypes.shape({
    originAddress: PropTypes.shape({
      streetName: PropTypes.string.isRequired,
      streetNumber: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired
    }).isRequired,
    destinationAddress: PropTypes.shape({
      postalCode: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired
    }).isRequired,
    products: PropTypes.arrayOf(PropTypes.string),
    parcels: PropTypes.arrayOf(
      PropTypes.shape({
        widthCm: PropTypes.number,
        heightCm: PropTypes.number,
        depthCm: PropTypes.number,
        weightGrams: PropTypes.number
      })
    )
  }).isRequired,
  porterBuddyWidgetConfig: PropTypes.shape({
    token: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired
  }).isRequired,
  priceEndpoint: PropTypes.string.isRequired,
  porterBuddyErrorMessage: PropTypes.string.isRequired,
  porterBuddyPublicToken: PropTypes.string.isRequired,
  klarnaErrorMessage: PropTypes.string.isRequired,
  handleDeliveryFail: PropTypes.func,
  handleDeliverySucceed: PropTypes.func
};

DeliveryWidget.propTypesMeta = {
  klarnaIframeHtml: 'exclude'
};

export default DeliveryWidget;
