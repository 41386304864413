import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Link from 'components/link';
import TextInput from 'components/text-input';

const CustomerDataImportForm = ({
  endpoint,
  secondaryAction,
  submitLabel,
  textInput
}) => (
  <div className="customer-data-import-form">
    <form action={endpoint} method="POST">
      <div className="customer-data-import-form-input">
        <TextInput {...textInput} autocomplete="tel" />
      </div>

      <div className="customer-data-import-form-actions">
        <Button type="submit" text={submitLabel} />
        {secondaryAction && <Link {...secondaryAction} />}
      </div>
    </form>
  </div>
);

CustomerDataImportForm.propTypes = {
  endpoint: PropTypes.string,
  secondaryAction: PropTypes.exact(Link.propTypes),
  submitLabel: PropTypes.string,
  textInput: PropTypes.exact(TextInput.propTypes)
};

export default CustomerDataImportForm;
