import React from 'react';
import PropTypes from 'prop-types';
import FindStore from 'components/find-store/find-store';
import PageSpinner from '../page-spinner';
import ReserveStoresList from './reserve-stores-list';
import Modal from '../modal';
import PubSub from 'pubsub-js';
import topics from 'js/topics.json';

class ReservInStore extends React.Component {
  static propTypes = {
    heading: PropTypes.string,
    productName: PropTypes.string,
    searchLabel: PropTypes.string,
    noResultsText: PropTypes.string,
    resultsHeading: PropTypes.string,
    findStore: PropTypes.exact(FindStore.propTypes),
    list: PropTypes.exact(ReserveStoresList.propTypes)
  };

  static propTypesMeta = {};

  static defaultProps = {
    noResultsText: 'Vi fant dessverre ingen butikker.'
  };

  state = {
    isVisible: false,
    productName: this.props.productName,
    heading: this.props.heading,
    searchLabel: this.props.searchLabel,
    findStore: this.props.findStore,
    list: this.props.list,
    noResultsText: this.props.noResultsText,
    resultsHeading: this.props.resultsHeading
  };

  componentDidMount() {
    this.subscriber = PubSub.subscribe(
      topics.reserveUpdate,
      (msg, newState) => {
        this.setState({
          ...newState,
          isVisible: true
        });
      }
    );
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.subscriber);
  }

  render() {
    const {
      isVisible,
      productName,
      heading,
      findStore,
      searchLabel,
      resultsHeading,
      list,
      noResultsText
    } = this.state;

    const hide = () => {
      this.setState({ isVisible: false });
    };
    return (
      <Modal
        containerClassName="reserve-in-store__modal"
        contentClassName="reserve-in-store__modal-content"
        hide={hide}
        isVisible={isVisible}
        showCloseButton={true}
        plainCloseButton={true}
        closeButtonClassName="reserve-in-store__modal-close"
      >
        <div className="reserve-in-store">
          <h2 className="reserve-in-store__heading">{heading}</h2>
          <div className="reserve-in-store__product-name">{productName}</div>
          {findStore && (
            <FindStore
              wrapperClassName="reserve-in-store__form-wrapper"
              className="reserve-in-store__form"
              searchClassName="search--reserve-in-store"
              {...findStore}
              heading={searchLabel}
            >
              {({ isLoading, stores }) => (
                <React.Fragment>
                  <PageSpinner isLoading={isLoading} />
                  {stores.length > 0 ? (
                    <React.Fragment>
                      {resultsHeading && (
                        <h3 className="reserve-in-store__results-heading">
                          {resultsHeading}
                        </h3>
                      )}
                      <div className="reserve-in-store__stores">
                        <ReserveStoresList stores={stores} {...list} />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="reserve-in-store__no-results">
                      {noResultsText}
                    </div>
                  )}
                </React.Fragment>
              )}
            </FindStore>
          )}
        </div>
      </Modal>
    );
  }
}

export default ReservInStore;
