import get from 'lodash/get';

export default (google, stores, googlePlacesData) => {
  // No matches
  if (!googlePlacesData || googlePlacesData.length === 0) {
    return [];
  }

  const result = googlePlacesData[0];
  const addressComponents = get(result, 'address_components', []);
  const bounds = result.geometry.bounds || result.geometry.viewport;
  const location = result.geometry.location;

  if (!isValidResult(addressComponents)) {
    return [];
  }

  // Attempt to find a store in the bounds provided by google
  if (bounds) {
    const storesInBounds = stores.filter(store =>
      bounds.contains(new google.maps.LatLng(store.lat, store.lng))
    );

    if (storesInBounds.length) {
      return storesInBounds;
    }
  }

  // If no match was found within bounds, try to find a match in a larger area around the match
  const nearbyStores = stores.filter(
    ({ lat, lng }) =>
      getDistance(google, new google.maps.LatLng(lat, lng), location) < 5000 // look within 5km
  );

  if (nearbyStores.length) {
    return nearbyStores;
  }

  // If no match was dound within or near bounds, try to find a match to one of the address components
  const storesByAddress = filterByAddressComponents(stores, addressComponents);

  if (storesByAddress.length) {
    return storesByAddress;
  }

  return [];
};

// Google, being helpful, will always return a match. If no useful match is found, there will be one match, matching the entire country. This function checks whether this has happened in order to detect cases where no match is found
function isValidResult(addressComponents) {
  return addressComponents.length === 1 &&
    addressComponents[0].types.includes('country')
    ? false
    : true;
}

function getDistance(google, p1, p2) {
  return google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
}

function filterByAddressComponents(stores, addressComponents) {
  // Convert google maps address_components (array of objects) into an array of strings
  const addressMatches = addressComponents
    .reduce((accum, component) => accum.concat(component['long_name']), [])
    .filter(str => str); // Remove any undefined values

  return stores.filter(({ postPlace, postCode, county }) => {
    // Include store in search result if one or more of the address components match postPlace, postCode or county
    return addressMatches.some(match =>
      [postPlace, postCode, county].includes(match)
    );
  });
}
