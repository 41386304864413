import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OnPageEditing from 'components/on-page-editing';

import ComponentListItem from 'components/component-list-item';

/*
NOTE: additionalComponentProps can be used to send props to all components of a specific type.
This only applies to the components rendered directly by ContentAreaItem (in other words these props don't trickle all the way down the render tree)
Example: forcing the 'green' theme for all CtaBlock rendered by a content area:
  <ComponentList additionalComponentProps={{ CtaBlock: { theme: CtaBlock.themes.green } }} />
*/

/*
NOTE: additionalItemProps works in much the same way as 'additionalComponentProps', except for the fact that it controls the ContentAreaItem wrapper instead of the component itself.
                                           
Example: forcing full width for all wrappers around InfoBlocks rendered by a content area:
  <ComponentList additionalItemProps={{ InfoBlock: { size: ContentAreaItem.sizes.fullScreen } }} />
*/

const ComponentList = ({
  additionalComponentProps, // See above comment
  additionalItemProps, // Same as 'additionalComponentProps' but for the ContentAreaItem component
  components,
  blockNotSupportedText,
  className,
  onPageEditing
}) =>
  components.length === 0 && !onPageEditing.name ? null : (
    <OnPageEditing name={onPageEditing.name}>
      <div className={cn('component-list', className)}>
        {components.map(block => (
          <ComponentListItem
            additionalComponentProps={additionalComponentProps}
            blockNotSupportedText={blockNotSupportedText}
            key={block.id}
            {...block}
            {...additionalItemProps[block.componentName]}
          />
        ))}
      </div>
    </OnPageEditing>
  );

ComponentList.propTypes = {
  additionalComponentProps: PropTypes.object,
  additionalItemProps: PropTypes.object,
  blockNotSupportedText: PropTypes.string,
  components: PropTypes.arrayOf(PropTypes.exact(ComponentListItem.propTypes)),
  className: PropTypes.string,
  onPageEditing: PropTypes.exact({
    name: PropTypes.string
  })
};

ComponentList.propTypesMeta = {
  additionalComponentProps: 'exclude',
  additionalItemProps: 'exclude',
  className: 'exclude'
};

ComponentList.defaultProps = {
  additionalComponentProps: {},
  additionalItemProps: {},
  components: [],
  onPageEditing: {}
};

export default ComponentList;
