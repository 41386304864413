import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

const CartProductStatus = ({ colSpan, text, link }) => (
  <tr className="cart-product-status">
    <td colSpan={colSpan}>
      <span className="cart-product-status-text">{text}</span>
      {link && <Link {...link} />}
    </td>
  </tr>
);

CartProductStatus.propTypes = {
  colSpan: PropTypes.number,
  text: PropTypes.string,
  link: PropTypes.exact(Link.propTypes)
};

CartProductStatus.propTypesMeta = {
  colSpan: 'exclude'
};

export default CartProductStatus;
