import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PubSub from 'pubsub-js';

import topics from 'js/topics.json';
import checkApiPropTypes from 'js/check-api-prop-types.js';

import AddToCartResponse from './add-to-cart-response';
import Availability from 'components/availability';
import Button from 'components/button';
import Form from 'components/form';
import Icon from 'components/icon';
import InputTable from 'components/input-table';
import Radio from 'components/radio';
import PageSpinner from 'components/page-spinner';
import Price from 'components/price';
import Select from 'components/select';
import Text from 'components/text';
import Link from 'components/link';
import ValidationErrorTexts from 'components/validation-error-texts';

class ContactLensForm extends React.Component {
  static propTypes = {
    showPurchaseSection: PropTypes.bool,
    addToCartEndpoint: PropTypes.string,
    availability: PropTypes.exact(Availability.propTypes),
    availabilityBanner: PropTypes.string,
    onChange: PropTypes.func,
    availabilityInformation: PropTypes.exact({
      text: PropTypes.string,
      link: PropTypes.exact(Link.propTypes)
    }),
    priceSummary: PropTypes.exact({
      price: PropTypes.string,
      priceLabel: PropTypes.string,
      summary: PropTypes.string
    }),
    productSelect: PropTypes.arrayOf(
      PropTypes.exact({
        input: PropTypes.exact(Radio.propTypes),
        label: PropTypes.string,
        price: PropTypes.exact(Price.propTypes)
      })
    ),
    propertySelect: PropTypes.exact(InputTable.propTypes),
    quantitySelect: PropTypes.exact(Select.propTypes),
    shippingBanner: PropTypes.string,
    submitLabel: PropTypes.string,
    subtitle: PropTypes.string,
    tag: PropTypes.string,
    title: PropTypes.string,
    validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes),
    validationEndpoint: PropTypes.string
  };

  static defaultProps = {
    productSelect: [],
    showPurchaseSection: true
  };

  state = {
    enableValidation: false,
    isLoading: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ isLoading: false });
    }
  }

  onChange = formData => {
    clearTimeout(this.changeTimeout);
    this.changeTimeout = setTimeout(() => {
      this.setState({ isLoading: true }, () => {
        this.props.onChange(formData);
      });
    }, 100);
  };

  onAddToCartResponse = response => {
    checkApiPropTypes(
      response,
      AddToCartResponse.propTypes,
      'AddToCartResponse'
    );
    PubSub.publish(topics.cartUpdate, response);
  };

  render() {
    const {
      priceSummary,
      productSelect,
      propertySelect,
      shippingBanner
    } = this.props;

    return (
      <Form
        className={cn('contact-lens-form', {
          'contact-lens-form--no-section-margins': this.props
            .showPurchaseSection
        })}
        endpoint={this.props.addToCartEndpoint}
        onChange={this.onChange}
        onInvalidSubmit={this.onInvalidSubmit}
        onResponse={this.onAddToCartResponse}
        showSubmitButton={false}
        validationErrors={this.props.validationErrors}
        validationEndpoint={this.props.validationEndpoint}
      >
        {({ isLoading }) => (
          <React.Fragment>
            <PageSpinner
              shouldContainInParent={true}
              isLoading={isLoading || this.state.isLoading}
            />

            <div className="contact-lens-form-section">
              {this.props.tag && (
                <div className="contact-lens-tag-wrapper">
                  <Text.div
                    className="contact-lens-tag"
                    theme={Text.themes.filled}
                  >
                    {this.props.tag}
                  </Text.div>
                </div>
              )}

              {this.props.title && (
                <Text.h2 theme={Text.themes.boldUppercase}>
                  {this.props.title}
                </Text.h2>
              )}
              {this.props.subtitle && (
                <p className="contact-lens-form-subtitle">
                  {this.props.subtitle}
                </p>
              )}

              {!this.props.showPurchaseSection && (
                <>
                  {this.props.availabilityInformation && (
                    <div className="contact-lens-purchase-information">
                      {this.props.availabilityInformation.text && (
                        <p>{this.props.availabilityInformation.text}</p>
                      )}
                      <Link
                        iconAfter="pin"
                        themes={[Link.themes.buttonGray]}
                        {...this.props.availabilityInformation.link}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            {this.props.showPurchaseSection && (
              <div className="contact-lens-form-section">
                {productSelect.length > 0 && (
                  <div className="contact-lens-product-select">
                    {productSelect.map(({ input, label, price }, index) => (
                      <div
                        className="contact-lens-product-select-item"
                        key={input.value}
                      >
                        <Radio
                          idPrefix="lens-select-"
                          validationMessageHidden={
                            index < productSelect.length - 1
                          }
                          {...input}
                        >
                          <span className="contact-lens-form-price">
                            <span>{label}</span>
                            <Price theme={Price.themes.vertical} {...price} />
                          </span>
                        </Radio>
                      </div>
                    ))}
                  </div>
                )}

                {shippingBanner && (
                  <div className="contact-lens-shipping-banner">
                    <Icon
                      className="contact-lens-availability-banner-icon"
                      name="checkmark"
                    />
                    <p>{shippingBanner}</p>
                  </div>
                )}

                {this.props.quantitySelect && (
                  <div className="contact-lens-form-quantity">
                    <Select
                      labelVisible={true}
                      {...this.props.quantitySelect}
                    />
                  </div>
                )}

                {propertySelect && (
                  <Text.div
                    className="contact-lens-property-select"
                    theme={Text.themes.small}
                  >
                    <InputTable {...propertySelect} />
                  </Text.div>
                )}

                {this.props.availabilityBanner && (
                  <div className="contact-lens-availability-banner">
                    <p>{this.props.availabilityBanner}</p>
                  </div>
                )}

                {priceSummary && (
                  <div className="contact-lens-form-summary">
                    <Text.div theme={Text.themes.small}>
                      {priceSummary.summary}
                    </Text.div>
                    <Text.div theme={Text.themes.big}>
                      {priceSummary.price}
                    </Text.div>
                  </div>
                )}

                {this.props.submitLabel && (
                  <Button
                    data-test-add-to-cart-button
                    iconAfter="basket"
                    type="submit"
                    text={this.props.submitLabel}
                    themes={[Button.themes.buttonGray, Button.themes.wide]}
                  />
                )}

                {this.props.availability && (
                  <div className="contact-lens-availability">
                    <Availability {...this.props.availability} />
                  </div>
                )}
              </div>
            )}
          </React.Fragment>
        )}
      </Form>
    );
  }
}

export default ContactLensForm;
