import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '../accordion';

const AccordionList = ({ items, isNarrow }) =>
  !items.length ? null : (
    <ul className="accordion-list">
      {items.map(item => (
        <li key={item.id}>
          <Accordion showBorder={false} {...item} isNarrow={isNarrow} />
        </li>
      ))}
    </ul>
  );

AccordionList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(Accordion.propTypes)),
  isNarrow: PropTypes.bool
};

AccordionList.propTypesMeta = {
  isNarrow: 'exclude'
};

AccordionList.defaultProps = {
  items: []
};

export default AccordionList;
