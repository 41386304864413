import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from '../content-container';
import ComponentList from 'components/component-list';
import ComponentListItem from 'components/component-list-item';

import MainTitle from '../main-title';

const StorePage = ({ heading, components }) => {
  return (
    <div className="store-page">
      <ContentContainer>
        <MainTitle {...heading} />
      </ContentContainer>
      <ComponentList
        components={components.map(block => {
          return { size: ComponentListItem.sizes.article, ...block };
        })}
      />
    </div>
  );
};

StorePage.propTypes = {
  heading: PropTypes.exact(MainTitle.propTypes),
  components: PropTypes.arrayOf(PropTypes.exact(ComponentListItem.propTypes))
};

export default StorePage;
