import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import userAddressHandler from 'js/user-address-handler';
import findNearestShops from 'js/find-nearest-shops';
import Select from 'components/select';

const SelectSortedOnGeoLocation = props => {
  const [shopsState, setShopsState] = useState([]);
  const [userCoords, setUserCoords] = useState({});

  const updateUserAddressInfo = () => {
    userAddressHandler.handle(
      props.userAddress,
      props.googleMapsApiKey,
      false,
      function(coords) {
        setUserCoords(coords);
      }
    );
  };

  // here we update shops model
  // we leave only needed values for select
  // such as label and value
  // key name renamed to label to support select component
  const setShops = shops => {
    const shopsArr = [];
    for (let shop of shops) {
      let newShop = {
        label: shop.name,
        value: shop.value
      };
      shopsArr.push(newShop);
    }
    setShopsState(shopsArr);
  };

  const seeNearestShops = () => {
    const limit = 25;
    const nearestShopsNum = 10;
    findNearestShops(
      userCoords,
      props.options,
      limit,
      nearestShopsNum,
      props.googleMapsApiKey
    ).then(shopsResult => {
      setShops(shopsResult);
    });
  };

  useEffect(() => {
    updateUserAddressInfo();
  }, []);

  useEffect(() => {
    // in case when we do not have any data we return null
    // in this case find-nearest-shops will sort shops in alph order
    // otherwise we always have lat and long
    if (
      userCoords === null ||
      (userCoords && userCoords.latitude && userCoords.longitude)
    )
      seeNearestShops();
  }, [userCoords]);

  return (
    <div className="select-sorted-on-geolocation">
      <Select
        placeholder={props.placeholder}
        name={props.name}
        idPrefix={props.idPrefix}
        options={shopsState}
        onChange={props.onSelectShop}
      />
    </div>
  );
};

SelectSortedOnGeoLocation.propTypes = {
  userAddress: PropTypes.shape({
    endpoint: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  onSelectShop: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  idPrefix: PropTypes.string,
  googleMapsApiKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
      distance: PropTypes.number
    })
  )
};

export default SelectSortedOnGeoLocation;
