/*
This is exported as a function rather than a plain object because:
  - This file imports all components
  - Some components indirectly import this file, creating a circular dependency
  - Webpack solves the problem of circular dependencies by setting the circular imports to 'undefined', meaning rendering will fail
  - Exporting as a function rather than a plain object magically solves this
*/

export default function() {
  return require('../../app.components');
}
