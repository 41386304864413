import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import Banner from './banner';
import CollapsableRichText from '../collapsable-rich-text';
import Link from '../link';

const cookies = new Cookies();

const SiteInfoBanner = props => {
  const { cookieClientId, cookieExpirationTimeInSeconds, ...rest } = props;

  const [showBanner, setShowBanner] = useState(true);

  const handleOnBannerClick = () => {
    cookies.set(cookieClientId, true, {
      path: '/',
      maxAge: cookieExpirationTimeInSeconds
    });

    setShowBanner(false);
  };

  return showBanner ? <Banner {...rest} onClick={handleOnBannerClick} /> : null;
};

SiteInfoBanner.propTypes = {
  title: PropTypes.string,
  cookieClientId: PropTypes.string,
  onClick: PropTypes.func,
  richText: PropTypes.exact(CollapsableRichText.propTypes),
  buttonText: PropTypes.string,
  cookieExpirationTimeInSeconds: PropTypes.number,
  link: PropTypes.exact(Link.propTypes)
};

export default SiteInfoBanner;
