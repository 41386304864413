import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import toQueryString from '@creuna/utils/to-query-string';

import breakpoints from 'js/breakpoints';
import propTypeTheme from 'utils/prop-type-theme';

import Button from '../button';
import CategoryFilters from '../category-filters';
import PaginationControls from '../pagination-controls';
import ContentContainer from '../content-container';
import EmptyListMessage from '../empty-list-message';
import MainTitle from 'components/main-title';
import Form from 'components/form';
import Checkbox from 'components/checkbox';
import ImageLinkList from '../image-link-list';
import PageSpinner from '../page-spinner';
import ProductsHeader from './products-header';
import ProductsFooter from './products-footer';
import ProductList from '../product-list';
import Select from '../select';
import Search from 'components/search';
import Text from 'components/text';
import TextInput from 'components/text-input';

const themes = {
  search: 'theme-search'
};

class CategoryPage extends React.Component {
  static propTypes = {
    articles: PropTypes.exact(ImageLinkList.propTypes),
    articlesButtonText: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    paginationControls: PropTypes.exact(PaginationControls.propTypes),
    hideSidebar: PropTypes.bool,
    filters: PropTypes.exact(CategoryFilters.propTypes),
    hasMoreProducts: PropTypes.bool,
    moreProductsEndpoint: PropTypes.string,
    moreProductsLabel: PropTypes.string,
    noResultsMessage: PropTypes.string,
    numberOfProducts: PropTypes.string,
    productList: PropTypes.exact(ProductList.propTypes),
    productsButtonText: PropTypes.string,
    preTitle: PropTypes.string,
    search: PropTypes.exact(Search.propTypes),
    showArticles: PropTypes.bool,
    productsHeader: PropTypes.exact(ProductsHeader.propTypes),
    showFiltersLabel: PropTypes.string,
    showNewProductsOnly: PropTypes.exact(Checkbox.propTypes),
    sorting: PropTypes.exact(Select.propTypes),
    theme: propTypeTheme(themes),
    title: PropTypes.string,
    productsFooter: PropTypes.exact(ProductsFooter.propTypes)
  };

  static propTypesMeta = {
    theme: 'exclude'
  };

  static defaultProps = {
    products: []
  };

  state = {
    articles: this.props.articles,
    articlesButtonText: this.props.articlesButtonText,
    filters: this.props.filters,
    filtersVisible: false,
    hasMoreProducts: this.props.hasMoreProducts,
    isArticlesVisible: this.props.showArticles,
    isDesktop: true,
    isLoading: false,
    moreProductsEndpoint: this.props.moreProductsEndpoint,
    numberOfProducts: this.props.numberOfProducts,
    productList: this.props.productList,
    productsButtonText: this.props.productsButtonText,
    preTitle: this.props.preTitle,
    title: this.props.title,
    paginationControls: this.props.paginationControls,
    productsHeader: this.props.productsHeader,
    productsFooter: this.props.productsFooter
  };

  form = null;
  searchTerm = get(this.props.search, 'value', '');
  previousFormData = {
    [get(this.props.showNewProductsOnly, 'name', '')]: get(
      this.props.showNewProductsOnly,
      'selected'
    ),
    [get(this.props.sorting, 'name', '')]:
      get(this.props.sorting, 'options', []).find(option => option.selected) ||
      get(this.props.sorting, 'options[0].value')
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  onFiltersChange = data => {
    if (this.state.isLoading) {
      return;
    }

    if (this.props.search) {
      const newSearchTerm = get(data, get(this.props.search, 'name', ''), '');

      if (this.searchTerm !== newSearchTerm) {
        // NOTE: We don't want to search while the user is typing
        this.searchTerm = newSearchTerm;
        return;
      }
    }

    // We need a separate check for 'show only new products' and result sorting, because these should refresh page on change
    let shouldRefresh = false;

    if (this.props.showNewProductsOnly) {
      const key = get(this.props.showNewProductsOnly, 'name', '');
      if (this.previousFormData[key] !== data[key]) {
        shouldRefresh = true;
      }
    }

    if (this.props.sorting) {
      const key = get(this.props.sorting, 'name', '');
      if (this.previousFormData[key] !== data[key]) {
        shouldRefresh = true;
      }
    }

    this.previousFormData = data;

    if ((this.form && this.state.isDesktop) || shouldRefresh) {
      window.history.replaceState(
        {},
        null,
        window.location.pathname + toQueryString(data)
      );
      this.form.submit();
    }
  };

  onResize = () => {
    this.setState({ isDesktop: breakpoints.m() });
  };

  debouncedResize = debounce(this.onResize, 400);

  hideFilters = () => {
    document.body.style.position = '';
    document.body.style.overflow = '';
    this.setState({ filtersVisible: false });
  };

  showFilters = () => {
    if (!this.state.isDesktop) {
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      this.mobileFilterContainer.scrollTop = 0;
    }
    this.setState({ filtersVisible: true });
  };

  showArticles = () => {
    this.setState({ isArticlesVisible: true });
  };

  showProducts = () => {
    this.setState({ isArticlesVisible: false });
  };

  onSubmit = () => {
    this.setState({ isLoading: true });
  };

  onResponse = (response, shouldReplaceProducts) => {
    this.setState(
      previousState => {
        const products = get(response, 'productList.products', []);

        const oldProducts = get(previousState, 'productList.products', []);

        const newProducts = shouldReplaceProducts
          ? products
          : oldProducts.concat(products);

        return Object.assign({}, response, {
          isLoading: false,
          filters: {
            ...previousState.filters,
            ...response.filters
          },
          productList: {
            ...previousState.productList,
            products: newProducts
          },
          paginationControls: response.paginationControls
        });
      },
      () => {
        this.hideFilters();
      }
    );
  };

  // Reset needs to be applied before submitting
  onReset = () => setTimeout(() => this.form.submit(), 200);

  render() {
    const loadMoreFormId = 'load-more-products';
    const hasProducts = get(this.state.productList, 'products', []).length > 0;

    return (
      <div className={cn('category-page', this.props.theme)}>
        <Form
          endpoint={this.props.endpoint}
          onChange={this.onFiltersChange}
          onRef={form => (this.form = form)}
          onResponse={r => this.onResponse(r, true)}
          onSubmit={this.onSubmit}
          showSubmitButton={false}
        >
          <PageSpinner isLoading={this.state.isLoading} />
          <ContentContainer className="category-page-header">
            <MainTitle
              preTitle={this.state.preTitle}
              title={this.state.title}
              theme={MainTitle.themes.small}
            />
            {this.props.search && (
              <React.Fragment>
                <div className="category-page-search">
                  <Search
                    textInputTheme={[TextInput.themes.bigText]}
                    {...this.props.search}
                  />
                </div>

                <div className="category-page-search-tabs">
                  <span
                    className={cn('category-page-search-tab', {
                      'is-active': !this.state.isArticlesVisible
                    })}
                  >
                    <Button
                      onClick={this.showProducts}
                      themes={[Button.themes.uppercase]}
                    >
                      {this.state.productsButtonText}
                    </Button>
                  </span>

                  <span
                    className={cn('category-page-search-tab', {
                      'is-active': this.state.isArticlesVisible
                    })}
                  >
                    <Button
                      onClick={this.showArticles}
                      themes={[Button.themes.uppercase]}
                    >
                      {this.state.articlesButtonText}
                    </Button>
                  </span>
                </div>
              </React.Fragment>
            )}
          </ContentContainer>

          <div
            className="category-page-content-wrapper"
            style={{ display: this.state.isArticlesVisible ? 'none' : '' }}
          >
            <ContentContainer className="category-page-content">
              {!this.props.hideSidebar && (
                <div className="category-page-sidebar">
                  <div className="category-page-sorting">
                    <div className="category-page-filter-sort-container">
                      <Text.div
                        className="category-page-number-of-products"
                        theme={Text.themes.boldUppercaseSmall}
                      >
                        {this.state.numberOfProducts}
                      </Text.div>

                      <div className="category-page-news">
                        {this.props.showNewProductsOnly && (
                          <Checkbox
                            idPrefix="category-show"
                            {...this.props.showNewProductsOnly}
                          />
                        )}
                      </div>

                      {this.props.sorting && (
                        <div className="category-page-sort">
                          <Select
                            inlineLabel={true}
                            theme={Select.themes.simple}
                            {...this.props.sorting}
                          />
                        </div>
                      )}

                      <Button
                        className="category-page-show-filters"
                        onClick={this.showFilters}
                        type="button"
                        text={this.props.showFiltersLabel}
                        themes={[Button.themes.buttonCyan, Button.themes.wide]}
                      />
                    </div>
                  </div>

                  {this.state.filters && (
                    <div
                      className={cn('category-page-filters-wrapper', {
                        'is-visible': this.state.filtersVisible
                      })}
                      ref={div => (this.mobileFilterContainer = div)}
                    >
                      <div className="category-page-filters">
                        <CategoryFilters
                          hideFilters={this.hideFilters}
                          isVisible={this.state.filtersVisible}
                          onReset={this.onReset}
                          {...this.state.filters}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="category-page-products">
                {this.state.productsHeader && (
                  <div className="category-page-products-header">
                    <ProductsHeader {...this.state.productsHeader} />
                  </div>
                )}
                {hasProducts ? (
                  <ProductList
                    theme={[
                      ProductList.themes.narrow,
                      ProductList.themes.noMargin
                    ]}
                    {...this.state.productList}
                  />
                ) : (
                  <EmptyListMessage text={this.props.noResultsMessage} />
                )}
                <div className="category-page-load-more">
                  {this.state.hasMoreProducts &&
                    !this.state.isArticlesVisible && (
                      <Button
                        form={loadMoreFormId}
                        text={this.props.moreProductsLabel}
                        type="submit"
                      />
                    )}
                </div>
                {this.state.paginationControls && (
                  <div className="category-page-pagination">
                    <PaginationControls {...this.state.paginationControls} />
                  </div>
                )}
                {this.state.productsFooter && (
                  <div className="category-page-products-footer">
                    <ProductsFooter {...this.state.productsFooter} />
                  </div>
                )}
              </div>
            </ContentContainer>
          </div>
        </Form>
        <Form
          endpoint={this.state.moreProductsEndpoint}
          id={loadMoreFormId}
          onSubmit={this.onSubmit}
          onResponse={response => this.onResponse(response, false)}
          showSubmitButton={false}
        />
        {this.state.isArticlesVisible && (
          <ContentContainer className="category-page-articles">
            <ImageLinkList {...this.state.articles} />
          </ContentContainer>
        )}
      </div>
    );
  }
}

CategoryPage.themes = themes;

export default CategoryPage;
