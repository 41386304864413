import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import RichText from 'components/rich-text';
import Text from 'components/text';

const Metadata = ({ title, descriptionList, text, link }) => (
  <Text.div className="metadata" theme={Text.themes.small}>
    <Text.h2 theme={Text.themes.h3}>{title}</Text.h2>
    <dl className="metadata-description-list">
      {descriptionList.map(description => (
        <React.Fragment key={description.tag}>
          <dt>{description.tag}</dt>
          <dd>{description.description}</dd>
        </React.Fragment>
      ))}
    </dl>
    <RichText text={text} />
    {link && (
      <div className="metadata-button-wrapper">
        <Link
          className="metadata-button"
          themes={[Link.themes.buttonOutline, Link.themes.arrowRight]}
          {...link}
        />
      </div>
    )}
  </Text.div>
);

Metadata.defaultProps = {
  descriptionList: []
};

Metadata.propTypes = {
  title: PropTypes.string,
  descriptionList: PropTypes.arrayOf(
    PropTypes.exact({
      tag: PropTypes.string,
      description: PropTypes.string
    })
  ),
  text: PropTypes.string,
  link: PropTypes.exact(Link.propTypes)
};

export default Metadata;
