import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Wrapper component that appears during keyboard navigation. Usefull for adding assistive functions like skipping content, etc.

const AssistiveDrawer = ({ description, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <aside
      className={cn('assistive-drawer', {
        'assistive-drawer--open': isOpen
      })}
      aria-label={description}
      tabIndex="-1"
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
    >
      <div className="assistive-drawer__content">{children}</div>
    </aside>
  );
};

AssistiveDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired
};

AssistiveDrawer.propTypesMeta = 'exclude';

export default AssistiveDrawer;
