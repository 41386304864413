import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import analytics from '../../js/analytics';

import Anything from 'components/anything';
import FluidImage from 'components/fluid-image';
import Price from 'components/price';
import Text from 'components/text';

const themes = {
  jumbo: 'theme-jumbo',
  moodboard: 'moodboard'
};

const ProductCard = ({
  analyticsData,
  brand,
  colors,
  colorsTag,
  image,
  price,
  details,
  name,
  url,
  tag,
  theme,
  ctaType
}) => {
  const onProductClick = React.useCallback(
    e => {
      if (!analyticsData) return;
      e.preventDefault();
      analytics.send(analyticsData, () => url && (window.location = url));
    },
    [analyticsData, url]
  );

  const className = cn('product-card', theme);

  const useLinkElement = url;
  const WrapperElement = useLinkElement ? 'a' : 'div';
  const wrapperProps = useLinkElement
    ? {
        'data-test-product-card': '',
        href: url,
        className,
        onClick: onProductClick,
        'data-cta-type': ctaType ? ctaType : null,
        'data-cta-title': name ? name : null
      }
    : { className };

  return (
    <WrapperElement {...wrapperProps}>
      <div className="product-card-content">
        {theme === themes.moodboard ? (
          <div className="product-card-image-wrapper">
            <div className="product-card-image">
              <FluidImage containedPosition="center bottom" {...image} />
            </div>
          </div>
        ) : (
          <div className="product-card-image">
            <FluidImage containedPosition="center bottom" {...image} />
          </div>
        )}
        {tag && (
          <Text className="product-card-tag" theme={Text.themes.filled}>
            {tag}
          </Text>
        )}

        <div className="product-card-details">
          {!!details.length && (
            <ul>
              {details.map(text => (
                <li key={text}>
                  <Text.span theme={Text.themes.small}>{text}</Text.span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="product-card-text">
          <div className="product-card-text-inner">
            <Text
              className="product-card-brand"
              theme={Text.themes.boldUppercaseSmall}
            >
              {brand}
            </Text>
            <Text
              theme={Text.themes.uppercaseSmall}
              className="product-card-name"
            >
              {name}
            </Text>
          </div>

          <div className="product-cart-price">
            <Price {...price} />
          </div>
        </div>

        {colors.length > 0 && (
          <div className="product-card-colors">
            {colors.map(({ name, code, image }) => (
              <div
                aria-label={name}
                className="product-card-color"
                key={code + name}
                style={{
                  backgroundColor: code,
                  backgroundImage: image && `url(${image})`
                }}
              />
            ))}
            <Text.div
              className="product-card-colors-tag"
              theme={Text.themes.small}
            >
              {colorsTag}
            </Text.div>
          </div>
        )}
      </div>
    </WrapperElement>
  );
};

ProductCard.propTypes = {
  analyticsData: PropTypes.shape(Anything.propTypes),
  brand: PropTypes.string,
  colors: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      code: PropTypes.string,
      image: PropTypes.string
    })
  ),
  colorsTag: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes),
  price: PropTypes.exact(Price.propTypes),
  details: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  url: PropTypes.string,
  tag: PropTypes.string,
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  ctaType: PropTypes.string
};

ProductCard.propTypesMeta = {
  theme: 'exclude',
  ctaType: 'exclude'
};

ProductCard.defaultProps = {
  colors: [],
  details: []
};

ProductCard.themes = themes;

export default ProductCard;
