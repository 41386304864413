import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FluidImage from 'components/fluid-image';
import ContentContainer from 'components/content-container';
import TagFilter from 'components/tag-filter';
import MagazineListItem from 'components/magazine-list-item';
import InfiniteScroll from 'react-infinite-scroll-component';

const MagazineLandingPage = ({ heroImage, tagFilter, listItems }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [items, setItems] = useState([...listItems]);
  const [list, setList] = useState([]);
  const numberItemsToLoad = 18;
  const onFelterChange = options => {
    setSelectedTags(options.selectedValues);
  };

  const filterItemsByTags = tagsArr => {
    let tmpList = [...listItems];

    if (tagsArr.length > 0) {
      let filteredList = tmpList.filter(function(item) {
        return tagsArr.indexOf(item.category) > -1;
      });

      setItems(filteredList);
    } else {
      setItems(tmpList);
    }
  };

  const getNthItem = (index, nth) => {
    return index % nth === nth - 1;
  };

  useEffect(() => {
    filterItemsByTags(selectedTags);
  }, [selectedTags]);

  useEffect(() => {
    setList(items.slice(0, numberItemsToLoad));
  }, [items]);

  const fakeFetchMoreData = () => {
    const currentLength = list.length;

    setList(
      list.concat(items.slice(currentLength, numberItemsToLoad + currentLength))
    );
  };

  return (
    <div className="magazine-landing-page">
      {heroImage && (
        <div className="magazine-landing-page__hero">
          <FluidImage {...heroImage} />
        </div>
      )}
      <div className="magazine-landing-page__filter">
        <ContentContainer>
          <TagFilter {...tagFilter} onChange={onFelterChange} />
        </ContentContainer>
      </div>

      <ContentContainer>
        <InfiniteScroll
          dataLength={list.length}
          next={fakeFetchMoreData}
          hasMore={true}
          className="magazine-landing-page__list"
        >
          {list.map((listItem, index) => {
            let textPosition = null;

            if (getNthItem(index, 6)) {
              textPosition = 'left';
            }

            if (getNthItem(index, 12)) {
              textPosition = 'right';
            }

            return (
              <div
                className="magazine-landing-page__list-item"
                key={listItem.id}
              >
                <MagazineListItem {...listItem} theme={textPosition} />
              </div>
            );
          })}
        </InfiniteScroll>
      </ContentContainer>
    </div>
  );
};

MagazineLandingPage.propTypes = {
  heroImage: PropTypes.exact(FluidImage.propTypes),
  tagFilter: PropTypes.exact(TagFilter.propTypes).isRequired,
  listItems: PropTypes.arrayOf(PropTypes.exact(MagazineListItem.propTypes))
};

MagazineLandingPage.defaultProps = {};

export default MagazineLandingPage;
