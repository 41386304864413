import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingSubtitle = props => {
  return <span className="appointment-booking-subtitle">{props.value}</span>;
};

AppointmentBookingSubtitle.propTypes = {
  value: PropTypes.string
};

AppointmentBookingSubtitle.propTypesMeta = 'exclude';

export default AppointmentBookingSubtitle;
