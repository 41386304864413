import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Text from 'components/text';

const SearchResultHeader = ({ label, link }) => (
  <Text.div className="global-search-results-header" theme={Text.themes.small}>
    <Text.span theme={Text.themes.bold}>{label}</Text.span>
    <span aria-hidden={true}>/</span>
    <Link {...link} />
  </Text.div>
);

SearchResultHeader.propTypes = {
  label: PropTypes.string,
  link: PropTypes.exact(Link.propTypes)
};

export default SearchResultHeader;
