import React from 'react';
import PropTypes from 'prop-types';

const AppointmentBookingRegionItem = props => {
  return (
    <div
      className="appointment-booking-region-item"
      onClick={props.onClickHandle}
    >
      <span>{props.name}</span>
    </div>
  );
};

AppointmentBookingRegionItem.propTypes = {
  onClickHandle: PropTypes.func,
  name: PropTypes.string.isRequired
};

AppointmentBookingRegionItem.propTypesMeta = 'exclude';

export default AppointmentBookingRegionItem;
