import React from 'react';
import PropTypes from 'prop-types';

import StoreMap from 'components/store-map';

const MapBlock = ({ storeMap }) => (
  <div className="map-block">
    <StoreMap {...storeMap} mobileViewIsLink={true} />
  </div>
);

MapBlock.propTypes = {
  storeMap: PropTypes.exact(StoreMap.propTypes)
};

export default MapBlock;
