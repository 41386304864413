import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import api from 'js/api-helper';

import CheckoutContext from './checkout-context';

import Button from 'components/button';
import Cart from 'components/cart';
import CheckoutLogin from 'components/checkout-login';
import CheckoutLogout from 'components/checkout-logout';
import CheckoutStep from './checkout-step';
import ContentContainer from '../content-container';
import TermsForm from 'components/terms-form';
import DeliveryForm from 'components/delivery-form';
import PageSpinner from '../page-spinner';

const stepIds = {
  cart: 'cart',
  terms: 'terms',
  login: 'login',
  payment: 'payment'
};

const CheckoutPage = ({
  analyticsEndpoint,
  cart,
  cartButtonText,
  cartTitle,
  collapseLabel,
  terms,
  delivery,
  paymentTitle,
  termsTitle,
  expandLabel,
  isLoggedIn,
  logout,
  login,
  loginTitle,
  title
}) => {
  const [hasIframeHTML, setHasIframeHTML] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [stepCompletenessMap, setStepCompletnessMap] = React.useState({
    [stepIds.cart]: false,
    [stepIds.terms]: false,
    [stepIds.payment]: false
  });

  const stepIsComplete = stepId => stepCompletenessMap[stepId];
  const setStepComplete = React.useCallback((stepId, stepNumber) => {
    // NOTE: `stepNumber` is the number of the completed step (the previously active step). The analytics endpoint is interested in what the currently active step is, which is the step after `stepNumber`.
    analyticsEndpoint &&
      api.execute(analyticsEndpoint, { currentActiveStep: stepNumber + 1 });
    setStepCompletnessMap(validities => ({ ...validities, [stepId]: true }));
  }, []);

  const onCartUpdate = React.useCallback(() => {
    // If the Klarna iframe has been loaded, the page needs to be refreshed when the cart changes in order to update the cart in the iframe.
    hasIframeHTML && window.location.reload();
  }, [hasIframeHTML]);

  return (
    <CheckoutContext.Provider
      value={{ isLoading, setIsLoading, hasIframeHTML, setHasIframeHTML }}
    >
      <PageSpinner isLoading={isLoading} />

      <div className="checkout-page">
        <ContentContainer>
          <h1>{title}</h1>
          <CheckoutStep
            collapseLabel={collapseLabel}
            expandLabel={expandLabel}
            isComplete={stepIsComplete(stepIds.cart)}
            isEnabled={get(cart, 'products', []).length > 0}
            number={1}
            theme={CheckoutStep.themes.padding}
            title={cartTitle}
          >
            <React.Fragment>
              <Cart {...cart} onUpdate={onCartUpdate} />
              <Button
                data-test-checkout-step-1-button
                onClick={() => setStepComplete(stepIds.cart, 1)}
                iconAfter="chevron-right"
                text={cartButtonText}
              />
            </React.Fragment>
          </CheckoutStep>
          {login && (
            <CheckoutStep
              collapseLabel={collapseLabel}
              expandLabel={expandLabel}
              forceInitialAnimation={true}
              isEnabled={stepIsComplete(stepIds.cart)}
              isComplete={stepIsComplete(stepIds.login)}
              number={2}
              theme={CheckoutStep.themes.padding}
              title={loginTitle}
            >
              {isLoggedIn ? (
                <CheckoutLogout
                  onComplete={() => setStepComplete(stepIds.login, 2)}
                  {...logout}
                />
              ) : (
                <CheckoutLogin
                  onComplete={didLoginOrRegister => {
                    setStepComplete(stepIds.login, 2);
                    didLoginOrRegister &&
                      hasIframeHTML &&
                      window.location.reload();
                  }}
                  {...login}
                />
              )}
            </CheckoutStep>
          )}
          {terms && (
            <CheckoutStep
              collapseLabel={collapseLabel}
              expandLabel={expandLabel}
              forceInitialAnimation={true}
              isEnabled={stepIsComplete(stepIds.login)}
              isComplete={stepIsComplete(stepIds.terms)}
              number={3}
              theme={CheckoutStep.themes.padding}
              title={termsTitle}
            >
              <TermsForm
                {...terms}
                onResponse={() => setStepComplete(stepIds.terms, 3)}
              />
            </CheckoutStep>
          )}
          <CheckoutStep
            collapseLabel={collapseLabel}
            expandLabel={expandLabel}
            forceInitialAnimation={true}
            theme={CheckoutStep.themes.padding}
            isEnabled={stepIsComplete(stepIds.terms)}
            isComplete={false}
            number={4}
            title={paymentTitle}
          >
            <DeliveryForm {...delivery} />
          </CheckoutStep>
        </ContentContainer>
      </div>
    </CheckoutContext.Provider>
  );
};

CheckoutPage.propTypes = {
  cart: PropTypes.exact(Cart.propTypes),
  analyticsEndpoint: PropTypes.string,
  cartButtonText: PropTypes.string,
  cartTitle: PropTypes.string,
  collapseLabel: PropTypes.string,
  terms: PropTypes.exact(TermsForm.propTypes),
  termsTitle: PropTypes.string,
  paymentTitle: PropTypes.string,
  delivery: PropTypes.exact(DeliveryForm.propTypes),
  expandLabel: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  klarnaTitle: PropTypes.string,
  logout: PropTypes.exact(CheckoutLogout.propTypes),
  login: PropTypes.exact(CheckoutLogin.propTypes),
  loginTitle: PropTypes.string,
  title: PropTypes.string
};

export default CheckoutPage;
