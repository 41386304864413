import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const KlarnaIframe = ({ htmlString, shouldEvalScript }) => {
  const container = useRef();

  useEffect(() => {
    if (shouldEvalScript) {
      // Klarna has decided to include script tags in their returned HTML. On the checkout page the HTML is returned from an API call and we are forced to assume the risk of evaluating these network-delivered script tags by finding them all and adding them to the DOM manually (since setting innerHTML does not evaluate scripts for good reasons).
      const scriptsTags = container.current.getElementsByTagName('script');

      for (var i = 0; i < scriptsTags.length; i++) {
        const parentNode = scriptsTags[i].parentNode;
        const newScriptTag = document.createElement('script');
        newScriptTag.type = 'text/javascript';
        newScriptTag.text = scriptsTags[i].text;
        parentNode.removeChild(scriptsTags[i]);
        parentNode.appendChild(newScriptTag);
      }
    }
  }, []);

  return (
    <div
      ref={container}
      className="klarna-iframe"
      data-test-klarna-iframe
      dangerouslySetInnerHTML={{ __html: htmlString }}
    />
  );
};

KlarnaIframe.propTypes = {
  htmlString: PropTypes.string,
  shouldEvalScript: PropTypes.bool
};

KlarnaIframe.propTypesMeta = 'exclude';

export default KlarnaIframe;
