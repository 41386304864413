import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Form from 'components/form';
import TextInput from 'components/text-input';
import Link from 'components/link';
import cn from 'classnames';

const colors = {
  cyan: 'cyan',
  gray: 'gray',
  orange: 'orange',
  cold: 'cold',
  clinical: 'clinical',
  blue: 'blue',
  nude: 'nude',
  mint: 'mint',
  purple: 'purple'
};

const NewsletterForm = ({ endpoint, submitLabel, input, termsLink, color }) => (
  <div
    className={cn('newsletter-form', {
      [`newsletter-form--${colors[color]}`]: colors[color]
    })}
  >
    <Form endpoint={endpoint} showSubmitButton={false}>
      <div className="newsletter-form__body">
        <TextInput
          className="newsletter-form__input"
          type="email"
          autocomplete="email"
          theme={[TextInput.themes.hiddenLabel, TextInput.themes.noValidation]}
          {...input}
        />
        <div className="newsletter-form__button-container">
          <Button
            type="submit"
            className="newsletter-form__button"
            themes={[Button.themes.buttonCyanSmall]}
          >
            {submitLabel}
          </Button>
        </div>
      </div>
    </Form>

    {termsLink && (
      <Link
        className="newsletter-form__terms-link"
        themes={[Link.themes.linkWhite]}
        {...termsLink}
      />
    )}
  </div>
);

NewsletterForm.propTypes = {
  input: PropTypes.exact(TextInput.propTypes),
  endpoint: PropTypes.string,
  submitLabel: PropTypes.string,
  termsLink: PropTypes.exact(Link.propTypes),
  color: PropTypes.oneOf(Object.values(colors))
};

NewsletterForm.propTypesMeta = {
  color: 'exclude'
};

export default NewsletterForm;
