import React from 'react';
import PropTypes from 'prop-types';

import Clickable from 'components/clickable';

// NOTE: Any valid DOM prop is a valid prop for Link
const Link = React.forwardRef(
  (
    {
      children,
      className,
      hiddenText,
      text,
      url,
      themes,
      isNoFollow,
      ctaTitle,
      ctaType,
      ...restProps
    },
    ref
  ) => {
    return (
      <Clickable
        {...restProps}
        aria-label={hiddenText ? text : undefined}
        className={className}
        element={url ? 'a' : 'span'}
        href={url}
        ref={ref}
        rel={isNoFollow ? 'nofollow' : undefined}
        themes={themes}
        ctaType={ctaType}
        ctaTitle={ctaTitle}
      >
        {hiddenText ? null : text}
        {children}
      </Clickable>
    );
  }
);

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hiddenText: PropTypes.bool,
  isNoFollow: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string,
  themes: PropTypes.any,
  ctaTitle: PropTypes.string,
  ctaType: PropTypes.string
};

Link.propTypesMeta = {
  className: 'exclude',
  hiddenText: 'exclude',
  themes: 'exclude',
  ctaTitle: 'exclude',
  ctaType: 'exclude'
};

Link.defaultProps = {
  themes: [Clickable.themes.link]
};

// NOTE: Show 'Link' in error messages instead of 'forwardRef'
Link.displayName = 'Link';

Link.themes = Clickable.themes;

export default Link;
