import React from 'react';
import PropTypes from 'prop-types';
import AppointmentBookingRegionItem from 'components/appointment-booking-modal/appointment-booking-region-item';

const AppointmentBookingRegionList = props => {
  return (
    <div className="appointment-booking-region-list">
      {props.regions.map((item, index) => {
        return (
          <AppointmentBookingRegionItem
            key={item.id}
            name={item.name}
            onClickHandle={() => {
              props.onClickRegion(index);
            }}
          />
        );
      })}
    </div>
  );
};

AppointmentBookingRegionList.propTypes = {
  onClickRegion: PropTypes.func,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired, // NOTE: Used as key in region list
      // shops neede for BE json structure
      shops: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired, // NOTE: Used as key in shop list
          address: PropTypes.string,
          postalCode: PropTypes.string,
          latitude: PropTypes.string,
          longitude: PropTypes.string
        })
      )
    })
  )
};

export default AppointmentBookingRegionList;
