import googleMapsLoader from 'js/google-maps-loader';
import getPlaceMatches from './get-place-matches';

const removeDuplicates = arr =>
  arr.reduce(
    (accum, value) => (accum.includes(value) ? accum : accum.concat(value)),
    []
  );

// Returns a filter function that takes a search term and a search callback
export default function(APIKey, stores) {
  return new Promise(resolve => {
    googleMapsLoader(APIKey).then(google => {
      const geocoder = new google.maps.Geocoder();

      // Return filter function
      resolve(searchTerm => {
        return new Promise(resolve => {
          if (!searchTerm) {
            resolve(stores);
            return;
          }

          const nameMatches = stores.filter(({ name }) =>
            name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          geocoder.geocode(
            { address: searchTerm, componentRestrictions: { country: 'NO' } },
            data => {
              const googleMatches = getPlaceMatches(google, stores, data);

              const allMatches = removeDuplicates(
                [].concat(nameMatches, googleMatches)
              );

              return resolve(allMatches);
            }
          );
        });
      });
    });
  });
}
