import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const TagItem = ({ id, value, label, isSelected, onChange, isDisabled }) => {
  const handleChange = () => {
    onChange({ value: value });
  };

  return (
    <div className="tag-item">
      <button
        id={id}
        type="button"
        className={cn('tag-item__label', {
          'tag-item__label--checked': isSelected
        })}
        onClick={handleChange}
        disabled={isDisabled}
      >
        {label}
      </button>
    </div>
  );
};

TagItem.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

TagItem.propTypesMeta = {
  inputName: 'exclude',
  id: 'exclude',
  isDisabled: 'exclude'
};

export default TagItem;
