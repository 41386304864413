import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';

const ImageContainer = ({ children, onClick }) => (
  <div className="product-image-container">
    <div className="product-image-hover-icon" onClick={onClick}>
      <Icon name="zoom" />
    </div>
    {children}
  </div>
);

ImageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

ImageContainer.propTypesMeta = 'exclude';

export default ImageContainer;
