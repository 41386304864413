import React from 'react';
import PropTypes from 'prop-types';

import Optician from './optician';
import ContentContainer from '../content-container';
import Text from '../text';

const OpticiansList = ({
  title,
  opticians,
  aboutTextThresholdInNumberOfChars
}) => {
  if (!opticians) return null;

  return (
    <ContentContainer>
      <div className="opticians-list">
        <div className="opticians-list-heading">
          <Text.h2 theme={Text.themes.boldUppercase}>{title}</Text.h2>
        </div>
        <ul className="opticians-list-list">
          {opticians.map((optician, index) => (
            <li className="opticians-list-item" key={index}>
              <Optician
                {...optician}
                textThresholdInNumberOfChars={aboutTextThresholdInNumberOfChars}
              />
            </li>
          ))}
        </ul>
      </div>
    </ContentContainer>
  );
};

OpticiansList.propTypes = {
  opticians: PropTypes.arrayOf(PropTypes.exact(Optician.propTypes)).isRequired,
  title: PropTypes.string,
  aboutTextThresholdInNumberOfChars: PropTypes.number
};

export default OpticiansList;
