import React from 'react';

import Form from 'components/form';

export default function useFormValidation(name, validations) {
  const {
    attachInput,
    detachInput,
    errors,
    onChange,
    dirtyFields
  } = React.useContext(Form.Context);

  const isDirty = dirtyFields[name];
  const errorMessage = isDirty ? errors[name] : undefined;
  const validate = () => onChange(name);

  // NOTE: Do NOT use 'validations' as a dependency here. You'll create an infinite update loop.
  React.useEffect(() => {
    attachInput(name, validations);
    return () => detachInput(name);
  }, [name]);

  return [errorMessage, validate, isDirty];
}
