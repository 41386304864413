import React from 'react';
import PropTypes from 'prop-types';

import TinyTransition from 'react-tiny-transition';
import Icon from '../icon';

import CollapsableRichText from '../collapsable-rich-text';
import Link from '../link';

const Banner = ({ title, richText, onClick, link, buttonText }) => {
  return (
    <div className="site-info-banner">
      <TinyTransition
        duration={300}
        disableInitialAnimation={true}
        classNames={{
          beforeEnter: 'site-info-banner-before-enter',
          entering: 'site-info-banner-entering',
          beforeLeave: 'site-info-banner-before-leave',
          leaving: 'site-info-banner-leaving'
        }}
      >
        <div className="site-info-banner-wrapper">
          <div className="site-info-banner-content">
            {title && (
              <>
                <div className="site-info-banner-close">
                  <button
                    className="site-info-banner-close-button"
                    onClick={onClick}
                  >
                    <span className="site-info-banner-close-text">
                      {buttonText}
                    </span>
                    <span className="site-info-banner-close-icon">
                      <Icon name="x" />
                    </span>
                  </button>
                </div>
                <h2 className="site-info-banner-header">{title}</h2>
              </>
            )}
            {richText && (
              <CollapsableRichText
                {...richText}
                onCloseClick={onClick}
                showInlineCloseButton={!title}
                closeButtonText={buttonText}
              >
                {link && link.url && (
                  <div className="site-info-banner-link">
                    <Link
                      {...link}
                      themes={[Link.themes.buttonWhite, Link.themes.arrowRight]}
                    />
                  </div>
                )}
              </CollapsableRichText>
            )}
          </div>
        </div>
      </TinyTransition>
    </div>
  );
};

Banner.propTypesMeta = 'exclude';

Banner.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  richText: PropTypes.exact(CollapsableRichText.propTypes),
  buttonText: PropTypes.string,
  cookieExpirationTimeInSeconds: PropTypes.number,
  link: PropTypes.exact(Link.propTypes)
};

export default Banner;
