import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/text';

const Appointments = ({ items, text }) => (
  <div className="appointments">
    {items && (
      <dl>
        {items.map(({ label, value }) => (
          <React.Fragment key={label + value}>
            <Text element="dt" theme={Text.themes.small}>
              {label}
            </Text>
            <dd>{value}</dd>
          </React.Fragment>
        ))}
      </dl>
    )}

    {text && <Text.p theme={Text.themes.small}>{text}</Text.p>}
  </div>
);

Appointments.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  text: PropTypes.string
};

Appointments.defaultProps = {
  items: []
};

export default Appointments;
