import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';
import Text from 'components/text';

const ReplacementProductBanner = ({
  image,
  productName,
  productType,
  text,
  title
}) => (
  <div className="replacement-product-banner">
    <Text.h2 theme={Text.themes.h2}>{title}</Text.h2>
    <Text.h3 theme={Text.themes.h3}>{productName}</Text.h3>
    <p className="replacement-product-banner-type">{productType}</p>
    <p className="replacement-product-banner-text">{text}</p>
    {image && (
      <div className="replacement-product-banner-image">
        <Image {...image} />
      </div>
    )}
  </div>
);

ReplacementProductBanner.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  productName: PropTypes.string,
  productType: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

export default ReplacementProductBanner;
