import React from 'react';
import PropTypes from 'prop-types';

import Error404Svg from './error-404-svg';
import Error500Svg from './error-500-svg';
import Icon from 'components/icon';

import css from './error-page.css';

const ErrorPage = ({ linkUrl, linkText, text, title, isServerError }) => (
  <React.Fragment>
    <style>{css}</style>
    <div className="error-page">
      <div className="error-page-image">
        {isServerError ? <Error500Svg /> : <Error404Svg />}
      </div>
      {title && <h1>{title}</h1>}
      {text && <p>{text}</p>}
      {linkUrl && (
        <a href={linkUrl}>
          {linkText || linkUrl}
          <Icon name="arrow-right" />
        </a>
      )}
    </div>
  </React.Fragment>
);

ErrorPage.propTypesMeta = {
  isServerError: 'exclude'
};

ErrorPage.propTypes = {
  linkUrl: PropTypes.string,
  isServerError: PropTypes.bool,
  linkText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

export default ErrorPage;
