import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const AppointmentBookingResultShop = props => {
  return (
    <div
      className="appointment-booking-result-shop"
      onClick={props.onClickHandle}
    >
      <div className="appointment-booking-result-shop__pin-box">
        <Icon name="pin" />
      </div>
      <strong className="appointment-booking-result-shop__title">
        {props.shop.name}
      </strong>
      <span className="appointment-booking-result-shop__description">
        {props.shop.address}
      </span>
      <div className="appointment-booking-result-shop__edit-box">
        <Icon name="icon-edit" />
      </div>
    </div>
  );
};

AppointmentBookingResultShop.propTypes = {
  onClickHandle: PropTypes.func,
  shop: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string
  })
};

AppointmentBookingResultShop.propTypesMeta = 'exclude';

export default AppointmentBookingResultShop;
