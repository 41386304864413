import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FluidImage from '../fluid-image';
import Link from '../link';
import Text from '../text';
import MagazineCategory from 'components/magazine-category/magazine-category';

const themes = {
  left: 'left',
  right: 'right'
};

const InspirationalItem = ({
  titleText,
  titleUrl,
  leadText,
  imageCreditText,
  image,
  link,
  theme,
  category,
  className
}) => {
  return (
    <div
      className={cn('inspirational-item', className, {
        [`inspirational-item--${themes[theme]}`]: themes[theme]
      })}
    >
      <div className="inspirational-item__content">
        <div className="inspirational-item__image">
          <div className="inspirational-item__image-container">
            {React.createElement(
              link || titleUrl ? 'a' : 'div',
              { href: (link && link.url) || titleUrl },
              <React.Fragment>
                <FluidImage {...image} />
                {category && (
                  <MagazineCategory
                    className="inspirational-item__category inspirational-item__category--over-image"
                    text={category}
                  />
                )}
              </React.Fragment>
            )}
          </div>
          {imageCreditText && (
            <div className="inspirational-item__image-credit">
              <Text.span theme={Text.themes.small}>{imageCreditText}</Text.span>
            </div>
          )}
        </div>
        <div className="inspirational-item__text">
          <div className="inspirational-item__text-content">
            {category && (
              <MagazineCategory
                className="inspirational-item__category inspirational-item__category--above-title"
                text={category}
              />
            )}
            {titleText && (
              <div className="inspirational-item__title">
                {titleUrl ? (
                  <Link
                    url={titleUrl}
                    text={titleText}
                    themes={[Link.themes.uppercase]}
                  />
                ) : (
                  <Text.span theme={Text.themes.uppercaseSmall}>
                    {titleText}
                  </Text.span>
                )}
              </div>
            )}
            {leadText && (
              <div className="inspirational-item__lead">
                <Text.span theme={Text.themes.small}>{leadText}</Text.span>
              </div>
            )}
            {link && (
              <div className="inspirational-item__link">
                <Link
                  themes={[Link.themes.uppercase, Link.themes.arrowRight]}
                  {...link}
                  ctaType="inspirational-items"
                  ctaTitle={titleText}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

InspirationalItem.propTypes = {
  titleText: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(themes)),
  titleUrl: PropTypes.string,
  imageCreditText: PropTypes.string,
  leadText: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes),
  link: PropTypes.exact(Link.propTypes),
  category: PropTypes.string,
  className: PropTypes.string
};

InspirationalItem.propTypesMeta = {
  category: 'exclude',
  className: 'exclude'
};

InspirationalItem.themes = themes;

export default InspirationalItem;
