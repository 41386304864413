import { useEffect } from 'react';
import isRunningOnClient from '@creuna/utils/is-running-on-client';
import chatScript from '!raw-loader!./script.js';

const Chat = () => {
  if (!isRunningOnClient) return null;

  let script = '';
  let style = '';

  const createScriptElement = () => {
    const scriptElement = document.createElement('script');

    scriptElement.innerHTML = chatScript;
    scriptElement.async = true;

    return scriptElement;
  };

  const createStyleElement = () => {
    const styleElement = document.createElement('style');

    styleElement.innerHTML = `
    @media (max-width: 700px) {
      #magic-widget-launcher-parent { bottom: 25px !important; }
    }`;
    styleElement.async = true;

    return styleElement;
  };

  useEffect(() => {
    script = createScriptElement();
    style = createStyleElement();

    document.body.appendChild(script);
    document.body.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(style);
    };
  }, []);

  return null;
};

Chat.propTypesMeta = 'exclude';

export default Chat;
