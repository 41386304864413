import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const ContainedImage = ({ alt, className, position, src }) => {
  return !src ? null : (
    <div
      className={cn('contained-image', className)}
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: position
      }}
    >
      <img src={src} alt={alt} />
    </div>
  );
};

ContainedImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  position: PropTypes.string,
  src: PropTypes.string
};

ContainedImage.propTypesMeta = 'exclude';

export default ContainedImage;
