import React from 'react';
import PropTypes from 'prop-types';
import ReserveStoresListItem from './reserve-stores-list-item';
import ReserveInStoreForm from './reserve-in-store-form';

const ReserveStoresList = ({
  storeLabels,
  stores,
  reservetionErrorMessageText,
  reservetionConfirmationText,
  reserveInStoreButtonText,
  reserveForm,
  posId
}) => {
  const [storeList, setStoreList] = React.useState([]);

  React.useEffect(() => {
    let storesWithProduct = stores.filter(item => item.productInStock === true);
    let storesWithoutProduct = stores.filter(
      item => item.productInStock !== true
    );
    //stores with product are shown first in the list
    setStoreList(storesWithProduct.concat(storesWithoutProduct));
  }, [stores]);

  return (
    <div className="reserve-stores-list">
      {storeList.map((store, i) => (
        <ReserveStoresListItem
          key={store.email + i}
          {...store}
          storeLabels={storeLabels}
          reserveForm={reserveForm}
          reservetionErrorMessageText={reservetionErrorMessageText}
          reserveInStoreButtonText={reserveInStoreButtonText}
          reservetionConfirmationText={reservetionConfirmationText}
          posId={posId}
        />
      ))}
    </div>
  );
};

ReserveStoresList.propTypes = {
  storeLabels: PropTypes.shape({
    address: PropTypes.string,
    hours: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    inStock: PropTypes.string,
    outOfStock: PropTypes.string
  }),
  posId: PropTypes.string.isRequired,
  stores: PropTypes.arrayOf(PropTypes.exact(ReserveStoresListItem.propTypes)),
  reservetionErrorMessageText: PropTypes.string,
  reservetionConfirmationText: PropTypes.string,
  reserveInStoreButtonText: PropTypes.string,
  reserveForm: PropTypes.exact(ReserveInStoreForm.propTypes)
};

ReserveStoresList.propTypesMeta = {
  stores: 'exclude' // NOTE: stores are provided by FindStorePage
};

ReserveStoresList.defaultProps = {
  stores: [],
  storeLabels: {}
};

export default ReserveStoresList;
