import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../grid';
import ContentContainer from '../content-container';
import GlassesHeader from './glasses-header';
import GlassesMeasurement from './glasses-measurement';
import LensVisualisation from '../lens-visualisation';
import MainTitle from 'components/main-title';
import Metadata from '../metadata';
import ProductListBlock from '../product-list-block';
import ProductText from 'components/product-text';

class GlassesPage extends React.Component {
  static propTypes = {
    frameMetadata: PropTypes.exact(Metadata.propTypes),
    glassesMeasurement: PropTypes.exact(GlassesMeasurement.propTypes),
    header: PropTypes.exact(GlassesHeader.propTypes),
    productTextHtml: PropTypes.string,
    lensMetadata: PropTypes.exact(Metadata.propTypes),
    lensVisualisation: PropTypes.exact(LensVisualisation.propTypes),
    relatedProducts: PropTypes.exact(ProductListBlock.propTypes),
    title: PropTypes.string,
    productVariantText: PropTypes.string
  };

  state = {
    ...this.props
  };

  updateSize = newState => {
    this.setState({ ...newState });
  };

  render() {
    return (
      <div className="glasses-page">
        <ContentContainer>
          <MainTitle title={this.props.title} theme={MainTitle.themes.small} />

          <GlassesHeader
            onUpdate={this.updateSize}
            {...this.state.header}
            productVariantText={this.state.productVariantText}
          />
          {this.props.productTextHtml && (
            <ProductText textHtml={this.props.productTextHtml} />
          )}

          <Grid
            className="glasses-page-content"
            theme={Grid.themes.rightColumnWider}
          >
            <Grid.Column>
              <Metadata {...this.state.frameMetadata} />
            </Grid.Column>
            <Grid.Column>
              <GlassesMeasurement {...this.state.glassesMeasurement} />
            </Grid.Column>
          </Grid>
          <Grid
            className="glasses-page-content"
            theme={Grid.themes.leftColumnWider}
          >
            <Grid.Column>
              {this.state.lensVisualisation && (
                <LensVisualisation {...this.state.lensVisualisation} />
              )}
            </Grid.Column>
            <Grid.Column>
              {this.state.lensMetadata && (
                <Metadata {...this.state.lensMetadata} />
              )}
            </Grid.Column>
          </Grid>
        </ContentContainer>

        <ContentContainer className="glasses-page-bottom-content">
          {this.state.relatedProducts && (
            <ProductListBlock {...this.state.relatedProducts} />
          )}
        </ContentContainer>
      </div>
    );
  }
}

export default GlassesPage;
