import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Checkbox from 'components/checkbox';
import Icon from 'components/icon';
import HiddenInput from 'components/hidden-input';

const AppointmentBookingAgreeBox = props => {
  const [infoState, setInfoState] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState('false');

  const onChangeHandler = checked => {
    setCheckboxValue(checked.toString());
    props.onClickAgree(checked, props.label);
  };

  const onClickHandler = () => {
    setInfoState(prevState => !prevState);
  };

  return (
    <div className={cn('appointment-booking-agree-box', props.className)}>
      <Checkbox
        idPrefix="appointment-booking"
        name="agree"
        label={props.label}
        onChange={onChangeHandler}
      />
      <div
        className={cn('appointment-booking-agree-box__info-icon-holder', {
          'appointment-booking-agree-box__info-icon-holder--checked': infoState
        })}
        onClick={onClickHandler}
      >
        <Icon
          className="appointment-booking-agree-box__info-icon"
          name="icon-info"
        />
      </div>

      <HiddenInput name={props.name} value={checkboxValue} />
      {infoState && (
        <div className="appointment-booking-agree-box__info">
          <div
            className="appointment-booking-agree-box__close-icon-holder"
            onClick={onClickHandler}
          >
            <Icon
              className="appointment-booking-agree-box__close-icon"
              name="x"
            />
          </div>
          <span className="appointment-booking-agree-box__info-title">
            {props.infoTitle}
          </span>
          <span className="appointment-booking-agree-box__info-description">
            {props.infoDescription}
          </span>
        </div>
      )}
    </div>
  );
};

AppointmentBookingAgreeBox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  infoTitle: PropTypes.string,
  infoDescription: PropTypes.string,
  name: PropTypes.string,
  onClickAgree: PropTypes.func
};

AppointmentBookingAgreeBox.propTypesMeta = {
  className: 'exclude'
};

export default AppointmentBookingAgreeBox;
