import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Collapse from 'react-tiny-collapse';

import useToggle from 'hooks/use-toggle';

import Icon from 'components/icon';
import OnPageEditing from 'components/on-page-editing';
import RichText from 'components/rich-text';

const Accordion = ({ id, OPE, text, title, isNarrow }) => {
  const [htmlId, setHtmlId] = React.useState(null);
  const [isExpanded, toggle] = useToggle(false);

  React.useEffect(() => {
    // Setting the id inside of a requestAnimationFrame callback seems to ensure that no two Accordions get the same id.
    requestAnimationFrame(() => {
      setHtmlId(new Date().getTime());
    });
  }, []);

  return (
    <div
      className={cn('accordion', { 'accordion--narrow': isNarrow })}
      key={id}
    >
      <button
        aria-controls={htmlId}
        aria-expanded={isExpanded}
        className={cn({ 'is-active': isExpanded })}
        onClick={toggle}
        type="button"
      >
        <div className="accordion-header">
          <OnPageEditing name={OPE.title}>
            <span
              className={cn('accordion-header-text', {
                'accordion-header-text--active': isExpanded
              })}
            >
              {title}
            </span>
          </OnPageEditing>
          <div className="accordion-icon">
            <Icon name="chevron-down" />
          </div>
        </div>
      </button>

      <Collapse componentProps={{ id: htmlId }} isOpen={isExpanded}>
        <OnPageEditing name={OPE.text}>
          <div className="accordion-content">
            <RichText
              {...text}
              hasBottomMargin={false}
              theme={RichText.themes.withinCollapsable}
            />
          </div>
        </OnPageEditing>
      </Collapse>
    </div>
  );
};

Accordion.propTypes = {
  id: PropTypes.string, // NOTE: Used as key in AccordionList
  isNarrow: PropTypes.bool,
  OPE: PropTypes.exact({
    text: PropTypes.string,
    title: PropTypes.string
  }),
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string
};

Accordion.propTypesMeta = {
  isNarrow: 'exclude'
};

Accordion.defaultProps = {
  OPE: {}
};

export default Accordion;
