import React from 'react';
import PropTypes from 'prop-types';

import Image from '../image';
import { glassesMeasurementId } from '../../js/local-ids.json';

const GlassesMeasurement = ({ items }) => (
  <div className="glasses-measurement-wrapper" id={glassesMeasurementId}>
    {items.map(({ image, measures = [] } = {}) => (
      <div key={image} className="glasses-measurement">
        <Image responsive={false} src={image} />
        <div className="glasses-measurement-text-wrapper">
          {measures.map(({ label, value } = {}) => (
            <div className="glasses-measurement-text" key={label}>
              <span>{label}</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

GlassesMeasurement.defaultProps = {
  items: []
};

GlassesMeasurement.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      image: PropTypes.string,
      measures: PropTypes.arrayOf(
        PropTypes.exact({
          label: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  )
};

export default GlassesMeasurement;
