import PropTypes from 'prop-types';

const MapMarker = () => {};

MapMarker.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired
};

export default MapMarker;
