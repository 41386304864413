import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';
import RichText from '../rich-text';
import Text from 'components/text';

const MyPageSection = ({
  children,
  headingLevel,
  ingress,
  label,
  link,
  links,
  title
}) => {
  return (
    <div className="my-page-section">
      <div className="my-page-section-header">
        <Text element={'h' + headingLevel} theme={Text.themes.h3}>
          {title}
        </Text>
        {ingress && (
          <RichText className="my-page-section-ingress" {...ingress} />
        )}

        {label && <p>{label}</p>}
        {link && (
          <Text.span theme={Text.themes.small}>
            <Link {...link} />
          </Text.span>
        )}

        {links.length > 0 &&
          links.map(link => (
            <Link iconAfter="chevron-right" key={link.url} {...link} />
          ))}
      </div>
      {children}
    </div>
  );
};

MyPageSection.propTypes = {
  children: PropTypes.node,
  headingLevel: PropTypes.number,
  ingress: PropTypes.exact(RichText.propTypes),
  label: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  title: PropTypes.string
};

MyPageSection.propTypesMeta = {
  headingLevel: 'exclude'
};

MyPageSection.defaultProps = {
  headingLevel: 2,
  links: []
};

export default MyPageSection;
