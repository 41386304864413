import React from 'react';
import PropTypes from 'prop-types';
import WithModalProvider from '../../hoc/with-modal-provider';
import MagazineHeaderSearch from './magazine-header-search';
import ContentContainer from 'components/content-container';
import LinkToMainSite from 'components/link-to-main-site';
import Logo from 'components/logo';
import Link from 'components/link';
import AppointmentBookingModal from 'components/appointment-booking-modal/appointment-booking-modal';
import MagazineHeaderAppointment from './magazine-header-appointment';
import MagazineHeaderCTA from './magazine-header-cta';

const MagazineHeader = ({
  linkToMainSite,
  logo,
  headerSearch,
  findStoreLink,
  bookAppointmentLink,
  appointmentBookingModalData
}) => {
  return (
    <div className="magazine-header">
      <div className="magazine-header__top-content">
        <ContentContainer className="magazine-header__top-content-inner">
          {linkToMainSite && (
            <LinkToMainSite
              {...linkToMainSite}
              className="magazine-header__back-link link-to-main-site--in-header"
            />
          )}

          <WithModalProvider>
            {(findStoreLink || bookAppointmentLink) && (
              <div className="magazine-header__cta-links">
                {findStoreLink && <MagazineHeaderCTA {...findStoreLink} />}
                {bookAppointmentLink && (
                  <MagazineHeaderAppointment
                    appointmentBookingModalData={appointmentBookingModalData}
                    bookAppointmentLink={bookAppointmentLink}
                  />
                )}
              </div>
            )}
            <MagazineHeaderSearch {...headerSearch} />
          </WithModalProvider>
        </ContentContainer>
      </div>
      {logo && logo.link && (
        <ContentContainer className="magazine-header__bottom-content">
          <Logo
            {...logo}
            className="magazine-header__logo"
            imageClassName="magazine-header__logo-image"
          />
        </ContentContainer>
      )}
    </div>
  );
};

MagazineHeader.propTypes = {
  linkToMainSite: PropTypes.exact(LinkToMainSite.propTypes).isRequired,
  bookAppointmentLink: PropTypes.exact(Link.propTypes),
  findStoreLink: PropTypes.exact(Link.propTypes),
  appointmentBookingModalData: PropTypes.exact(
    AppointmentBookingModal.propTypes
  ),
  logo: PropTypes.exact(Logo.propTypes).isRequired,
  headerSearch: PropTypes.exact(MagazineHeaderSearch.propTypes)
};

MagazineHeader.defaultProps = {};

export default MagazineHeader;
