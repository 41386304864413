import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'components/grid';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import Form from 'components/form';
import PageSpinner from 'components/page-spinner';
import PersonalInformationForm from '../personal-information-form';

const PersonalInformationPage = ({
  form,
  formContent,
  layout,
  section,
  showDeliveryAddress
}) => {
  return (
    <MyPageLayout {...layout}>
      <Grid theme={Grid.themes.leftColumnWider}>
        <Grid.Column>
          <MyPageSection headingLevel={1} {...section}>
            <Form showSubmitButton={true} {...form}>
              {({ isLoading }) => (
                <React.Fragment>
                  <PageSpinner
                    shouldContainInParent={true}
                    isLoading={isLoading}
                  />

                  <PersonalInformationForm
                    showDeliveryAddress={showDeliveryAddress}
                    showDeliveryAddressCheckbox={false}
                    {...formContent}
                  />
                </React.Fragment>
              )}
            </Form>
          </MyPageSection>
        </Grid.Column>
      </Grid>
    </MyPageLayout>
  );
};

PersonalInformationPage.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  formContent: PropTypes.exact(PersonalInformationForm.propTypes),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes),
  showDeliveryAddress: PropTypes.bool
};

PersonalInformationPage.propTypesMeta = {
  showDeliveryAddress: 'exclude'
};

export default PersonalInformationPage;
