import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Grid from 'components/grid';
import Link from 'components/link';
import MyPageLayout from 'components/my-page-layout';
import MyPageSection from 'components/my-page-section';
import Form from 'components/form';
import Checkbox from 'components/checkbox';
import PageSpinner from 'components/page-spinner';
import Text from 'components/text';

const ConsentPage = ({
  form,
  abortLink,
  consentGroups,
  layout,
  section,
  submitLabel
}) => (
  <MyPageLayout {...layout}>
    <Grid theme={Grid.themes.leftColumnWider}>
      <Grid.Column>
        <MyPageSection headingLevel={1} {...section}>
          <Form {...form}>
            {({ isLoading }) => (
              <React.Fragment>
                <PageSpinner
                  shouldContainInParent={true}
                  isLoading={isLoading}
                />

                {consentGroups.map(({ title, options = [] }) => (
                  <div className="consent-checkbox-group" key={title}>
                    <Text.h2 theme={Text.themes.bold}>{title}</Text.h2>
                    {options.map(({ input, label, description }) => (
                      <div
                        className="consent-checkbox"
                        key={input.name + input.value}
                      >
                        <Checkbox theme={Checkbox.themes.alignTop} {...input}>
                          <span className="consent-checkbox-wrapper">
                            <span className="consent-checkbox-label">
                              {label}
                            </span>
                            <span className="consent-checkbox-description">
                              {description}
                            </span>
                          </span>
                        </Checkbox>
                      </div>
                    ))}
                  </div>
                ))}

                <div className="consent-button-wrapper">
                  <Button
                    className="consent-button"
                    type="submit"
                    text={submitLabel}
                  />
                  <Link
                    className="consent-button"
                    themes={[Link.themes.buttonOutline]}
                    {...abortLink}
                  />
                </div>
              </React.Fragment>
            )}
          </Form>
        </MyPageSection>
      </Grid.Column>
    </Grid>
  </MyPageLayout>
);

ConsentPage.propTypes = {
  abortLink: PropTypes.shape(Link.propTypes),
  form: PropTypes.exact(Form.propTypes),
  consentGroups: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.exact({
          input: PropTypes.exact(Checkbox.propTypes),
          label: PropTypes.string,
          description: PropTypes.string
        })
      )
    })
  ),
  layout: PropTypes.exact(MyPageLayout.propTypes),
  section: PropTypes.exact(MyPageSection.propTypes),
  submitLabel: PropTypes.string
};

ConsentPage.defaultProps = {
  consentGroups: [],
  labelDescriptions: []
};

export default ConsentPage;
