import React from 'react';
import CategoryPage from '../category-page';

const SearchPage = props => (
  <CategoryPage theme={CategoryPage.themes.search} {...props} />
);

SearchPage.propTypes = CategoryPage.propTypes;

export default SearchPage;
