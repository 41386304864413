import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  fill: 'theme-fill'
};

// NOTE: Uses dangerouslySetInnerHTML because react for some reason strips the 'muted' attribute, breaking autoplay. See https://github.com/facebook/react/issues/10389
const Video = ({ autoPlay, poster, src, theme }) => {
  const attributes = autoPlay ? 'muted autoplay playsinline loop' : 'controls';

  return (
    <div
      className={cn('video', theme)}
      dangerouslySetInnerHTML={{
        __html: `<video ${attributes} poster="${poster}" src="${src}" />`
      }}
    />
  );
};

Video.propTypes = {
  autoPlay: PropTypes.bool,
  poster: PropTypes.string,
  src: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

Video.propTypesMeta = {
  theme: 'exclude'
};

Video.themes = themes;

export default Video;
