import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/text';

const PersonalDetails = ({ items }) => (
  <dl className="personal-details">
    {items.map(detail => (
      <React.Fragment key={detail.id}>
        <Text element="dt" theme={Text.themes.small}>
          {detail.tag}
        </Text>
        <dd>{detail.detail}</dd>
      </React.Fragment>
    ))}
  </dl>
);

PersonalDetails.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      tag: PropTypes.string,
      detail: PropTypes.string
    })
  )
};

PersonalDetails.defaultProps = {
  items: []
};

export default PersonalDetails;
