import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FluidImage from '../fluid-image';
import Link from '../link';
import Text from '../text';
import BookingLink from 'components/booking-link';

const colors = {
  cyan: 'cyan',
  gray: 'gray',
  // transparent: 'transparent',
  orange: 'orange',
  cold: 'cold',
  clinical: 'clinical',
  blue: 'blue',
  nude: 'nude',
  mint: 'mint',
  purple: 'purple'
};

const orientations = {
  horizontal: 'horizontal',
  vertical: 'vertical'
};

const TinyCtaBlock = ({
  image,
  title,
  lead,
  link,
  color,
  hasFrame,
  orientation,
  bookingLink,
  ctaType = 'tiny-cta-block'
}) => {
  const buttonThemes =
    color === colors.transparent
      ? [Link.themes.arrowRight, Link.themes.buttonCyan]
      : [Link.themes.arrowRight, Link.themes.buttonWhite];

  return (
    <div
      className={cn('tiny-cta-block', {
        [`tiny-cta-block--${colors[color]}`]: colors[color],
        [`tiny-cta-block--${orientations[orientation]}`]: orientations[
          orientation
        ],
        [`tiny-cta-block--framed`]: hasFrame
      })}
    >
      <div className="tiny-cta-block__content">
        {image && (
          <div className="tiny-cta-block__image">
            <FluidImage {...image} />
          </div>
        )}
        <div className="tiny-cta-block__text">
          {title && (
            <Text.h3
              theme={Text.themes.uppercaseLarge}
              className="tiny-cta-block__title"
            >
              {title}
            </Text.h3>
          )}
          {lead && <p className="tiny-cta-block__lead">{lead}</p>}
          {link && link.url && (
            <div className="tiny-cta-block__button">
              <Link
                {...link}
                themes={buttonThemes}
                ctaType={ctaType}
                ctaTitle={title}
              />
            </div>
          )}
          {bookingLink && bookingLink.text && (
            <div className="tiny-cta-block__button">
              <BookingLink themes={buttonThemes} {...bookingLink} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TinyCtaBlock.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)),
  image: PropTypes.exact(FluidImage.propTypes),
  title: PropTypes.string,
  lead: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  hasFrame: PropTypes.bool,
  orientation: PropTypes.oneOf(Object.values(orientations)),
  bookingLink: PropTypes.exact(BookingLink.propTypes),
  ctaType: PropTypes.string
};

TinyCtaBlock.propTypesMeta = {
  hasFrame: 'exclude',
  ctaType: 'exclude'
};

TinyCtaBlock.defaultProps = {
  color: colors.blue,
  hasFrame: false,
  orientation: orientations.vertical
};

export default TinyCtaBlock;
