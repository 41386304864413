import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from 'components/content-container';
import ComponentList from 'components/component-list';

const Frontpage = ({ blocks }) => {
  return (
    <ContentContainer>
      <ComponentList {...blocks} />
    </ContentContainer>
  );
};

Frontpage.propTypes = {
  blocks: PropTypes.exact(ComponentList.propTypes)
};

export default Frontpage;
