import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import FluidImage from 'components/fluid-image';
import Link from 'components/link';
import OnPageEditing from 'components/on-page-editing';
import Text from 'components/text';
import BookingLink from 'components/booking-link';

const themes = {
  full: 'theme-full',
  small: 'theme-small',
  compact: 'theme-compact'
};

const imageAlignments = {
  left: 'image-left',
  right: 'image-right'
};

const colors = {
  cyan: 'color-cyan',
  gray: 'color-gray',
  transparent: 'color-transparent',
  orange: 'color-orange',
  cold: 'color-cold',
  clinical: 'color-clinical',
  blue: 'color-blue',
  nude: 'color-nude',
  mint: 'color-mint',
  purple: 'color-purple'
};

const linkThemes = {
  'color-cyan': [Link.themes.buttonGray],
  'color-gray': [Link.themes.buttonCyan],
  'color-transparent': [Link.themes.buttonCyan],
  'color-orange': [Link.themes.buttonGray],
  'color-cold': [Link.themes.buttonGray],
  'color-clinical': [Link.themes.buttonGray],
  'color-blue': [Link.themes.buttonGray],
  'color-nude': [Link.themes.buttonGray],
  'color-mint': [Link.themes.buttonGray],
  'color-purple': [Link.themes.buttonGray]
};

const getLinkThemes = (blockColor, index, listLength) =>
  index < listLength - 1
    ? linkThemes[blockColor] || [Link.themes.buttonGray]
    : [Link.themes.buttonWhite, Link.themes.arrowRight];

const CtaBlock = ({
  color,
  image,
  imageAlign,
  links,
  OPE,
  isFocusable,
  subtitle,
  text,
  theme,
  title,
  bookingLink,
  ctaType = 'cta-block'
}) => {
  const tabIndex = isFocusable ? 0 : -1;

  return (
    <div
      className={cn('cta-block', theme, color, imageAlign, {
        'has-image': image,
        'image-as-background': image && color === colors.transparent
      })}
    >
      <div className="cta-block-text">
        <OnPageEditing name={OPE.title}>
          <Text.h2
            className="cta-block-title"
            theme={
              theme === themes.small || themes.compact
                ? Text.themes.uppercaseHuge
                : Text.themes.uppercaseJumbo
            }
          >
            {title}
          </Text.h2>
        </OnPageEditing>

        <OnPageEditing name={OPE.subtitle}>
          {subtitle && <Text.h3>{subtitle}</Text.h3>}
        </OnPageEditing>

        <OnPageEditing name={OPE.text}>
          {text && <Text>{text}</Text>}
        </OnPageEditing>

        <OnPageEditing name={OPE.links}>
          {(links.length > 0 || bookingLink) && (
            <ul className="cta-block-links">
              {links.map((link, index) => (
                <li key={link.text}>
                  <Link
                    tabIndex={tabIndex}
                    themes={getLinkThemes(
                      color,
                      index,
                      links.length + (bookingLink && bookingLink.text ? 1 : 0)
                    )}
                    {...link}
                    ctaType={ctaType}
                    ctaTitle={title}
                  />
                </li>
              ))}
              {bookingLink && bookingLink.text && (
                <li>
                  <BookingLink
                    tabIndex={tabIndex}
                    themes={getLinkThemes(
                      color,
                      links.length,
                      links.length + (bookingLink.text ? 1 : 0)
                    )}
                    {...bookingLink}
                    ctaType={ctaType}
                    ctaTitle={title}
                  />
                </li>
              )}
            </ul>
          )}
        </OnPageEditing>
      </div>

      <OnPageEditing name={OPE.image}>
        {image && (
          <div className="cta-block-image">
            <FluidImage {...image} />
          </div>
        )}
      </OnPageEditing>
    </div>
  );
};

CtaBlock.propTypes = {
  color: PropTypes.oneOf(Object.values(colors)),
  image: PropTypes.exact(FluidImage.propTypes),
  imageAlign: PropTypes.oneOf(Object.values(imageAlignments)),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  OPE: PropTypes.exact({
    image: PropTypes.string,
    links: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
  }),
  subtitle: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  text: PropTypes.string,
  title: PropTypes.string,
  isFocusable: PropTypes.bool,
  bookingLink: PropTypes.exact(BookingLink.propTypes),
  ctaType: PropTypes.string
};

CtaBlock.propTypesMeta = {
  isFocusable: 'exclude',
  ctaType: 'exclude'
};

CtaBlock.defaultProps = {
  links: [],
  OPE: {},
  theme: themes.full,
  color: colors.cyan,
  isFocusable: true
};

CtaBlock.themes = themes;
CtaBlock.colors = colors;

export default CtaBlock;
