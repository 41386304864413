import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Button from 'components/button';
import ContentContainer from 'components/content-container';
import Form from 'components/form';
import Link from 'components/link';
import PageSpinner from 'components/page-spinner';
import TextInput from 'components/text-input';
import Text from 'components/text';
import ValidationErrorTexts from 'components/validation-error-texts';

const themes = {
  maxi: 'theme-maxi',
  mini: 'theme-mini'
};

const LoginForm = ({
  endpoint,
  forgotPassword,
  headingLevel,
  idPrefix,
  password,
  registerLink,
  registerLinkLabel,
  submitLabel,
  title,
  theme,
  userId,
  validationErrors
}) => {
  const Wrapper = theme === themes.maxi ? ContentContainer : 'div';

  return (
    <Wrapper className={cn('login-form', theme)}>
      <Text element={'h' + headingLevel} theme={Text.themes.h2}>
        {title}
      </Text>

      <Form
        className="login-form-form"
        endpoint={endpoint}
        showSubmitButton={false}
        validationErrors={validationErrors}
      >
        {({ isLoading }) => (
          <React.Fragment>
            <PageSpinner isLoading={isLoading} shouldContainInParent={true} />

            <TextInput
              idPrefix={idPrefix}
              validations={['isRequired']}
              autocomplete="email"
              {...userId}
            />
            <TextInput
              idPrefix={idPrefix}
              type="password"
              autocomplete="current-password"
              validations={['isRequired']}
              {...password}
            />

            {forgotPassword && (
              <Link
                className="login-form-forgot-password"
                {...forgotPassword}
              />
            )}

            <Button
              className="login-form-submit"
              text={submitLabel}
              type="submit"
              themes={[Button.themes.buttonGray, Button.themes.wide]}
            />
          </React.Fragment>
        )}
      </Form>

      <div className="login-form-register">
        <p>{registerLinkLabel}</p>
        <div className="login-form-register-action">
          <Link {...registerLink} />
        </div>
      </div>
    </Wrapper>
  );
};

LoginForm.propTypes = {
  endpoint: PropTypes.string,
  forgotPassword: PropTypes.exact(Link.propTypes),
  headingLevel: PropTypes.number,
  idPrefix: PropTypes.string,
  password: PropTypes.exact(TextInput.propTypes),
  registerLink: PropTypes.exact(Link.propTypes),
  registerLinkLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  title: PropTypes.string,
  theme: propTypeTheme(themes),
  userId: PropTypes.exact(TextInput.propTypes),
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
};

LoginForm.propTypesMeta = {
  headingLevel: 'exclude',
  idPrefix: 'exclude',
  theme: 'exclude'
};

LoginForm.defaultProps = {
  headingLevel: 1
};

LoginForm.themes = themes;

export default LoginForm;
