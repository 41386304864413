import React from 'react';
import PropTypes from 'prop-types';

import EmailTemplateContent from './email-template-content';
import EmailTemplateHeader from './email-template-header';
import EmailTemplateSummary from './email-template-summary';

import css from './email-template.css';

const EmailTemplate = ({
  addressLabel,
  address,
  cart,
  customer,
  email,
  expectedDeliveryDate,
  logo,
  title,
  orderConfirmationLabel,
  orderDetails,
  orderDate,
  orderDateLabel,
  orderNumber,
  paymentMethodLabel,
  paymentMethod,
  phone,
  reorderLink,
  text
}) => (
  <React.Fragment>
    <style>{css}</style>
    <table className="email-template-wrapper">
      <tr>
        <td />
        <td align="center" width="600">
          <div className="email-template">
            <EmailTemplateHeader logo={logo} title={title} text={text} />
            <EmailTemplateContent
              orderConfirmationLabel={orderConfirmationLabel}
              orderNumber={orderNumber}
              orderDate={orderDate}
              addressLabel={addressLabel}
              orderDateLabel={orderDateLabel}
              customerName={customer}
              customerTelephoneNumber={phone}
              customerEmail={email}
              customerAddress={address}
              expectedDeliveryDate={expectedDeliveryDate}
              {...cart}
            />
            <EmailTemplateSummary
              reorderLink={reorderLink}
              orderDetails={orderDetails}
              paymentMethodLabel={paymentMethodLabel}
              paymentMethod={paymentMethod}
              {...cart}
            />
          </div>
        </td>
        <td />
      </tr>
    </table>
  </React.Fragment>
);

EmailTemplate.propTypes = {
  addressLabel: PropTypes.string,
  address: PropTypes.string,
  cart: PropTypes.object,
  customerLabel: PropTypes.string,
  customer: PropTypes.string,
  email: PropTypes.string,
  expectedDeliveryDate: PropTypes.string,
  logo: PropTypes.object,
  title: PropTypes.string,
  orderConfirmationLabel: PropTypes.string,
  orderDetails: PropTypes.array,
  orderDate: PropTypes.string,
  orderDateLabel: PropTypes.string,
  orderNumber: PropTypes.string,
  paymentMethodLabel: PropTypes.string,
  paymentMethod: PropTypes.string,
  phone: PropTypes.string,
  reorderLink: PropTypes.object,
  text: PropTypes.string
};

EmailTemplate.propTypesMeta = 'exclude';

export default EmailTemplate;
