import React from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import CheckoutContext from 'components/checkout-page/checkout-context';
import KlarnaIframe from '../klarna-iframe';
import DeliveryWidget from '../delivery-widget';

import Radio from 'components/radio';

const DeliveryForm = ({ title, deliveryWidget, options, endpoint }) => {
  const { setIsLoading, setHasIframeHTML } = React.useContext(CheckoutContext);

  const [showPorterBuddy, setShowPorterBuddy] = React.useState(false);
  const [showOnlyKlarna, setShowOnlyKlarna] = React.useState(false);
  const [klarnaIframe, setKlarnaIframe] = React.useState();
  const [currentShipmentId, setCurrentShipmentId] = React.useState('');
  const currentShipmentRef = React.useRef();
  currentShipmentRef.current = currentShipmentId;

  const handleOnChange = async value => {
    setIsLoading(true);
    setShowPorterBuddy(false);
    setShowOnlyKlarna(false);
    setKlarnaIframe('');
    setCurrentShipmentId('');
    setHasIframeHTML(false);

    const { iframeHTML, enablePorterBuddy } = await api.execute(endpoint, {
      value
    });

    if (iframeHTML) {
      setHasIframeHTML(true);
      setKlarnaIframe(iframeHTML);
    }

    enablePorterBuddy ? setShowPorterBuddy(true) : setShowOnlyKlarna(true);
    //console.log('setting delivery method id: ' + value);
    setCurrentShipmentId(value);

    setIsLoading(false);
  };

  async function handleDeliveryFail() {
    //console.log('delivery failed: method id = ' + currentShipmentRef.current);
    const firstShipping = options.find(
      option => option.input.value !== currentShipmentRef.current
    );

    //console.log('setting another method id: ');
    //console.log(firstShipping);
    if (firstShipping !== undefined) {
      await api.execute(endpoint, {
        value: firstShipping.input.value
      });
    }
  }

  async function handleDeliverySucceed() {
    //console.log('delivery succeed: method id = ' + currentShipmentRef.current)
    if (currentShipmentRef.current !== '') {
      await api.execute(endpoint, {
        value: currentShipmentRef.current
      });
    }
  }

  return (
    <div className="delivery-form">
      <div className="delivery-form-delivery">
        {title && <h3>{title}</h3>}
        {options.map(({ description, input, label, price }) => (
          <div className="terms-form-radio" key={input.value}>
            <Radio onChange={handleOnChange} {...input}>
              <span>
                <span className="terms-form-label">
                  {label} {price || ''}
                </span>
                {description && (
                  <span className="terms-form-label-description">
                    {description}
                  </span>
                )}
              </span>
            </Radio>
          </div>
        ))}
        <div className="delivery-form-payment">
          {showPorterBuddy && (
            <DeliveryWidget
              klarnaIframeHtml={klarnaIframe}
              handleDeliveryFail={handleDeliveryFail}
              handleDeliverySucceed={handleDeliverySucceed}
              {...deliveryWidget}
            />
          )}
          {showOnlyKlarna && (
            <KlarnaIframe htmlString={klarnaIframe} shouldEvalScript={true} />
          )}
        </div>
      </div>
    </div>
  );
};

DeliveryForm.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      input: PropTypes.exact(Radio.propTypes),
      label: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string
    })
  ),
  title: PropTypes.string,
  deliveryWidget: PropTypes.shape(DeliveryWidget.propTypes),
  endpoint: PropTypes.string.isRequired
};

DeliveryForm.defaultProps = {
  options: []
};

export default DeliveryForm;
