import React from 'react';
import PropTypes from 'prop-types';
import MoodboardBlockItem from './moodboard-block-item';
import Masonry from 'react-masonry-component';
import Text from 'components/text';

const masonryOptions = {
  columnWidth: '.moodboard-block__sizer',
  itemSelector: '.moodboard-block__item',
  gutter: 10,
  percentPosition: true,
  isFitWidth: false,
  transitionDuration: 0,
  horizontal: false,
  rowHeight: 0,
  stagger: 0
};

const MoodboardBlock = ({ title, items }) => {
  return (
    <div className="moodboard-block">
      {title && (
        <Text.h2
          className="moodboard-block__title"
          theme={Text.themes.uppercaseBig}
        >
          {title}
        </Text.h2>
      )}
      {items && (
        <Masonry
          options={masonryOptions}
          updateOnEachImageLoad={false}
          className={'moodboard-block__items'}
        >
          <div className="moodboard-block__sizer"></div>
          {items.map((item, i) => {
            return <MoodboardBlockItem {...item} key={i} />;
          })}
        </Masonry>
      )}
    </div>
  );
};

MoodboardBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(MoodboardBlockItem.propTypes))
};

export default MoodboardBlock;
