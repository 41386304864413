import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const AppointmentBookingResultRegion = props => {
  return (
    <div
      className="appointment-booking-result-region"
      onClick={props.onClickHandle}
    >
      <div className="appointment-booking-result-region__pin-box">
        <Icon name="pin" />
      </div>
      <strong className="appointment-booking-result-region__title">
        {props.region}
      </strong>
      <div className="appointment-booking-result-region__edit-box">
        <Icon name="icon-edit" />
      </div>
    </div>
  );
};

AppointmentBookingResultRegion.propTypes = {
  region: PropTypes.string,
  onClickHandle: PropTypes.func
};

AppointmentBookingResultRegion.propTypesMeta = 'exclude';

export default AppointmentBookingResultRegion;
