import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import InspirationalItem from './inspirational-item';
import Text from '../text';

const InspirationalItems = ({ items, title }) => {
  return (
    <div
      className={cn('inspirational-items', {
        'inspirational-items--single': items.length === 1
      })}
    >
      {title && (
        <div className="inspirational-items__header">
          <Text.h2 theme={Text.themes.uppercaseBig}>{title}</Text.h2>
        </div>
      )}
      {items && items.length > 0 && (
        <ul className="inspirational-items__list">
          {items.map((item, index) => {
            return (
              <li key={index} className="inspirational-items__item">
                <InspirationalItem {...item} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

InspirationalItems.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(InspirationalItem.propTypes))
};

InspirationalItems.propTypesMeta = {};

export default InspirationalItems;
