import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';
import useEvent from 'hooks/use-event';

const InfoNote = ({
  id,
  className,
  text,
  showButtonText = '',
  closeButtonText = ' '
}) => {
  const [textIsShown, setTextIsShown] = React.useState(false);
  const [textPositionLeft, setTextPositionLeft] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);

  const parent = React.useRef();

  const hideText = () => {
    setTextIsShown(false);
    setIsClicked(false);
  };

  useEscape(() => {
    hideText();
  });

  useClickOutside(parent, () => {
    hideText();
  });

  const setTooltipPosition = () => {
    const buttonLeftPosition = parent.current.getBoundingClientRect().x;

    const buttonRightPossition =
      window && parent
        ? window.innerWidth - buttonLeftPosition - parent.current.offsetWidth
        : 0;
    setTextPositionLeft(buttonRightPossition < 340 && buttonLeftPosition > 340);
  };

  const onButtonClick = () => {
    if (isClicked) {
      hideText();
    } else {
      setTextIsShown(true);
      setIsClicked(true);
      setTooltipPosition();
    }
  };

  useEvent('resize', () => setTooltipPosition());

  React.useEffect(() => {
    textIsShown && setTooltipPosition();
  }, [textIsShown]);

  return (
    <div
      ref={parent}
      className={cn('info-note', className, {
        'info-note--text-is-shown': textIsShown
      })}
    >
      <button
        className={cn('info-note__button', {
          'info-note__button--active': textIsShown
        })}
        onClick={e => {
          e.preventDefault();
          onButtonClick();
        }}
        type="button"
        aria-label={textIsShown ? closeButtonText : showButtonText}
        aria-describedby={id}
      ></button>
      <div
        className={cn('info-note__text', {
          'info-note__text--is-shown': textIsShown,
          'info-note__text--position-left': textPositionLeft
        })}
        id={id}
        role="tooltip"
      >
        {text}
      </div>
    </div>
  );
};

InfoNote.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  showButtonText: PropTypes.string.isRequired,
  closeButtonText: PropTypes.string.isRequired
};

InfoNote.propTypesMeta = {
  className: 'exclude'
};

export default InfoNote;
