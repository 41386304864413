import React from 'react';

/** Attach an event listener to `element` with the `passive` option set to false. */
export default function useNonPassiveEvent(
  event,
  element,
  listener,
  dependencies = []
) {
  React.useEffect(() => {
    if (!element) return;
    const options = { passive: false };
    element.addEventListener(event, listener, options);
    return () => element.removeEventListener(event, listener, options);
  }, [event, element, ...dependencies]);
}
