import PropTypes from 'prop-types';

import Cart from 'components/cart';
import AddToCartConfirmation from 'components/add-to-cart-confirmation';

const AddToCartResponse = {};
AddToCartResponse.propTypes = {
  cart: PropTypes.exact(Cart.propTypes),
  confirmation: PropTypes.exact(AddToCartConfirmation.propTypes),
  numberOfProductsInCart: PropTypes.number
};

export default AddToCartResponse;
