import React from 'react';
import PropTypes from 'prop-types';

import Availability from 'components/availability';
import GlassesForm from './glasses-form';
import Grid from 'components/grid';
import Link from 'components/link';
import Price from 'components/price';
import ProductColors from './product-colors';
import ProductImages from 'components/product-images';
import ProductLogo from 'components/product-logo';
import ProductSellingPoints from 'components/product-selling-points';
import SellingPoints from 'components/selling-points';
import Text from 'components/text';
import ProductVariantText from 'components/product-variant-text';

const GlassesHeader = ({
  availability,
  onUpdate,
  price,
  productColors,
  productImages,
  productLogo,
  productSellingPoints,
  purchaseForm,
  purchaseInformation,
  sellingPoints,
  subtitle,
  title,
  tag,
  productVariantText
}) => {
  const hidePurchaseInformation =
    purchaseForm && purchaseForm.hideAddToCartyButton === true;
  return (
    <Grid
      className="glasses-header"
      theme={[Grid.themes.leftColumnWider, Grid.themes.compact]}
    >
      <Grid.Column>
        <div className="glasses-logo">
          <ProductLogo {...productLogo} />
        </div>

        <div className="glasses-image">
          <ProductImages {...productImages} />
        </div>
      </Grid.Column>

      <Grid.Column>
        <div className="glasses-sidebar">
          {tag && (
            <div className="glasses-sidebar-wrapper">
              <Text.div className="glasses-tag" theme={Text.themes.filled}>
                {tag}
              </Text.div>
            </div>
          )}
          <div className="glasses-title-and-price">
            <div>
              <Text.h2
                className="glasses-title"
                theme={Text.themes.boldUppercase}
              >
                {title}
              </Text.h2>
              <Text.p className="glasses-subtitle">{subtitle}</Text.p>
            </div>

            <Price theme={Price.themes.big} {...price} />
          </div>

          {productColors && <ProductColors {...productColors} />}

          {purchaseForm && (
            <GlassesForm
              onUpdate={onUpdate}
              {...purchaseForm}
              productName={title + ' ' + subtitle}
              purchaseInformation={purchaseInformation}
            />
          )}

          {availability && (
            <div className="glasses-availablity">
              <Availability {...availability} />
            </div>
          )}

          {purchaseInformation && !hidePurchaseInformation && (
            <div className="glasses-purchase-information">
              <p>{purchaseInformation.text}</p>
              <Link
                iconAfter="pin"
                themes={[Link.themes.buttonGray]}
                {...purchaseInformation.link}
              />
            </div>
          )}

          {productVariantText && (
            <ProductVariantText textHtml={productVariantText} />
          )}

          {productSellingPoints && (
            <div className="glasses-selling-points">
              <ProductSellingPoints items={productSellingPoints} />
            </div>
          )}

          <SellingPoints {...sellingPoints} />
        </div>
      </Grid.Column>
    </Grid>
  );
};

GlassesHeader.propTypes = {
  availability: PropTypes.exact(Availability.propTypes),
  onUpdate: PropTypes.func,
  price: PropTypes.exact(Price.propTypes),
  productColors: PropTypes.exact(ProductColors.propTypes),
  productImages: PropTypes.exact(ProductImages.propTypes),
  productLogo: PropTypes.exact(ProductLogo.propTypes),
  productSellingPoints: PropTypes.arrayOf(PropTypes.string),
  purchaseForm: PropTypes.exact(GlassesForm.propTypes),
  purchaseInformation: PropTypes.exact({
    text: PropTypes.string,
    link: PropTypes.exact(Link.propTypes)
  }),
  sellingPoints: PropTypes.exact(SellingPoints.propTypes),
  subtitle: PropTypes.string,
  tag: PropTypes.string,
  title: PropTypes.string,
  productVariantText: PropTypes.string
};

GlassesHeader.defaultProps = {
  productSellingPoints: []
};

GlassesHeader.propTypesMeta = {
  productVariantText: 'exclude'
};

export default GlassesHeader;
