import PropTypes from 'prop-types';

import ContactLensForm from './contact-lens-form';

const UpdateFormResponse = {};
UpdateFormResponse.propTypes = {
  purchaseForm: PropTypes.exact(ContactLensForm.propTypes)
};

export default UpdateFormResponse;
