import React from 'react';
import PropTypes from 'prop-types';
import AppointmentBookingAssistantAccordion from 'components/appointment-booking-modal/appointment-booking-assistant-accordion';

const AppointmentBookingAssistant = props => {
  return (
    <div className="appointment-booking-assistant">
      <AppointmentBookingAssistantAccordion
        defaultText={props.defaultText}
        cancelText={props.cancelText}
        label={props.label}
        assistants={props.assistants}
        assistantId={props.assistantId}
        preselectedOpticianId={props.preselectedOpticianId}
        onClickAssistant={props.onClickAssistant}
        onClickRemoveAssistant={props.onClickRemoveAssistant}
      />
    </div>
  );
};

AppointmentBookingAssistant.propTypes = {
  defaultText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClickAssistant: PropTypes.func,
  onClickRemoveAssistant: PropTypes.func,
  assistants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      dates: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          weekday: PropTypes.string,
          weekdayShort: PropTypes.string,
          month: PropTypes.string,
          timeSlots: PropTypes.arrayOf(PropTypes.string)
        })
      )
    })
  ),
  assistantId: PropTypes.string,
  preselectedOpticianId: PropTypes.string
};

AppointmentBookingAssistant.propTypesMeta = {
  assistants: 'exclude',
  assistantId: 'exclude',
  preselectedOpticianId: 'exclude'
};

export default AppointmentBookingAssistant;
