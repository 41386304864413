import React from 'react';
import PropTypes from 'prop-types';

import CheckoutContext from 'components/checkout-page/checkout-context';

import useEvent from 'hooks/use-event';

import Button from 'components/button';
import Link from 'components/link';
import HiddenInput from 'components/hidden-input';
import Form from 'components/form';
import Checkbox from 'components/checkbox';
import RichText from 'components/rich-text';
import ValidationErrorTexts from 'components/validation-error-texts';

const TermsForm = ({
  buttonText,
  endpoint,
  isDesktopKey,
  onResponse,
  legalConsent,
  privacyConsent,
  privacyConsentLink,
  marketingConsents,
  marketingTitle,
  marketingDisclaimer,
  reminderConsents,
  termsDescriptionBody,
  termsDescriptionLead,
  legalConsentLink,
  validationErrors
}) => {
  const desktopThreshold = 780;
  const { setIsLoading } = React.useContext(CheckoutContext);
  const [isDesktop, setIsDesktop] = React.useState(false);

  useEvent('resize', () => setIsDesktop(window.innerWidth > desktopThreshold));
  React.useEffect(() => setIsDesktop(window.innerWidth > desktopThreshold), []);

  return (
    <div className="terms-form">
      <Form
        endpoint={endpoint}
        onSubmit={() => setIsLoading(true)}
        onResponse={data => {
          onResponse(data);
          setIsLoading(false);
        }}
        validationErrors={validationErrors}
      >
        <HiddenInput name={isDesktopKey} value={String(isDesktop)} />
        <div className="terms-form-terms">
          <div className="terms-form__description">
            {termsDescriptionLead && (
              <div className="terms-form__description-lead">
                <RichText
                  theme={RichText.themes.checkmarks}
                  text={termsDescriptionLead}
                />
              </div>
            )}
            {reminderConsents && reminderConsents.length > 0 && (
              <div className="terms-form__reminders">
                {reminderConsents.map((input, index) => (
                  <div className="terms-form__reminder" key={index}>
                    <Checkbox key={input.value} {...input} />
                  </div>
                ))}
              </div>
            )}
            {termsDescriptionBody && (
              <div className="terms-form__description-body">
                <RichText
                  theme={RichText.themes.checkmarks}
                  text={termsDescriptionBody}
                />
              </div>
            )}
          </div>

          {legalConsent && (
            <div className="terms-form__legal">
              <Checkbox
                data-test-checkout-checkbox={true}
                className="terms-form-checkbox is-required"
                validations={['isRequired']}
                theme={Checkbox.themes.alignTop}
                {...legalConsent}
              />
              {legalConsentLink && (
                <Link className="terms-form-link" {...legalConsentLink} />
              )}
            </div>
          )}

          {privacyConsent && (
            <div className="terms-form__privacy">
              <Checkbox
                data-test-checkout-checkbox={true}
                className="terms-form-checkbox is-required"
                validations={['isRequired']}
                theme={Checkbox.themes.alignTop}
                {...privacyConsent}
              />
              {privacyConsentLink && (
                <Link className="terms-form-link" {...privacyConsentLink} />
              )}
            </div>
          )}

          {marketingConsents && (
            <div className="terms-form__marketing">
              {marketingTitle && (
                <h3 className="terms-form__heading">{marketingTitle}</h3>
              )}
              {marketingConsents.map(input => (
                <Checkbox
                  key={input.value}
                  theme={Checkbox.themes.alignTop}
                  {...input}
                />
              ))}
              {marketingDisclaimer && (
                <div className="terms-form__marketing-text">
                  <RichText
                    theme={RichText.themes.checkmarks}
                    text={marketingDisclaimer}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <Button
          className="terms-form-submit"
          data-test-checkout-step-3-button
          iconAfter="chevron-right"
          type="submit"
          text={buttonText}
        />
      </Form>
    </div>
  );
};

TermsForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  isDesktopKey: PropTypes.string.isRequired,
  onResponse: PropTypes.func,
  legalConsent: PropTypes.exact(Checkbox.propTypes).isRequired,
  privacyConsent: PropTypes.exact(Checkbox.propTypes).isRequired,
  privacyConsentLink: PropTypes.exact(Link.propTypes),
  termsDescriptionBody: PropTypes.string,
  termsDescriptionLead: PropTypes.string,
  marketingTitle: PropTypes.string,
  marketingDisclaimer: PropTypes.string,
  reminderConsents: PropTypes.arrayOf(PropTypes.exact(Checkbox.propTypes)),
  marketingConsents: PropTypes.arrayOf(PropTypes.exact(Checkbox.propTypes)),
  legalConsentLink: PropTypes.exact(Link.propTypes),
  validationErrors: PropTypes.exact(ValidationErrorTexts.propTypes)
};

TermsForm.defaultProps = {
  reminderConsents: [],
  marketingConsents: []
};

export default TermsForm;
