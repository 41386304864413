import React from 'react';
import PropTypes from 'prop-types';

import CheckoutContext from 'components/checkout-page/checkout-context';

import Button from 'components/button';
import Form from 'components/form';
import Link from 'components/link';
import TextInput from 'components/text-input';

const CheckoutLoginForm = ({
  endpoint,
  forgotPasswordLink,
  onLoggedIn,
  password,
  submitLabel,
  text,
  title,
  userId
}) => {
  const { setIsLoading } = React.useContext(CheckoutContext);

  return (
    <Form
      className="checkout-login-form"
      endpoint={endpoint}
      onSubmit={() => setIsLoading(true)}
      onResponse={({ isLoggedIn }) => {
        isLoggedIn && onLoggedIn();
        setIsLoading(false);
      }}
    >
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
      <TextInput validations={['isRequired']} {...userId} />
      <TextInput
        type="password"
        autocomplete="current-password"
        validations={['isRequired']}
        {...password}
      />

      <div className="checkout-login-form-footer">
        <Button
          className="checkout-login-form-submit"
          type="submit"
          text={submitLabel}
        />
        {forgotPasswordLink && <Link {...forgotPasswordLink} />}
      </div>
    </Form>
  );
};

CheckoutLoginForm.propTypes = {
  endpoint: PropTypes.string,
  forgotPasswordLink: PropTypes.exact(Link.propTypes),
  onLoggedIn: PropTypes.func,
  password: PropTypes.exact(TextInput.propTypes),
  submitLabel: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.exact(TextInput.propTypes)
};

CheckoutLoginForm.defaultProps = {
  onLoggedIn: () => {}
};

export default CheckoutLoginForm;
