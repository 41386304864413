import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Text from 'components/text';

const themes = {
  small: 'theme-small'
};

const MainTitle = ({ preTitle, title, theme }) => {
  return (
    <Text.div className={cn('main-title', theme)}>
      {preTitle && (
        <Text.span
          className="main-title-pre-title"
          theme={Text.themes.uppercase}
        >
          {preTitle}
        </Text.span>
      )}
      <Text.h1 className="main-title-h1">
        <Text.span className="main-title-title" theme={Text.themes.h1}>
          {title}
        </Text.span>
      </Text.h1>
    </Text.div>
  );
};

MainTitle.propTypes = {
  preTitle: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

MainTitle.propTypesMeta = {
  theme: 'exclude'
};

MainTitle.themes = themes;

export default MainTitle;
