import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ModalContext from '../../context/modal';
import Link from 'components/link';

const AppointmentBookingTrigger = ({
  className,
  themes,
  url,
  text,
  tabIndex,
  isStorePreselected,
  isPreselectedAppointmentId,
  isPreselectedOpticianId
}) => {
  const modalContext = useContext(ModalContext);

  const toggleAppointmentBookingModal = e => {
    e.preventDefault();

    modalContext.toggleAppointmentBookingModal(
      isStorePreselected,
      isPreselectedAppointmentId,
      isPreselectedOpticianId
    );
  };

  return (
    <Link
      tabIndex={tabIndex}
      isNoFollow={true}
      className={className}
      themes={themes}
      onClick={toggleAppointmentBookingModal}
      url={url}
      text={text}
    />
  );
};

AppointmentBookingTrigger.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
  themes: PropTypes.any,
  tabIndex: PropTypes.number,
  isStorePreselected: PropTypes.bool,
  isPreselectedAppointmentId: PropTypes.bool,
  isPreselectedOpticianId: PropTypes.bool
};

AppointmentBookingTrigger.propTypesMeta = {
  className: 'exclude',
  themes: 'exclude',
  tabIndex: 'exclude',
  isStorePreselected: 'exclude',
  isPreselectedAppointmentId: 'exclude',
  isPreselectedOpticianId: 'exclude'
};

AppointmentBookingTrigger.defaultProps = {
  themes: [Link.themes.buttonCyan],
  className: 'booking-link',
  isStorePreselected: false,
  isPreselectedAppointmentId: false,
  isPreselectedOpticianId: false
};

AppointmentBookingTrigger.themes = Link.themes;

export default AppointmentBookingTrigger;
