import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import api from '../../js/api-helper';

import Icon from '../icon';
import PageSpinner from '../page-spinner';
import Button from '../button';
import TextInput from '../text-input';
import SelectSortedOnGeoLocation from 'components/select-sorted-on-geolocation';

const MyStore = ({
  selectedStoreText,
  toggleText,
  nearestShops,
  submitEndpoint,
  textInput,
  isInitiallyOpen,
  submitButtonText
}) => {
  const [text, setText] = useState(selectedStoreText);
  const [shopId, setShopId] = useState('');
  const [state, setState] = useState({
    isOpen: isInitiallyOpen,
    isLoading: false
  });

  const handleOnSave = () => {
    setState({ ...state, isLoading: true });

    api.execute(submitEndpoint, { shopId }).then(() => {
      setState({ ...state, isLoading: false, isOpen: false });
    });
  };

  const onSelectShop = useCallback(shopId => {
    const selectedShop = nearestShops.options.find(o => o.value === shopId);
    setText(selectedShop.name);
    setShopId(selectedShop.value);
  });

  return (
    <>
      <div className="my-store">
        {!state.isOpen && (
          <>
            <div className="my-store__selected">
              <Icon name="checkmark" className="my-store__selected-icon" />
              <div className="my-store__selected-text">{text}</div>
            </div>
            <div className="my-store__toggle">
              <Button
                className="my-store__button"
                onClick={() => setState({ isOpen: true })}
                themes={[Button.themes.link]}
              >
                {toggleText}
              </Button>
            </div>
          </>
        )}
        {state.isOpen && (
          <>
            <div className="my-store__text-input">
              <TextInput
                {...textInput}
                theme={TextInput.themes.cyan}
                disabled={true}
                value={text}
                onChange={value => setText(value)}
              />
              <Button className="my-store__submit" onClick={handleOnSave}>
                {submitButtonText}
              </Button>
            </div>
            <div className="my-store__select-input">
              <SelectSortedOnGeoLocation
                {...nearestShops}
                onSelectShop={onSelectShop}
              />
            </div>
          </>
        )}
      </div>
      <PageSpinner isLoading={state.isLoading} />
    </>
  );
};

MyStore.propTypes = {
  selectedStoreText: PropTypes.string,
  toggleText: PropTypes.string,
  isInitiallyOpen: PropTypes.bool,
  submitEndpoint: PropTypes.string,
  submitButtonText: PropTypes.string,
  textInput: PropTypes.exact(TextInput.propTypes),
  nearestShops: PropTypes.exact(SelectSortedOnGeoLocation.propTypes)
};

MyStore.defaultProps = {};

export default MyStore;
