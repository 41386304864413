import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FluidImage from 'components/fluid-image';

const formats = {
  square: 'square',
  tall: 'tall',
  squareBig: 'square-big',
  landscape: 'landscape'
};

const PhotoBlockItem = ({
  caption,
  image,
  imageCreditText,
  className,
  format
}) => {
  return (
    <div className={cn('photo-block__item', className)}>
      <div
        className={cn('photo-block__image-container', {
          [`photo-block__image-container--format-${format}`]: format
        })}
      >
        <FluidImage {...image} className="photo-block__image" />
      </div>
      {imageCreditText && (
        <div className="photo-block__image-credit-text">{imageCreditText}</div>
      )}
      {caption && <div className="photo-block__image-caption">{caption}</div>}
    </div>
  );
};

PhotoBlockItem.propTypes = {
  className: PropTypes.string,
  imageCreditText: PropTypes.string,
  caption: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes).isRequired,
  format: PropTypes.oneOf(Object.values(formats))
};

PhotoBlockItem.propTypesMeta = {
  className: 'exclude',
  format: 'exclude'
};

export default PhotoBlockItem;
