import React from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import getFilter from './filter-stores';

import Search from '../search';
import Store from './store';

class FindStore extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    searchClassName: PropTypes.string,
    googleMapsAPIKey: PropTypes.string,
    search: PropTypes.exact(Search.propTypes),
    stores: PropTypes.arrayOf(PropTypes.exact(Store.propTypes)),
    textInputTheme: PropTypes.any,
    updateOnChange: PropTypes.bool,
    heading: PropTypes.string
  };

  static propTypesMeta = {
    className: 'exclude',
    wrapperClassName: 'exclude',
    searchClassName: 'exclude',
    textInputTheme: 'exclude',
    updateOnChange: 'exclude',
    heading: 'exclude'
  };

  static defaultProps = {
    search: {},
    stores: []
  };

  state = {
    isLoading: false,
    searchTerm: '',
    stores: this.props.stores
  };

  defaultStores = this.props.stores;

  doSubmit = () => {
    this.setState({ isLoading: true }, () => {
      this.filter(this.state.searchTerm)
        .then(stores => {
          this.setState({
            isLoading: false,
            stores
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  debouncedSubmit = debounce(this.doSubmit, 500, { leading: true });

  onSearchInput = searchTerm => {
    const shouldDoLiveSearch =
      this.props.updateOnChange && searchTerm.length >= 2;

    this.setState({ searchTerm });

    // If the input had a value but is now empty, run doSubmit in order to reset the stores list
    if (shouldDoLiveSearch) {
      this.debouncedSubmit(searchTerm);
    }

    if (!searchTerm) {
      this.setState({ stores: this.defaultStores });
    }
  };

  onSearch = e => {
    e.preventDefault();
    this.doSubmit();
  };

  componentDidMount() {
    getFilter(this.props.googleMapsAPIKey, this.props.stores).then(filter => {
      this.filter = filter;
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className={this.props.wrapperClassName}>
          {this.props.heading && (
            <strong className="find-store__heading">
              {this.props.heading}
            </strong>
          )}
          <form className={this.props.className} onSubmit={this.onSearch}>
            <Search
              onChange={this.onSearchInput}
              showSubmitButton={!this.props.updateOnChange}
              textInputTheme={this.props.textInputTheme}
              {...this.props.search}
              className={this.props.searchClassName}
            />
          </form>
        </div>
        {this.props.children({
          apiKey: this.props.googleMapsAPIKey,
          isLoading: this.state.isLoading,
          searchTerm: this.state.searchTerm,
          stores: this.state.stores
        })}
      </React.Fragment>
    );
  }
}

export default FindStore;
