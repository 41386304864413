import React from 'react';
import PropTypes from 'prop-types';

import Collapse from 'react-tiny-collapse';

import Form from 'components/form';
import Select from 'components/select';

const CartProductDetails = ({ endpoint, inputs, isExpanded }) => {
  return (
    <tr className="cart-product-details">
      <td />
      <td colSpan={2}>
        <Collapse isOpen={isExpanded}>
          <Form endpoint={endpoint} showSubmitButton={false}>
            <div className="cart-product-details-content">
              {inputs.map(detail => (
                <div className="cart-product-detail" key={detail.name}>
                  <Select labelVisible={true} {...detail} />
                </div>
              ))}
            </div>
          </Form>
        </Collapse>
      </td>
    </tr>
  );
};

CartProductDetails.propTypes = {
  endpoint: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.exact(Select.propTypes)),
  isExpanded: PropTypes.bool
};

CartProductDetails.propTypesMeta = {
  isExpanded: 'exclude'
};

CartProductDetails.defaultProps = {
  endpoint: ''
};

export default CartProductDetails;
