import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import ProductCard from 'components/product-card';

const themes = {
  narrow: 'theme-narrow',
  noMargin: 'theme-no-margin',
  productBackgrounds: 'theme-product-backgrounds',
  vertical: 'theme-vertical'
};

const sizes = {
  oneColumn: 'size-one-column',
  twoColumns: 'size-two-columns'
};

const ProductList = ({
  products,
  productTheme,
  size,
  theme,
  ctaType = 'product-list'
}) => {
  return (
    <div className={cn('product-list', theme, size)}>
      <ul>
        {products.map(product => (
          <li key={product.url}>
            <ProductCard theme={productTheme} {...product} ctaType={ctaType} />
          </li>
        ))}
      </ul>
    </div>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.exact(ProductCard.propTypes)),
  productTheme: PropTypes.any,
  size: PropTypes.oneOf(Object.values(sizes)),
  theme: propTypeTheme(themes),
  ctaType: PropTypes.string
};

ProductList.propTypesMeta = {
  productTheme: 'exclude',
  size: 'exclude',
  theme: 'exclude',
  ctaType: 'exclude'
};

ProductList.defaultProps = {
  products: []
};

ProductList.themes = themes;
ProductList.sizes = sizes;

export default ProductList;
