/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link';
import Icon from 'components/icon';
import cn from 'classnames';

const LinkToMainSite = ({ className, url, text }) => {
  return (
    <a href={url} className={cn('link-to-main-site', className)}>
      <Icon name="icon-back" className="link-to-main-site__icon-before" />
      {text && <span className="link-to-main-site__text">{text}</span>}
      <Icon
        name="interoptik-logo-back"
        className="link-to-main-site__icon-after"
      />
    </a>
  );
};

LinkToMainSite.propTypes = Object.assign({}, Link.propTypes, {
  className: PropTypes.string
});

LinkToMainSite.propTypesMeta = {
  className: 'exclude'
};

export default LinkToMainSite;
